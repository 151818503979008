import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom'; // useParams to get route params
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Avatar } from '../../../../components/Avatar';
import { UserService } from '../../../../services/userService'; // Import the UserService
import Loading from '../../../../components/Loading';
import dateFormat from 'dateformat';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { WarehouseProductGroup } from '../../../../models/WarehouseProductGroup';
import { useWarehouseProductGroup } from '../../../../hooks/useWarehouseProductGroup';

const ViewWarehouseProductGroupIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Use useParams to get the id from the URL
    const navigate = useNavigate();
    const [group, setGroup] = useState<WarehouseProductGroup | null>(null);
    const { fetchGroupById, loading, error } = useWarehouseProductGroup();

    useEffect(() => {
        const fetchGroupDetails = async () => {
            if (!id) return; // Ensure ID is valid before making the request

            try {
                const fetchedGroup = await fetchGroupById(Number(id));
                setGroup(fetchedGroup);
            } catch (err) {
                showErrorToast('Failed to fetch group details.');
            }
        };
        fetchGroupDetails();
    }, [id]);

    useEffect(() => {
        document.title = "View Product Group";
    }, []);

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!group) return <p className='py-16 text-center'>Group not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/inventories/groups" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Product Groups
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={group.group_name![0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{group.group_name}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span>{dateFormat(group.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={() => navigate(`/gp/inventories/groups/edit/${group.id}`)}>Edit</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>Producy Group Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Group Name</DescriptionTerm>
                    <DescriptionDetails>{group.group_name}</DescriptionDetails>
                    <DescriptionTerm>Group Code</DescriptionTerm>
                    <DescriptionDetails>{group.group_code}</DescriptionDetails>
                </DescriptionList>
            </div>
        </>
    );
};

export default ViewWarehouseProductGroupIdPage;
