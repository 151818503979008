// hooks/useStockAdjust.ts
import { useState, useEffect, useContext } from 'react';
import { StockAdjust } from '../models/StockAdjust';
import StockAdjustService from '../services/stockadjustService';
import { UserContext } from '../context/userContext';

export const useStockAdjust = () => {
    const [stockAdjusts, setStockAdjusts] = useState<StockAdjust[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [totalStockAdjusts, setTotalStockAdjusts] = useState<number>(0);
    const { currentUser } = useContext(UserContext);

    const fetchAllFilteredStockAdjusts = async () => {
        setLoading(true);
        setError(null);
        try {
            if (currentUser?.warehouse_hq === "1") {
                const stockAdjustData = await StockAdjustService.getAllStockAdjusts();
                setStockAdjusts(stockAdjustData);
                setTotalStockAdjusts(stockAdjustData.length);
            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1") {
                const stockAdjustData = await StockAdjustService.getAllStockAdjustsByWarehouseId(currentUser.warehouse_id.toString());
                setStockAdjusts(stockAdjustData);
                setTotalStockAdjusts(stockAdjustData.length);
            }
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-ins');
        } finally {
            setLoading(false);
        }
    }

    // Fetch all stock-adjusts
    const fetchStockAdjusts = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await StockAdjustService.getAllStockAdjusts();
            setStockAdjusts(data);
            setTotalStockAdjusts(data.length);
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-adjusts');
        } finally {
            setLoading(false);
        }
    };

    // Fetch a single stock-adjust by ID
    const fetchStockAdjustById = async (id: number): Promise<StockAdjust | null> => {
        setLoading(true);
        setError(null);
        try {
            return await StockAdjustService.getStockAdjustById(id);
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-adjust');
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Create a new stock-adjust
    const createStockAdjust = async (StockAdjustData: Omit<StockAdjust, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const newStockAdjustRes = await StockAdjustService.createStockAdjust(StockAdjustData);
            return newStockAdjustRes;
        } catch (err: any) {
            setError(err.message || 'Error creating stock-adjust');
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Update an existing stock-adjust
    const updateStockAdjust = async (id: number, StockAdjustData: Omit<StockAdjust, 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const updatedStockAdjustRes = await StockAdjustService.updateStockAdjust(id, StockAdjustData);
            return updatedStockAdjustRes;
        } catch (err: any) {
            setError(err.message || 'Error updating stock-adjust');
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Delete a stock-adjust
    const deleteStockAdjust = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            await StockAdjustService.deleteStockAdjust(id);
            setStockAdjusts((prevStockAdjusts) => prevStockAdjusts.filter((StockAdjust) => StockAdjust.id !== id));
        } catch (err: any) {
            setError(err.message || 'Error deleting stock-adjust');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllFilteredStockAdjusts();
    }, [currentUser]);

    return {
        stockAdjusts,
        fetchStockAdjusts,
        fetchStockAdjustById,
        createStockAdjust,
        updateStockAdjust,
        deleteStockAdjust,
        loading,
        error,
        totalStockAdjusts,
    };
};
