import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom'; // useParams to get route params
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Avatar } from '../../../../components/Avatar';
import { UserService } from '../../../../services/userService'; // Import the UserService
import Loading from '../../../../components/Loading';
import dateFormat from 'dateformat';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { WarehouseProductDepartment } from '../../../../models/WarehouseProductDepartment';
import { useWarehouseProductDepartment } from '../../../../hooks/useWarehouseProductDepartment';

const ViewWarehouseProductDepartmentIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Use useParams to get the id from the URL
    const navigate = useNavigate();
    const [department, setDepartment] = useState<WarehouseProductDepartment | null>(null);
    const { fetchDepartmentById, loading, error } = useWarehouseProductDepartment();

    useEffect(() => {
        const fetchDepartmentDetails = async () => {
            if (!id) return; // Ensure ID is valid before making the request

            try {
                const fetchedDepartment = await fetchDepartmentById(Number(id));
                setDepartment(fetchedDepartment);
            } catch (err) {
                showErrorToast('Failed to fetch department details.');
            }
        };
        fetchDepartmentDetails();
    }, [id]);

    useEffect(() => {
        document.title = "View Product Department";
    }, []);

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!department) return <p className='py-16 text-center'>Department not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/inventories/departments" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Product Department
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={department.department_name![0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{department.department_name}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span>{dateFormat(department.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={() => navigate(`/gp/inventories/departments/edit/${department.id}`)}>Edit</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>Producy Department Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Department Name</DescriptionTerm>
                    <DescriptionDetails>{department.department_name}</DescriptionDetails>
                </DescriptionList>
            </div>
        </>
    );
};

export default ViewWarehouseProductDepartmentIdPage;
