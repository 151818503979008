import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../../models/User';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Select } from '../../../../components/Select';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { useRoles } from '../../../../hooks/useRoles';
import Loading from '../../../../components/Loading';
import { useSetupUsergroup } from '../../../../hooks/useSetupUsergroup';
import { useWarehouses } from '../../../../hooks/useWarehouse';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import useUserService from '../../../../hooks/useUserService';
import { UserService } from '../../../../services/userService';

interface AddSetupUsergroup {
    fullname: string;
    second_name?: string;
    area?: string;
    group?: string;
    office_no?: string;
    fax_no?: string;
    mobile_no?: string;
    account_code?: string;
    address?: string;
    address_2?: string;
    address_3?: string;
    address_4?: string;
    email?: string;
    setup_usergroup_type_id?: Number;
    warehouse_id: Number;
    user_id?: number;
}

interface AddUser {
    username: string;
    password: string;
    confirmPassword?: string; // Optional as it's not part of the AddUser interface but used for validation
    role: bigint;
    fullname: string;
    email: string;
    mobile_no: string;
    street_address: string;
    remarks: string;
    warehouse_id: bigint;
}

const SetupAddSupplierPage = () => {
    const navigate = useNavigate();
    const { createUserGroup, updateUserGroup } = useSetupUsergroup();
    const { warehouses } = useWarehouses();
    const filteredWarehouses = warehouses.filter(warehouse => warehouse.hq !== '1');
    const [supplier, setSupplier] = useState<AddSetupUsergroup>({
        fullname: '',
        second_name: '',
        area: '',
        group: '',
        office_no: '',
        fax_no: '',
        mobile_no: '',
        account_code: '',
        address: '',
        address_2: '',
        address_3: '',
        address_4: '',
        email: '',
        setup_usergroup_type_id: 1,
        warehouse_id: Number(0),
        user_id: Number(0),
    });

    const [user, setUser] = useState<AddUser>({
        username: '',
        password: '',
        confirmPassword: '', // For confirming password
        role: BigInt(0),
        fullname: '',
        email: '',
        mobile_no: '',
        street_address: '',
        remarks: '',
        warehouse_id: BigInt(0),
    });

    const [isCreateAccountDialogOpen, setIsCreateAccountDialogOpen] = useState(false); // Dialog open state
    const [isInputAccountOpen, setIsInputAccountOpen] = useState(false); // Dialog open state
    const [isCreating, setIsCreating] = useState(false); // Loading state for deletion
    const [returnInsertedSupplierId, setReturnInsertedSupplierId] = useState<string | null>();
    const [returnInsertedUserId, setReturnInsertedUserId] = useState<string | null>()

    useEffect(() => {
        document.title = "Add Supplier";
    }, []);

    useEffect(() => {
        if (filteredWarehouses.length > 0 && supplier.warehouse_id === 0) {
            setSupplier(prev => ({ ...prev, warehouse_id: Number(filteredWarehouses[0].id) }));
        }
    }, [filteredWarehouses, supplier.warehouse_id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setSupplier(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!supplier.fullname || !supplier.account_code || !supplier.email || !supplier.address || !supplier.warehouse_id) {
            showErrorToast("Please fill in all field before submit");
            return;
        }

        try {
            // Convert BigInt values to strings
            const supplierToSubmit = {
                ...supplier,
                setup_usergroup_id: supplier.setup_usergroup_type_id!.toString(),
                warehouse_id: supplier.warehouse_id!.toString(),
            };

            const res = await createUserGroup(supplierToSubmit);
            showLoadingToast(`Creating ${supplier.fullname} ...`, 100);
            if (res.dbsuccess) {
                setTimeout(() => {
                    setIsCreateAccountDialogOpen(true);
                    showSuccessToast(`${supplier.fullname} added successfully`);
                }, 1000);
                setReturnInsertedSupplierId(res.data.id);
            } else {
                showErrorToast(`Failed adding supplier`)
            }
        } catch (err) {
            showErrorToast(`Failed to add supplier: ${err}`);
        }
    };

    const handleOpenCreateAccountDialog = () => {
        setIsCreateAccountDialogOpen(true);
    };

    const handleEditFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setUser(prev => ({ ...prev, [name]: value }));
    };

    const handleCreateAccount = async () => {
        setIsCreating(true);

        try {
            // Prepare the user object with required supplier fields, converting BigInt values to strings
            const userToSubmit = {
                ...user,
                fullname: supplier.fullname,
                mobile_no: supplier.mobile_no || '',
                email: supplier.email || '',
                role: '5', // Convert BigInt to string
                warehouse_id: supplier.warehouse_id.toString(), // Convert BigInt to string
            };

            const res = await UserService.addUser(userToSubmit);

            if (res.dbsuccess) {
                showLoadingToast(`Creating ${supplier.fullname} ...`, 100);
                setTimeout(() => {
                    navigate('/gp/setup/suppliers');
                    showSuccessToast(`${supplier.fullname}'s user account created successfully`);
                }, 1000);
                const supplierToSubmit = {
                    ...supplier,
                    setup_usergroup_id: supplier.setup_usergroup_type_id!.toString(),
                    warehouse_id: supplier.warehouse_id!.toString(),
                    user_id: res.data.id
                };
                const updatedRes = await updateUserGroup(Number(returnInsertedSupplierId), supplierToSubmit);
            } else {
                showErrorToast(`Error creating user: ${res.message}`);
            }

            setIsCreateAccountDialogOpen(false);
        } catch (err) {
            showErrorToast(`Failed to add user: ${err}`);
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Supplier</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className='ml-3' disabled={(!supplier.fullname || !supplier.account_code || !supplier.email || !supplier.address || !supplier.warehouse_id)}>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Supplier Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Details about a company or individual providing goods or services.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Full Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Full Name"
                                        name="fullname"
                                        value={supplier.fullname}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Second Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Second Name"
                                        name="second_name"
                                        value={supplier.second_name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Area</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Area"
                                        name="area"
                                        value={supplier.area}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Group</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Group"
                                        name="group"
                                        value={supplier.group}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Office Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Office Number"
                                        name="office_no"
                                        value={supplier.office_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Fax Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Fax Number"
                                        name="fax_no"
                                        value={supplier.fax_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Mobile Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Mobile Number"
                                        name="mobile_no"
                                        value={supplier.mobile_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Account Code</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Account Code"
                                        name="account_code"
                                        value={supplier.account_code?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Address</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Address"
                                        name="address"
                                        value={supplier.address?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Address 2</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Address 2"
                                        name="address_2"
                                        value={supplier.address_2?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Address 3</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Address 3"
                                        name="address_3"
                                        value={supplier.address_3?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Address 4</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Address 4"
                                        name="address_4"
                                        value={supplier.address_4?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Email</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="email"
                                        aria-label="Email"
                                        name="email"
                                        value={supplier.email?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Warehouse</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Warehouse"
                                        name="warehouse_id"
                                        value={supplier.warehouse_id.toString()}
                                        onChange={handleChange}
                                    >
                                        {filteredWarehouses.map(warehouse => (
                                            <option key={warehouse.id.toString()} value={warehouse.id.toString()}>
                                                {warehouse.warehouse_name}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button type="submit" disabled={(!supplier.fullname || !supplier.account_code || !supplier.email || !supplier.address || !supplier.warehouse_id)}>Save</Button>
            </div>
            <Dialog open={isCreateAccountDialogOpen} onClose={() => setIsCreateAccountDialogOpen(false)}>
                <DialogTitle>Create User Account</DialogTitle>
                <DialogDescription>
                    Do you want to create a user account for this supplier?
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => navigate('/gp/setup/suppliers')}>
                        Cancel
                    </Button>
                    <Button onClick={() => { setIsInputAccountOpen(true); setIsCreateAccountDialogOpen(false); }}>
                        {isCreating ? <Loading /> : 'Yes'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isInputAccountOpen} onClose={() => setIsInputAccountOpen(false)}>
                <DialogTitle>Create User Account</DialogTitle>
                <DialogDescription>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Username</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Username"
                                        type="text"
                                        name="username"
                                        value={user.username || ''}
                                        onChange={handleEditFormChange}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Password</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Password"
                                        type="password"
                                        name="password"
                                        value={user.password || ''}
                                        onChange={handleEditFormChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => { setIsInputAccountOpen(false); navigate('/gp/setup/suppliers'); }} disabled={isCreating}>
                        Cancel
                    </Button>
                    <Button onClick={handleCreateAccount} disabled={isCreating || !user.password || !user.username}>
                        {isCreating ? <Loading /> : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default SetupAddSupplierPage;
