import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Text } from '../../../../components/Text';
import { Select } from '../../../../components/Select';
import { Textarea } from '../../../../components/Textarea';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import Loading from '../../../../components/Loading';
import { useWarehouses } from '../../../../hooks/useWarehouse';
import { Warehouse } from '../../../../models/Warehouse';
import { Switch, SwitchField, SwitchGroup } from '../../../../components/Switch';

const SetupEditWarehousePage = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>(); // Assuming `id` is passed as a URL parameter
    const [warehouse, setWarehouse] = useState<Warehouse | null>(null);
    const { updateWarehouse, fetchWarehouseById, loading, error } = useWarehouses();
    const [isHQ, setIsHQ] = useState(false)

    useEffect(() => {
        const fetchWarehouseDetails = async () => {
            if (!id) return; // Ensure ID is valid before making the request

            try {
                const fetchedWarehouse = await fetchWarehouseById(Number(id));
                setWarehouse(fetchedWarehouse);
                if (fetchedWarehouse.hq == '1') {
                    setIsHQ(true);
                }
            } catch (err) {
                showErrorToast('Failed to fetch warehouse details.');
            }
        };
        fetchWarehouseDetails();
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setWarehouse(prev => prev ? ({ ...prev, [name]: value }) : null);
    };

    const handleToggleHQ = (checked: any) => {
        setWarehouse(prev => prev ? ({ ...prev, hq: checked }) : null);
        setIsHQ(checked);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!warehouse) return;

        try {
            await updateWarehouse(Number(warehouse.id!), warehouse);
            showLoadingToast(`Updating ${warehouse.warehouse_name}...`, 100);
            setTimeout(() => {
                navigate('/gp/warehouses');
                showSuccessToast(`${warehouse.warehouse_name} updated successfully`);
            }, 1000);
        } catch (err) {
            showErrorToast('Failed to update warehouse.');
        }
    };

    if (loading) return <Loading />;
    if (error) return <p>{error}</p>;
    if (!warehouse) return <p>Warehouse not found.</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Edit Warehouse</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className="ml-3">Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Warehouse</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Details about a GPC warehouse.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Warehouse Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Name"
                                        name="warehouse_name"
                                        value={warehouse.warehouse_name!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Location</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Name"
                                        name="location"
                                        value={warehouse.location!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1 ">
                                    <div className='flex justify-between'>
                                        <Subheading>HQ Warehouse</Subheading>
                                        <SwitchGroup>
                                            <SwitchField>
                                                <Switch
                                                    color="dark/zinc" // Choose color variant or customize
                                                    checked={isHQ} // Pass the current state
                                                    onChange={handleToggleHQ} // Set state on change
                                                />
                                            </SwitchField>
                                        </SwitchGroup>
                                    </div>
                                    <Text>All data will be shown on user account linked to HQ's warehouse</Text>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button type="submit">Save</Button>
            </div>
        </form>
    );
};

export default SetupEditWarehousePage;
