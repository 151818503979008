// hooks/useWarehouseProductItemDepartment.tsx
import { useState, useEffect, useCallback } from 'react';
import { WarehouseProductDepartment } from '../models/WarehouseProductDepartment';
import { WarehouseProductDepartmentService } from '../services/WarehouseProductDepartmentService';

export const useWarehouseProductDepartment = () => {
    const [departments, setDepartments] = useState<WarehouseProductDepartment[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Fetch all departments
    const fetchDepartments = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await WarehouseProductDepartmentService.getAllDepartments();
            setDepartments(data);
        } catch (err) {
            setError("Error fetching departments.");
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchDepartmentById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const data = await WarehouseProductDepartmentService.getDepartmentById(id);
            return data;
        } catch (err) {
            setError('Failed to fetch the department by ID.');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Create a new department
    const createDepartment = async (departmentData: Omit<WarehouseProductDepartment, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const newDepartmentRes = await WarehouseProductDepartmentService.createDepartment(departmentData);
            return newDepartmentRes;
        } catch (err) {
            setError("Error creating department.");
        } finally {
            setLoading(false);
        }
    };

    // Update a department by ID
    const updateDepartment = async (id: number, departmentData: Omit<WarehouseProductDepartment, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const updatedDepartmentRes = await WarehouseProductDepartmentService.updateDepartment(id, departmentData);
            return updatedDepartmentRes;
        } catch (err) {
            setError("Error updating department.");
        } finally {
            setLoading(false);
        }
    };

    // Delete a department by ID
    const deleteDepartment = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            await WarehouseProductDepartmentService.deleteDepartment(id);
            setDepartments((prevDepartments) =>
                prevDepartments.filter((department) => department.id !== id)
            );
        } catch (err) {
            setError("Error deleting department.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDepartments();
    }, [fetchDepartments]);

    return {
        departments,
        loading,
        error,
        fetchDepartments,
        fetchDepartmentById,
        createDepartment,
        updateDepartment,
        deleteDepartment
    };
};
