// services/SetupBrandService.ts
import { SetupBrand } from '../models/SetupBrand';

const API_URL = process.env.REACT_APP_SERVER_URL + "/setupbrand";

export const SetupBrandService = {

    getAllBrands: async () => {
        const response = await fetch(`${API_URL}/getAllBrands`);
        if (!response.ok) {
            throw new Error('Error fetching brands');
        }
        return await response.json();
    },

    getAllBrandsByWarehouseId: async (warehouseId: string) => {
        const response = await fetch(`${API_URL}/getAllBrandsByWarehouseId/${warehouseId}`);
        if (!response.ok) {
            throw new Error('Error fetching brands');
        }
        return await response.json();
    },

    getBrandById: async (id: number): Promise<SetupBrand> => {
        const response = await fetch(`${API_URL}/getBrandById/${id}`);
        if (!response.ok) {
            throw new Error(`Error fetching brand with id ${id}`);
        }
        return await response.json();
    },

    createBrand: async (brandData: Omit<SetupBrand, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await fetch(`${API_URL}/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(brandData),
        });

        if (!response.ok) {
            throw new Error('Error creating brand');
        }

        return await response.json();
    },

    updateBrand: async (id: number, brandData: Omit<SetupBrand, 'created_time' | 'updated_time'>) => {
        const response = await fetch(`${API_URL}/edit/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(brandData),
        });

        if (!response.ok) {
            throw new Error(`Error updating brand with id ${id}`);
        }

        return await response.json();
    },

    deleteBrand: async (id: number): Promise<void> => {
        const response = await fetch(`${API_URL}/delete/${id}`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            throw new Error(`Error deleting brand with id ${id}`);
        }
    },
};
