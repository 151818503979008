import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from '../../../../models/User';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Select } from '../../../../components/Select';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { useRoles } from '../../../../hooks/useRoles';
import Loading from '../../../../components/Loading';
import { useSetupBrand } from '../../../../hooks/useSetupBrand';
import { useWarehouseProductItem } from '../../../../hooks/useWarehouseProductItem';
import { useWarehouseProduct } from '../../../../hooks/useWarehouseProduct';
import { WarehouseProduct } from '../../../../models/WarehouseProduct';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/Table';
import { PencilIcon, TrashIcon } from '@heroicons/react/16/solid';
import { WarehouseProductItem } from '../../../../models/WarehouseProductItem';
import dateFormat from 'dateformat';

interface AddWarehouseProductItem {
    ccode?: string | null;
    sku?: string | null;
    product_id?: number | null;
    item_no: string | null;
    second_item_no: string | null;
    packing?: string | null;
    uom?: string | null;
    minimum_quantity: string | null;
}

const AddWarehouseProductItemPageId = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [productItem, setProductItem] = useState<AddWarehouseProductItem>({
        ccode: '',
        sku: '',
        product_id: Number(id),
        item_no: '',
        second_item_no: '',
        packing: '',
        uom: '',
        minimum_quantity: '',
    });
    const { createMultipleProductItems } = useWarehouseProductItem();
    const { products } = useWarehouseProduct();
    const [selectedProduct, setSelectedProduct] = useState<WarehouseProduct | null>(null);
    const [productItemList, setProductItemList] = useState<AddWarehouseProductItem[]>([]); // To store added stock-in items
    const [isOpen, setIsOpen] = useState(false); // Manage the edit dialog state
    const [editItemIndex, setEditItemIndex] = useState<number | null>(null); // Track which item is being edited
    const [isDeleting, setIsDeleting] = useState(false); // For delete confirmation
    const [editForm, setEditForm] = useState<Partial<AddWarehouseProductItem>>({
        ccode: '',
        sku: '',
        item_no: '',
        second_item_no: '',
        packing: '',
        uom: '',
        minimum_quantity: '',
    });

    useEffect(() => {
        document.title = "Add Product Items";
    }, []);

    useEffect(() => {
        if (!id) return;

        if (id) {
            setProductItem(prev => ({ ...prev, product_id: Number(id) }));
        }

        const matchingProduct = products.find((product) => Number(product.id) === Number(id));
        if (matchingProduct) {
            setSelectedProduct(matchingProduct);
            setProductItem(prev => ({ ...prev, product_id: matchingProduct.id }));
        }

    }, [products]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setProductItem(prev => ({ ...prev, [name]: value }));
    };

    const handleAddToTable = () => {
        const { ccode, sku, item_no, second_item_no, packing, uom, minimum_quantity } = productItem;

        // Validation: Ensure that quantity, batch_no, and expiry_date are not empty
        if (!ccode || !sku || !item_no || !second_item_no || !packing || !uom || !minimum_quantity) {
            showErrorToast('Please fill in all fields before adding to table.');
            return;
        }

        setProductItem({
            ccode: '',
            sku: '',
            item_no: '',
            second_item_no: '',
            packing: '',
            uom: '',
            minimum_quantity: '',
            product_id: Number(selectedProduct!.id),
        }); // Reset the form fields after adding

        setProductItemList(prevList => [...prevList, productItem]);
    };

    const handleSaveAll = async () => {
        try {
            showLoadingToast(`Saving all items...`, 100);
            const res = await createMultipleProductItems(productItemList); // Save all items
            if (res!.dbsuccess) {
                setTimeout(() => {
                    showSuccessToast(`All items saved successfully.`);
                    navigate('/gp/inventories/products'); // Redirect after saving
                }, 1000);
            } else {
                showErrorToast(`Failed adding product items data`);
            }
        } catch (err) {
            showErrorToast(`Failed to save product items: ${err}`);
        }
    };

    const handleDeleteItem = (index: number) => {
        setProductItemList(prevList => prevList.filter((_, i) => i !== index));
    };

    const handleOpenEditDialog = (index: number) => {
        const itemToEdit = productItemList[index];
        setEditForm({
            ccode: itemToEdit.ccode,
            sku: itemToEdit.sku,
            item_no: itemToEdit.item_no,
            second_item_no: itemToEdit.second_item_no,
            packing: itemToEdit.packing,
            uom: itemToEdit.uom,
            minimum_quantity: itemToEdit.minimum_quantity,
        });
        setEditItemIndex(index); // Set the index of the item being edited
        setIsOpen(true); // Open the dialog
    };

    const handleEditSubmit = () => {
        if (editItemIndex !== null) {
            const updatedItems = [...productItemList];
            updatedItems[editItemIndex] = {
                ...updatedItems[editItemIndex],
                ...editForm
            };
            setProductItemList(updatedItems); // Update the list with the edited item
            setIsOpen(false); // Close the dialog
        }
    };

    const handleEditFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditForm(prevForm => ({ ...prevForm, [name]: value }));
    };

    return (
        <form method="post" className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Product Items</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="button" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="button" className="ml-3" onClick={handleSaveAll} disabled={productItemList.length === 0}>
                        Save All
                    </Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Product Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Individual units or variations of a product.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        <div className="sm:col-span-full">
                            <Subheading>Product Barcode</Subheading>
                            <label className='text-zinc-400'>{selectedProduct?.barcode}</label>
                        </div>

                        <div className="sm:col-span-full">
                            <Subheading>Description</Subheading>
                            <label className='text-zinc-400'>{selectedProduct?.description}</label>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>CCode</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="CCode"
                                        name="ccode"
                                        value={productItem.ccode!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Item Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="SKU"
                                        name="sku"
                                        value={productItem.sku!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Item No</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Item No"
                                        name="item_no"
                                        value={productItem.item_no!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Second Item No</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Second Item No"
                                        name="second_item_no"
                                        value={productItem.second_item_no!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Packing</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Packing"
                                        name="packing"
                                        value={productItem.packing!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>UOM</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="UOM"
                                        name="uom"
                                        value={productItem.uom!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Minimum Quantity</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Minimum Quantity"
                                        name="minimum_quantity"
                                        type='number'
                                        value={productItem.minimum_quantity!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4 my-6">
                <Button type="button" onClick={handleAddToTable}>
                    Add to Table
                </Button>
            </div>

            <div>
                <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>S/N</TableHeader>
                            <TableHeader>CCode</TableHeader>
                            <TableHeader>SKU</TableHeader>
                            <TableHeader>Item No</TableHeader>
                            <TableHeader>Second Item No</TableHeader>
                            <TableHeader>Packing</TableHeader>
                            <TableHeader>UOM</TableHeader>
                            <TableHeader>Minimum_Quantity</TableHeader>
                            <TableHeader>Actions</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(productItemList.length > 0) ? productItemList.map((item, index) => (
                            <TableRow key={index} title={`${index}`}>
                                <TableCell >{index + 1}</TableCell>
                                <TableCell >{item.ccode}</TableCell>
                                <TableCell >{item.sku}</TableCell>
                                <TableCell className="text-zinc-500">{item.item_no}</TableCell>
                                <TableCell className="text-zinc-500">{item.second_item_no}</TableCell>
                                <TableCell className="text-zinc-500">{item.packing}</TableCell>
                                <TableCell className="text-zinc-500">{item.uom}</TableCell>
                                <TableCell className="text-zinc-400">{item.minimum_quantity}</TableCell>
                                <TableCell className="py-5 flex gap-4">
                                    <a onClick={() => handleOpenEditDialog(index)} className="text-zinc-600 hover:text-zinc-900">
                                        <PencilIcon className='h-5 w-5' />
                                    </a>
                                    <a onClick={() => handleDeleteItem(index)} className="text-zinc-600 hover:text-zinc-900">
                                        <TrashIcon className='h-5 w-5' />
                                    </a>
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={9} className="text-center py-8">No item found.</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>

            {/* Edit Product Item Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Edit Product Item</DialogTitle>
                <DialogDescription>
                    You can edit the information of the product item.
                </DialogDescription>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>CCode</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="CCode"
                                    type="text"
                                    name="ccode"
                                    value={editForm.ccode || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>SKU</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="SKU"
                                    type="text"
                                    name="sku"
                                    value={editForm.sku || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Item No</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Item No"
                                    type="text"
                                    name="item_no"
                                    value={editForm.item_no || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>

                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Second Item No</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Second Item No"
                                    type="text"
                                    name="second_item_no"
                                    value={editForm.second_item_no || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>

                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Packing</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Packing"
                                    type="text"
                                    name="packing"
                                    value={editForm.packing || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>

                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>UOM</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="UOM"
                                    type="text"
                                    name="uom"
                                    value={editForm.uom || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>

                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Min Quantity</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Min Quantity"
                                    type="number"
                                    name="minimum_quantity"
                                    value={editForm.minimum_quantity || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                </div>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleEditSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default AddWarehouseProductItemPageId;
