// services/SetupUsergroupService.ts
import axios from 'axios';
import { SetupUsergroup } from '../models/SetupUsergroup';

const API_URL = process.env.REACT_APP_SERVER_URL + "/setupusergroup";

const SetupUsergroupService = {

    getAllUserGroups: async (): Promise<SetupUsergroup[]> => {
        const response = await axios.get(`${API_URL}/getAllUserGroups`);
        return response.data;
    },

    getAllSupplier: async (): Promise<SetupUsergroup[]> => {
        const response = await axios.get(`${API_URL}/getAllSupplier`);
        return response.data;
    },

    getAllCustomer: async (): Promise<SetupUsergroup[]> => {
        const response = await axios.get(`${API_URL}/getAllCustomer`);
        return response.data;
    },

    getAllLogistic: async (): Promise<SetupUsergroup[]> => {
        const response = await axios.get(`${API_URL}/getAllLogistic`);
        return response.data;
    },

    getAllSupplierByWarehouseId: async (warehouseId: string) => {
        const response = await axios.get(`${API_URL}/getAllSupplierByWarehouseId/${warehouseId}`);
        return response.data;
    },

    getAllCustomerByWarehouseId: async (warehouseId: string) => {
        const response = await axios.get(`${API_URL}/getAllCustomerByWarehouseId/${warehouseId}`);
        return response.data;
    },

    getAllLogisticByWarehouseId: async (warehouseId: string) => {
        const response = await axios.get(`${API_URL}/getAllLogisticByWarehouseId/${warehouseId}`);
        return response.data;
    },

    getSupplierByUserId: async (userId: string) => {
        const response = await axios.get(`${API_URL}/getSupplierByUserId/${userId}`);
        return response.data;
    },

    getUserGroupById: async (id: number): Promise<SetupUsergroup> => {
        const response = await axios.get(`${API_URL}/getUserGroupById/${id}`);
        return response.data;
    },

    createUserGroup: async (data: SetupUsergroup) => {
        const response = await axios.post(`${API_URL}/add`, data);
        return response.data;
    },

    updateUserGroup: async (id: number, data: Omit<SetupUsergroup, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/edit/${id}`, data);
        return response.data;
    },

    deleteUserGroup: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    },
};

export default SetupUsergroupService;
