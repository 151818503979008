import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon, PencilIcon, TrashIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Avatar } from '../../../../components/Avatar';
import Loading from '../../../../components/Loading';
import dateFormat from 'dateformat';
import { useStockIn } from '../../../../hooks/useStockIn';
import { StockIn } from '../../../../models/StockIn';
import { useStockInItems } from '../../../../hooks/useStockInItem';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import { StockInItem } from '../../../../models/StockInItem';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/Table';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import { Input } from '../../../../components/Input';

// Utility function to format date to yyyy-MM-dd based on local time
const formatDate = (isoString: string): string => {
    const date = new Date(isoString);

    // Get the year, month, and day using local time
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day

    return `${year}-${month}-${day}`; // Format as yyyy-MM-dd
};

const ViewStockInIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [stockIn, setStockIn] = useState<StockIn | null>(null);
    const { fetchStockInById, loading, error } = useStockIn();
    const [stockInItems, setStockInItems] = useState<StockInItem[]>([]);
    const { fetchStockInItemByStockInId, updateStockInItem, deleteStockInItem } = useStockInItems();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false); // Dialog open state
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // Dialog open state
    const [editForm, setEditForm] = useState<Partial<StockInItem>>({}); // Form state for item editing
    const [editingItemId, setEditingItemId] = useState<number | null>(null); // Track the item being edited
    const [selectedStockInItemId, setSelectedStockInItemId] = useState<string | null>(null); // ID of the user to be deleted
    const [isDeleting, setIsDeleting] = useState(false); // Loading state for deletion  

    useEffect(() => {
        document.title = "View Stock In";
    }, []);

    const fetchStockInDetails = async () => {
        if (!id) return;

        try {
            const fetchedStockIn = await fetchStockInById(Number(id));
            setStockIn(fetchedStockIn);
            const fetchedStockInItems = await fetchStockInItemByStockInId(Number(id));
            setStockInItems(fetchedStockInItems);
        } catch (err) {
            showErrorToast('Failed to fetch stock in & items details.');
        }
    };

    useEffect(() => {
        fetchStockInDetails();
    }, [id]);

    const openEditDialog = (item: StockInItem) => {
        setEditingItemId(Number(item.id));
        setEditForm({
            product_item_id: item.product_item_id,
            item_no: item.item_no,
            quantity: item.quantity,
            batch_no: item.batch_no,
            expiry_date: formatDate(item.expiry_date!),
            stock_in_id: item.stock_in_id,
        });
        setIsOpen(true);
    };

    const handleEditFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    };

    const handleEditSubmit = async () => {
        if (editingItemId !== null) {
            showLoadingToast(`Updating ${editForm.item_no}...`, 100);
            const res = await updateStockInItem(editingItemId, {
                ...editForm
            } as StockInItem);

            if (res && res.dbsuccess) {
                showSuccessToast(`${editForm.item_no} updated successfully`);
                setStockInItems((prevItems) =>
                    prevItems.map((item) =>
                        Number(item.id) === editingItemId ? { ...item, ...editForm } : item
                    )
                );
                setIsOpen(false); // Close dialog
            } else {
                showErrorToast(`Failed updating stock-in item data`);
            }
        }
    };

    // Open the delete dialog with the selected user's ID
    const handleOpenDeleteDialog = (stockInId: string) => {
        setSelectedStockInItemId(stockInId);
        setIsDeleteDialogOpen(true);
    };

    // Handle delete user
    const handleDelete = async () => {
        if (!selectedStockInItemId) return;

        setIsDeleting(true);
        try {
            await deleteStockInItem(Number(selectedStockInItemId)); // Call the delete API
            fetchStockInDetails();
            setIsDeleteDialogOpen(false);
        } catch (error) {
            console.error('Failed to delete stock-in item data:', error);
            showErrorToast(`Failed deleting stock-in item data: ${error}`);
        } finally {
            setIsDeleting(false);
        }
    };

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!stockIn) return <p className='py-16 text-center'>Stock In not found.</p>;

    return (
        <>
            <div>
                <div className="max-lg:hidden">
                    <Link to="/gp/stocks/stockin" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                        <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                        Stock In
                    </Link>
                </div>
                <div className="mt-4 lg:mt-8">
                    <div className="flex items-center gap-4">
                        <Avatar square initials={stockIn.reference_no![0]} className="bg-zinc-300 text-gray-700 size-8" />
                        <Heading>{stockIn.reference_no}</Heading>
                    </div>
                    <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                        <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                            <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                                <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                                <span className='pr-1 text-zinc-400'>Created on</span><span>{dateFormat(stockIn.created_time, "mmmm dd, yyyy")}</span>
                            </span>
                        </div>
                        <div className="flex gap-4">
                            <Button outline onClick={() => navigate(`/gp/stocks/stockin/add/items/${stockIn.id}`)}>Add Items</Button>
                            <Button onClick={() => navigate(`/gp/stocks/stockin/edit/${stockIn.id}`)}>Edit</Button>
                        </div>
                    </div>
                </div>
                <div className="mt-12">
                    <Subheading>Stock In Information</Subheading>
                    <Divider className="mt-4" />
                    <DescriptionList>
                        <DescriptionTerm>Reference No</DescriptionTerm>
                        <DescriptionDetails>{stockIn.reference_no!}</DescriptionDetails>
                        <DescriptionTerm>Supplier</DescriptionTerm>
                        <DescriptionDetails>{stockIn.supplier_fullname || '-'}</DescriptionDetails>
                        <DescriptionTerm>Supplier Invoice No</DescriptionTerm>
                        <DescriptionDetails>{stockIn.supplier_invoice || '-'}</DescriptionDetails>
                        <DescriptionTerm>Logistic</DescriptionTerm>
                        <DescriptionDetails>{stockIn.logistic_fullname || '-'}</DescriptionDetails>
                        <DescriptionTerm>Receiver</DescriptionTerm>
                        <DescriptionDetails>{stockIn.receiver_fullname || '-'}</DescriptionDetails>
                        <DescriptionTerm>Created By</DescriptionTerm>
                        <DescriptionDetails>{stockIn.created_by_fullname || '-'}</DescriptionDetails>
                        <DescriptionTerm>Warehouse</DescriptionTerm>
                        <DescriptionDetails>{stockIn.warehouse_name || '-'}</DescriptionDetails>
                    </DescriptionList>
                </div>
                <Divider className='py-4' />
                <div>
                    <Subheading>Stock In Items List</Subheading>
                    <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                        <TableHead>
                            <TableRow>
                                <TableHeader>Product Item No</TableHeader>
                                <TableHeader>Description</TableHeader>
                                <TableHeader>Quantity</TableHeader>
                                <TableHeader>UOM</TableHeader>
                                <TableHeader>Packing</TableHeader>
                                <TableHeader>Batch No</TableHeader>
                                <TableHeader>Expiry Date</TableHeader>
                                <TableHeader>Actions</TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(stockInItems.length > 0) ? stockInItems.map((stockInItem) => (
                                <TableRow key={stockInItem.id} title={`${stockInItem.item_no}`}>
                                    <TableCell>{stockInItem.item_no}</TableCell>
                                    <TableCell>{stockInItem.description}</TableCell>
                                    <TableCell className="text-zinc-500">{stockInItem.quantity}</TableCell>
                                    <TableCell className="text-zinc-500">{stockInItem.uom}</TableCell>
                                    <TableCell className="text-zinc-500">{stockInItem.packing}</TableCell>
                                    <TableCell className="text-zinc-500">{stockInItem.batch_no}</TableCell>
                                    <TableCell className="text-zinc-400">{dateFormat(stockInItem.expiry_date?.toString(), "mmmm dd, yyyy")}</TableCell>
                                    <TableCell className="py-5 flex gap-4">
                                        <a onClick={() => openEditDialog(stockInItem)} className="text-zinc-600 hover:text-zinc-900">
                                            <PencilIcon className='h-5 w-5' />
                                        </a>
                                        <a onClick={() => handleOpenDeleteDialog(stockInItem.id.toString())} className="text-zinc-600 hover:text-zinc-900">
                                            <TrashIcon className='h-5 w-5' />
                                        </a>
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow>
                                <TableCell colSpan={9} className="text-center py-8">No items found.</TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>

                </div>
                {/* Edit Stock In Item Dialog */}
                <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                    <DialogTitle>Edit Stock In Item</DialogTitle>
                    <DialogDescription>
                        You can edit the quantity, batch no, and expiry date of the stock-in item.
                    </DialogDescription>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Quantity</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Edit Quantity"
                                        type="number"
                                        name="quantity"
                                        value={editForm.quantity || ''}
                                        onChange={handleEditFormChange}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Batch Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Edit Batch No"
                                        type="text"
                                        name="batch_no"
                                        value={editForm.batch_no || ''}
                                        onChange={handleEditFormChange}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Expiry Date</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Edit Expiry Date"
                                        type="date"
                                        name="expiry_date"
                                        value={editForm.expiry_date || ''}
                                        onChange={handleEditFormChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                    <DialogActions>
                        <Button plain onClick={() => setIsOpen(false)}>
                            Cancel
                        </Button>
                        <Button onClick={handleEditSubmit}>Save</Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Confirmation Dialog */}
                <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                    <DialogTitle>Delete Stock In Item</DialogTitle>
                    <DialogDescription>
                        Are you sure you want to delete this stock in item? This action cannot be undone.
                    </DialogDescription>
                    <DialogActions>
                        <Button plain onClick={() => setIsDeleteDialogOpen(false)} disabled={isDeleting}>
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} disabled={isDeleting}>
                            {isDeleting ? <Loading /> : 'Delete'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default ViewStockInIdPage;
