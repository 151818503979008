// hooks/useStockOut.ts
import { useState, useEffect, useContext } from 'react';
import { StockOut } from '../models/StockOut';
import StockOutService from '../services/stockoutService';
import showErrorToast from '../components/Toasts/ShowErrorToast';
import { UserContext } from '../context/userContext';

export const useStockOut = () => {
    const [stockOuts, setStockOuts] = useState<StockOut[]>([]);
    const [pickupStockOuts, setPickupStockOuts] = useState<StockOut[]>([]);
    const [completedStockOuts, setCompletedStockOuts] = useState<StockOut[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [totalStockOuts, setTotalStockOuts] = useState<number>(0);
    const { currentUser } = useContext(UserContext);

    const fetchAllFilteredStockOuts = async () => {
        setLoading(true);
        setError(null);
        try {
            if (currentUser?.warehouse_hq === "1") {
                const stockOutData = await StockOutService.getAllStockOuts();
                setStockOuts(stockOutData);
                setTotalStockOuts(stockOutData.length);
            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1") {
                const stockOutData = await StockOutService.getAllStockOutsByWarehouseId(currentUser.warehouse_id.toString());
                setStockOuts(stockOutData);
                setTotalStockOuts(stockOutData.length);
            }
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-outs');
        } finally {
            setLoading(false);
        }
    }

    const fetchAllFilteredPickUpStockOuts = async () => {
        setLoading(true);
        setError(null);
        try {
            if (currentUser?.warehouse_hq === "1") {
                const stockOutData = await StockOutService.getAllPickupStockOuts();
                setPickupStockOuts(stockOutData);
            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1") {
                const stockOutData = await StockOutService.getAllPickupStockOutsByWarehouseId(currentUser.warehouse_id.toString());
                setPickupStockOuts(stockOutData);
            }
        } catch (err: any) {
            setError(err.message || 'Error fetching pick up stock-outs');
        } finally {
            setLoading(false);
        }
    }

    // Fetch all stock-outs
    const fetchStockOuts = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await StockOutService.getAllStockOuts();
            setStockOuts(data);
            setTotalStockOuts(data.length);
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-outs');
            showErrorToast(err.message || 'Error fetching stock-outs');
        } finally {
            setLoading(false);
        }
    };

    const fetchPickupStockOuts = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await StockOutService.getAllPickupStockOuts();
            return data;
        } catch (err: any) {
            setError(err.message || 'Error fetching pickup stock-outs');
            showErrorToast(err.message || 'Error fetching pickup stock-outs');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const fetchPickupStockOutsByWarehouseId = async (warehouseId: string) => {
        setLoading(true);
        setError(null);
        try {
            const data = await StockOutService.getAllPickupStockOutsByWarehouseId(warehouseId);
            return data;
        } catch (err: any) {
            setError(err.message || 'Error fetching pickup stock-outs');
            showErrorToast(err.message || 'Error fetching pickup stock-outs');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const fetchCompletedStockOuts = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await StockOutService.getAllCompletedStockOuts();
            return data;
        } catch (err: any) {
            setError(err.message || 'Error fetching completed stock-outs');
            showErrorToast(err.message || 'Error fetching completed stock-outs');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const fetchCompletedStockOutsByWarehouseId = async (warehouseId: string) => {
        setLoading(true);
        setError(null);
        try {
            const data = await StockOutService.getAllCompletedStockOutsByWarehouseId(warehouseId);
            return data;
        } catch (err: any) {
            setError(err.message || 'Error fetching completed stock-outs');
            showErrorToast(err.message || 'Error fetching completed stock-outs');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Fetch a single stock-out by ID
    const fetchStockOutById = async (id: number): Promise<StockOut | null> => {
        setLoading(true);
        setError(null);
        try {
            return await StockOutService.getStockOutById(id);
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-out');
            showErrorToast(err.message || `Error fetching stock-out ${id}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Create a new stock-out
    const createStockOut = async (StockOutData: Omit<StockOut, 'id' | 'created_time' | 'updated_time' | 'status'>) => {
        setLoading(true);
        setError(null);
        try {
            const newStockOut = await StockOutService.createStockOut(StockOutData);
            setStockOuts((prevStockOuts) => [...prevStockOuts, newStockOut]);
            return newStockOut;
        } catch (err: any) {
            setError(err.message || 'Error creating stock-out');
            showErrorToast(err.message || `Error creating stock-out`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Update an existing stock-out
    const updateStockOut = async (id: number, StockOutData: Omit<StockOut, 'created_time' | 'updated_time' | 'status'>) => {
        setLoading(true);
        setError(null);
        try {
            const updatedStockOut = await StockOutService.updateStockOut(id, StockOutData);
            setStockOuts((prevStockOuts) =>
                prevStockOuts.map((StockOut) => (StockOut.id === id ? updatedStockOut : StockOut))
            );
            return updatedStockOut;
        } catch (err: any) {
            setError(err.message || 'Error updating stock-out');
            showErrorToast(err.message || `Error updating stock-out ${id}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Delete a stock-out
    const deleteStockOut = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            await StockOutService.deleteStockOut(id);
            setStockOuts((prevStockOuts) => prevStockOuts.filter((StockOut) => StockOut.id !== id));
        } catch (err: any) {
            setError(err.message || 'Error deleting stock-out');
            showErrorToast(err.message || `Error deleting stock-out ${id}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllFilteredStockOuts();
        fetchAllFilteredPickUpStockOuts();
    }, [currentUser]);

    return {
        stockOuts,
        pickupStockOuts,
        completedStockOuts,
        fetchStockOuts,
        fetchPickupStockOuts,
        fetchPickupStockOutsByWarehouseId,
        fetchCompletedStockOuts,
        fetchCompletedStockOutsByWarehouseId,
        fetchStockOutById,
        createStockOut,
        updateStockOut,
        deleteStockOut,
        loading,
        error,
        totalStockOuts,
        fetchAllFilteredPickUpStockOuts,
    };
};
