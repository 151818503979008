// services/WarehouseProductItemDepartmentService.ts
import axios from 'axios';
import { WarehouseProductDepartment } from '../models/WarehouseProductDepartment';

const API_URL = process.env.REACT_APP_SERVER_URL + "/warehouseproductdepartment";

export const WarehouseProductDepartmentService = {

    getAllDepartments: async (): Promise<WarehouseProductDepartment[]> => {
        const response = await axios.get(`${API_URL}/getAllDepartments`);
        return response.data;
    },

    getDepartmentById: async (id: number): Promise<WarehouseProductDepartment> => {
        const response = await axios.get(`${API_URL}/getDepartmentById/${id}`);
        return response.data;
    },

    createDepartment: async (department: Omit<WarehouseProductDepartment, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, department);
        return response.data;
    },

    updateDepartment: async (id: number, department: Omit<WarehouseProductDepartment, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/update/${id}`, department);
        return response.data;
    },

    deleteDepartment: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};
