// hooks/useWarehouseProductItemGroup.ts
import { useState, useEffect, useCallback } from 'react';
import { WarehouseProductGroup } from '../models/WarehouseProductGroup';
import { WarehouseProductGroupService } from '../services/warehouseProductGroupService';

export const useWarehouseProductGroup = () => {
    // State management
    const [groups, setGroups] = useState<WarehouseProductGroup[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Fetch all groups and set to state
    const fetchAllGroups = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await WarehouseProductGroupService.getAllGroups();
            setGroups(data);
        } catch (err) {
            setError('Failed to fetch item groups.');
        } finally {
            setLoading(false);
        }
    }, []);

    // Fetch a specific group by ID
    const fetchGroupById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const data = await WarehouseProductGroupService.getGroupById(id);
            return data;
        } catch (err) {
            setError('Failed to fetch the item group by ID.');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Create a new group
    const createGroup = async (group: Omit<WarehouseProductGroup, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const newGroupRes = await WarehouseProductGroupService.createGroup(group);
            setGroups((prevGroups) => [...prevGroups, newGroupRes]);
            return newGroupRes;
        } catch (err) {
            setError('Failed to create item group.');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Update an existing group by ID
    const updateGroup = async (id: number, group: Omit<WarehouseProductGroup, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const updatedGroupRes = await WarehouseProductGroupService.updateGroup(id, group);
            setGroups((prevGroups) =>
                prevGroups.map((g) => (g.id === id ? updatedGroupRes : g))
            );
            return updatedGroupRes;
        } catch (err) {
            setError('Failed to update item group.');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Delete a group by ID
    const deleteGroup = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            await WarehouseProductGroupService.deleteGroup(id);
            setGroups((prevGroups) => prevGroups.filter((g) => g.id !== id));
        } catch (err) {
            setError('Failed to delete item group.');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Automatically fetch groups when the hook is first used
    useEffect(() => {
        fetchAllGroups();
    }, [fetchAllGroups]);

    return {
        groups,
        loading,
        error,
        fetchAllGroups,
        fetchGroupById,
        createGroup,
        updateGroup,
        deleteGroup,
    };
};
