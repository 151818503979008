// services/WarehouseProductItemBatchNoMovementService.ts

import axios from 'axios';
import { WarehouseProductItemBatchNoMovement } from '../models/WarehouseProductItemBatchNoMovement';

const API_URL = process.env.REACT_APP_SERVER_URL + '/warehouseproductitembatchnomovement'; // Update with your actual API endpoint

const WarehouseProductItemBatchNoMovementService = {
    getAllMovements: async () => {
        const response = await axios.get(`${API_URL}/getAllMovements`);
        return response.data;
    },

    getAllMovementsBySupplierId: async (supplierId: string) => {
        const response = await axios.get(`${API_URL}/getAllMovementsBySupplierId/${supplierId}`);
        return response.data;
    },

    getAllMovementsByWarehouseId: async (warehouseId: string) => {
        const response = await axios.get(`${API_URL}/warehouse/${warehouseId}`);
        return response.data;
    },

    getMovementsByProductItemId: async (productItemId: number): Promise<WarehouseProductItemBatchNoMovement[]> => {
        const response = await axios.get(`${API_URL}/product/${productItemId}`);
        return response.data;
    },

    getMovementById: async (id: number): Promise<WarehouseProductItemBatchNoMovement | null> => {
        const response = await axios.get(`${API_URL}/getMovementById/${id}`);
        return response.data;
    },

    createMovement: async (movementData: Omit<WarehouseProductItemBatchNoMovement, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, movementData);
        return response.data;
    },

    updateMovement: async (id: number, movementData: WarehouseProductItemBatchNoMovement) => {
        const response = await axios.put(`${API_URL}/update/${id}`, movementData);
        return response.data;
    },

    deleteMovement: async (id: number) => {
        const response = await axios.delete(`${API_URL}/delete/${id}`);
        return response.data;
    },
};

export default WarehouseProductItemBatchNoMovementService;
