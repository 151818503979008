// hooks/useWarehouseProductItemMovement.ts
import { useState, useEffect, useContext } from 'react';
import { WarehouseProductItemMovement } from '../models/WarehouseProductItemMovement';
import WarehouseProductItemMovementService from '../services/warehouseProductItemMovementService';
import { UserContext } from '../context/userContext';
import { useSetupUsergroup } from './useSetupUsergroup';
import showErrorToast from '../components/Toasts/ShowErrorToast';

const useWarehouseProductItemMovement = () => {
    const [movements, setMovements] = useState<WarehouseProductItemMovement[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { currentUser } = useContext(UserContext);
    const { fetchSupplierByUserId } = useSetupUsergroup();

    const fetchAllFilteredMovements = async () => {
        setLoading(true);
        setError(null);
        try {
            if (currentUser?.warehouse_hq === "1") {
                const movementData = await WarehouseProductItemMovementService.getAllMovements();
                setMovements(movementData);

            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1" && currentUser?.role.toString() !== "5") {
                const movementData = await WarehouseProductItemMovementService.getAllMovementsByWarehouseId(Number(currentUser.warehouse_id.toString()));
                setMovements(movementData);
            } else if (currentUser?.role.toString() === "5") {
                const supplierIdRes = await fetchSupplierByUserId(currentUser.id.toString());
                if (supplierIdRes) {
                    try {
                        const movementData = await WarehouseProductItemMovementService.getAllMovementsBySupplierId(supplierIdRes.id);
                        setMovements(movementData);
                    } catch (err) {
                        showErrorToast(`Error fetching stock movement by supplier id ${err}`)
                    }
                }
            }
        } catch (err) {
            setError('Failed to fetch movements data');
        } finally {
            setLoading(false);
        }
    }

    // Fetch all movements
    const fetchAllMovements = async () => {
        setLoading(true);
        try {
            const data = await WarehouseProductItemMovementService.getAllMovements();
            setMovements(data);
            setError(null);
        } catch (err) {
            setError('Failed to fetch movements');
        } finally {
            setLoading(false);
        }
    };

    // Fetch all movements
    const getAllMovementsBySupplierId = async (supplierId: string) => {
        setLoading(true);
        try {
            const data = await WarehouseProductItemMovementService.getAllMovementsBySupplierId(supplierId);
            setMovements(data);
            setError(null);
            return data;
        } catch (err) {
            setError('Failed to fetch movements');
        } finally {
            setLoading(false);
        }
    };

    // Fetch movements by product item ID
    const fetchMovementsByProductItemId = async (productItemId: number) => {
        setLoading(true);
        try {
            const data = await WarehouseProductItemMovementService.getMovementsByProductItemId(productItemId);
            setMovements(data);
            setError(null);
            return data;
        } catch (err) {
            setError(`Failed to fetch movements for product item ID ${productItemId}`);
        } finally {
            setLoading(false);
        }
    };

    // Fetch movements by product item ID
    const fetchMovementById = async (id: number) => {
        setLoading(true);
        try {
            const data = await WarehouseProductItemMovementService.getMovementById(id);
            return data;
        } catch (err) {
            setError(`Failed to fetch movements for ID ${id}`);
        } finally {
            setLoading(false);
        }
    };

    // Create a new movement
    const createMovement = async (movementData: Omit<WarehouseProductItemMovement, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const newMovementRes = await WarehouseProductItemMovementService.createMovement(movementData);
            setMovements(prevMovements => [...prevMovements, newMovementRes]);
            return newMovementRes;
        } catch (err) {
            setError('Failed to create movement');
        }
    };

    // Update a movement by ID
    const updateMovement = async (id: number, movementData: Omit<WarehouseProductItemMovement, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const updatedMovementRes = await WarehouseProductItemMovementService.updateMovement(id, movementData);
            setMovements(prevMovements =>
                prevMovements.map(movement => (movement.id === id ? updatedMovementRes : movement))
            );
            return updatedMovementRes;
        } catch (err) {
            setError(`Failed to update movement with ID ${id}`);
        }
    };

    // Delete a movement by ID
    const deleteMovement = async (id: number) => {
        try {
            await WarehouseProductItemMovementService.deleteMovement(id);
            setMovements(prevMovements => prevMovements.filter(movement => movement.id !== id));
        } catch (err) {
            setError(`Failed to delete movement with ID ${id}`);
        }
    };

    // Optionally, automatically fetch all movements on mount
    useEffect(() => {
        fetchAllFilteredMovements();
    }, [currentUser]);

    return {
        movements,
        loading,
        error,
        fetchAllMovements,
        getAllMovementsBySupplierId,
        fetchMovementsByProductItemId,
        createMovement,
        updateMovement,
        deleteMovement,
        fetchMovementById
    };
};

export default useWarehouseProductItemMovement;
