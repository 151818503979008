// hooks/useWarehouseProductItemBatchNo.tsx
import { useState, useEffect } from 'react';
import WarehouseProductItemBatchNoService from '../services/warehouseProductItemBatchNoService';
import { WarehouseProductItemBatchNo } from '../models/WarehouseProductItemBatchNo';

export const useWarehouseProductItemBatchNo = () => {
    const [batchNo, setBatchNo] = useState<WarehouseProductItemBatchNo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchAllBatches = async () => {
        setLoading(true);
        try {
            const data = await WarehouseProductItemBatchNoService.getAllBatches();
            setBatchNo(data);
        } catch (err) {
            setError('Error fetching batches');
        } finally {
            setLoading(false);
        }
    };

    const fetchBatchNoById = async (id: number) => {
        setLoading(true);
        try {
            const data = await WarehouseProductItemBatchNoService.getBatchById(id);
            setBatchNo([data]);
        } catch (err) {
            setError('Error fetching batch by ID');
        } finally {
            setLoading(false);
        }
    };

    const fetchBatchNoByProductItemId = async (productItemId: number) => {
        setLoading(true);
        try {
            const data = await WarehouseProductItemBatchNoService.getBatchesByProductItemId(productItemId);
            setBatchNo(data);
            return data;
        } catch (err) {
            setError('Error fetching batches by product item ID');
        } finally {
            setLoading(false);
        }
    };

    const fetchBatchNoByWarehouseId = async (warehouseId: number) => {
        setLoading(true);
        try {
            const data = await WarehouseProductItemBatchNoService.getBatchesByWarehouseId(warehouseId);
            setBatchNo(data);
            return data;
        } catch (err) {
            setError('Error fetching batches by warehouse ID');
        } finally {
            setLoading(false);
        }
    };

    const fetchBatchNoByProductIdAndWarehouseId = async (productItemId: number, warehouseId: number) => {
        setLoading(true);
        try {
            const data = await WarehouseProductItemBatchNoService.getBatchesByProductIdAndWarehouseId(productItemId, warehouseId);
            setBatchNo(data);
            return data;
        } catch (err) {
            setError('Error fetching batches by product ID and warehouse ID');
        } finally {
            setLoading(false);
        }
    };

    const createBatch = async (batchData: Omit<WarehouseProductItemBatchNo, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const newBatchRes = await WarehouseProductItemBatchNoService.createBatch(batchData);
            setBatchNo([...batchNo, newBatchRes]);
            return newBatchRes;
        } catch (err) {
            setError('Error creating batch');
        }
    };

    const updateBatch = async (id: number, batchData: Omit<WarehouseProductItemBatchNo, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const updatedBatchRes = await WarehouseProductItemBatchNoService.updateBatch(id, batchData);
            setBatchNo(batchNo.map(batch => (batch.id === id ? updatedBatchRes : batch)));
            return updatedBatchRes;
        } catch (err) {
            setError('Error updating batch');
        }
    };

    const deleteBatch = async (id: number) => {
        try {
            await WarehouseProductItemBatchNoService.deleteBatch(id);
            setBatchNo(batchNo.filter(batch => batch.id !== id));
        } catch (err) {
            setError('Error deleting batch');
        }
    };

    useEffect(() => {
        fetchAllBatches();
    }, []);

    return {
        batchNo,
        loading,
        error,
        fetchAllBatches,
        fetchBatchNoById,
        fetchBatchNoByProductItemId,
        fetchBatchNoByWarehouseId,
        fetchBatchNoByProductIdAndWarehouseId,
        createBatch,
        updateBatch,
        deleteBatch,
    };
};
