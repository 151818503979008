// hooks/useRoles.ts

import { useState, useEffect } from 'react';
import roleService from '../services/roleService';
import { Role } from '../models/Role';

export const useRoles = () => {
    const [roles, setRoles] = useState<Role[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const rolesData = await roleService.getAllRoles();
                setRoles(rolesData);
            } catch (err) {
                setError('Failed to fetch roles');
            } finally {
                setLoading(false);
            }
        };

        fetchRoles();
    }, []);

    return { roles, loading, error };
};
