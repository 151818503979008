// services/WarehouseProductItemCategoryService.ts
import axios from 'axios';
import { WarehouseProductCategory } from '../models/WarehouseProductCategory';

const API_URL = process.env.REACT_APP_SERVER_URL + "/warehouseproductcategory";

export const WarehouseProductCategoryService = {

    getAllCategories: async (): Promise<WarehouseProductCategory[]> => {
        const response = await axios.get(`${API_URL}/getAllCategories`);
        return response.data;
    },

    getCategoryById: async (id: number): Promise<WarehouseProductCategory> => {
        const response = await axios.get(`${API_URL}/getCategoryById/${id}`);
        return response.data;
    },

    createCategory: async (category: Omit<WarehouseProductCategory, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, category);
        return response.data;
    },

    updateCategory: async (id: number, category: Omit<WarehouseProductCategory, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/update/${id}`, category);
        return response.data;
    },

    deleteCategory: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};
