import { useState, useEffect } from 'react';
import { StockOutItem } from '../models/StockOutItem';
import StockOutItemService from '../services/stockoutItemService';

export const useStockOutItems = () => {
    const [stockOutItems, setStockOutItems] = useState<StockOutItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Fetch all stock out items
    const fetchStockOutItems = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await StockOutItemService.getAllStockOutItems();
            setStockOutItems(data);
        } catch (err: any) {
            setError(err.message || 'Error fetching stock out items');
        } finally {
            setLoading(false);
        }
    };

    // Fetch a stock out item by ID
    const fetchStockOutItemById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const item = await StockOutItemService.getStockOutItemById(id);
            return item;
        } catch (err: any) {
            setError(err.message || `Error fetching stock out item with ID: ${id}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Fetch a stock out item by ID
    const fetchStockOutItemByStockOutId = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const item = await StockOutItemService.getStockOutItemByStockOutId(id);
            return item;
        } catch (err: any) {
            setError(err.message || `Error fetching stock out item with stock out ID: ${id}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Create a new stock out item
    const createStockOutItem = async (data: Omit<StockOutItem, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const newItemRes = await StockOutItemService.createStockOutItem(data);
            return newItemRes;
        } catch (err: any) {
            setError(err.message || 'Error creating stock out item');
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Create multiple stock-out items
    const createMultipleStockOutItems = async (items: Omit<StockOutItem, 'id' | 'created_time' | 'updated_time'>[]) => {
        setLoading(true);
        setError(null);
        const createdItems: StockOutItem[] = [];
        const dbSuccesses: boolean[] = [];

        try {
            // Loop through the items array and create each item
            for (const item of items) {
                const newItemRes = await StockOutItemService.createStockOutItem(item);
                createdItems.push(newItemRes.data);
                dbSuccesses.push(newItemRes.dbSuccess); // Add each success/failure to dbSuccesses
            }

            if (dbSuccesses.includes(false)) {
                return { dbsuccess: false }
            } else {
                return { dbsuccess: true }
            }
        } catch (err: any) {
            setError(err.message || 'Error creating multiple stock-out items');
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Update an existing stock out item
    const updateStockOutItem = async (id: number, data: Omit<StockOutItem, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const updatedItemRes = await StockOutItemService.updateStockOutItem(id, data);
            return updatedItemRes;
        } catch (err: any) {
            setError(err.message || `Error updating stock out item with ID: ${id}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Delete a stock out item
    const deleteStockOutItem = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            await StockOutItemService.deleteStockOutItem(id);
            setStockOutItems(prevItems => prevItems.filter(item => item.id !== BigInt(id)));
        } catch (err: any) {
            setError(err.message || `Error deleting stock out item with ID: ${id}`);
        } finally {
            setLoading(false);
        }
    };

    // Fetch stock out items on mount
    useEffect(() => {
        fetchStockOutItems();
    }, []);

    return {
        stockOutItems,
        loading,
        error,
        fetchStockOutItems,
        fetchStockOutItemById,
        fetchStockOutItemByStockOutId,
        createStockOutItem,
        createMultipleStockOutItems,
        updateStockOutItem,
        deleteStockOutItem,
    };
};
