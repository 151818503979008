import { useState, useEffect, useContext } from 'react';
import { UserService } from '../services/userService';
import { User } from '../models/User';
import { UserContext } from '../context/userContext';
import showErrorToast from '../components/Toasts/ShowErrorToast';

const useUserService = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [totalUsers, setTotalUsers] = useState<number>(0); // Total number of users
    const { currentUser } = useContext(UserContext);

    const fetchAllFilteredUsers = async () => {
        setLoading(true);
        setError(null);
        try {
            if (currentUser?.warehouse_hq === "1") {
                const userData = await UserService.getAllUsers();
                setUsers(userData);
                setTotalUsers(userData.length);
            } else if (currentUser?.warehouse_id && currentUser.role!.toString() === "1") {
                const userData = await UserService.getAllUsersByWarehouseId(currentUser.warehouse_id.toString());
                setUsers(userData);
                setTotalUsers(userData.length);
            }
        } catch (err) {
            setError('Failed to load users');
        } finally {
            setLoading(false);
        }
    };

    const getUserById = async (id: string): Promise<User | null> => {
        setLoading(true);
        setError(null);
        try {
            const user = await UserService.getUserById(id);
            return user;
        } catch (err) {
            setError('Failed to load user');
            throw err;
        } finally {
            setLoading(false);
        }
    }

    const getCurrentUserProfile = async () => {
        setLoading(true);
        setError(null);
        try {
            const profileRes = await UserService.getProfile();
            return profileRes;
        } catch (err) {
            setError(`Failed to fetch current user profile: ${err}`);
            showErrorToast(`Failed to fetch current user profile: ${err}`);
        } finally {
            setLoading(false);
        }
    }

    const deleteUser = async (id: string) => {
        setLoading(true);
        setError(null);
        try {
            await UserService.deleteUser(id);
            setUsers(users.filter((i) => BigInt(i.id) !== BigInt(id)));
        } catch (err) {
            setError('Failed to delete product item');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAllFilteredUsers();
    }, [currentUser]);

    return { users, loading, error, totalUsers, getUserById, deleteUser, getCurrentUserProfile };
};

export default useUserService;
