// src/hooks/useTrackingVerify.ts
import { useEffect, useState } from 'react';
import { TrackingVerify } from '../models/TrackingVerify';
import TrackingVerifyService from '../services/trackingVerifyService';
import showErrorToast from '../components/Toasts/ShowErrorToast';

export const useTrackingVerify = () => {
    const [trackingVerifies, setTrackingVerifies] = useState<TrackingVerify[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchTrackingVerifies = async () => {
        try {
            const data = await TrackingVerifyService.getAllTrackingVerifies();
            setTrackingVerifies(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
            showErrorToast(`Error fetching tracking verifies: ${err}`);
        } finally {
            setLoading(false);
        }
    };

    // Fetch a single tracking verify by ID
    const fetchTrackingVerifyById = async (id: number): Promise<TrackingVerify | null> => {
        setLoading(true);
        setError(null);
        try {
            return await TrackingVerifyService.getTrackingVerifyById(id);
        } catch (err: any) {
            setError(err.message || 'Error fetching tracking verify');
            showErrorToast(`Error fetching tracking verify by id: ${err}, ${id}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Fetch a single tracking verify by stock out ID
    const fetchTrackingVerifyByStockOutId = async (stockOutId: number): Promise<TrackingVerify[] | null> => {
        setLoading(true);
        setError(null);
        try {
            return await TrackingVerifyService.getTrackingVerifyByStockOutId(stockOutId);
        } catch (err: any) {
            setError(err.message || 'Error fetching tracking verify');
            showErrorToast(`Error fetching tracking verify by stock out id: ${err}, ${stockOutId}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const createTrackingVerify = async (data: Omit<TrackingVerify, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const newVerify = await TrackingVerifyService.createTrackingVerify(data);
            setTrackingVerifies((prev) => [...prev, newVerify]);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
            showErrorToast(`Error creating tracking verify: ${err}`);
            throw err;
        }
    };

    const updateTrackingVerify = async (id: number, data: Omit<TrackingVerify, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const updatedVerify = await TrackingVerifyService.updateTrackingVerify(id, data);
            setTrackingVerifies((prev) => prev.map((verify) => (verify.id === id ? updatedVerify : verify)));
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
            showErrorToast(`Error updating tracking verify: ${err}, ${id}`);
            throw err;
        }
    };

    const deleteTrackingVerify = async (id: number) => {
        try {
            await TrackingVerifyService.deleteTrackingVerify(id);
            setTrackingVerifies((prev) => prev.filter((verify) => verify.id !== id));
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
            showErrorToast(`Error deleting tracking verify: ${err}, ${id}`);
        }
    };

    useEffect(() => {
        fetchTrackingVerifies();
    }, []);

    return {
        trackingVerifies,
        loading,
        error,
        fetchTrackingVerifies,
        fetchTrackingVerifyById,
        fetchTrackingVerifyByStockOutId,
        createTrackingVerify,
        updateTrackingVerify,
        deleteTrackingVerify,
    };
};
