import { useState, useEffect } from 'react';
import { StockAdjustItem } from '../models/StockAdjustItem';
import StockAdjustItemService from '../services/stockadjustItemService';

export const useStockAdjustItems = () => {
    const [StockAdjustItems, setStockAdjustItems] = useState<StockAdjustItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Fetch all stock adjust items
    const fetchStockAdjustItems = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await StockAdjustItemService.getAllStockAdjustItems();
            setStockAdjustItems(data);
        } catch (err: any) {
            setError(err.message || 'Error fetching stock adjust items');
        } finally {
            setLoading(false);
        }
    };

    // Fetch a stock adjust item by ID
    const fetchStockAdjustItemById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const item = await StockAdjustItemService.getStockAdjustItemById(id);
            return item;
        } catch (err: any) {
            setError(err.message || `Error fetching stock adjust item with ID: ${id}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Fetch a stock adjust item by ID
    const fetchStockAdjustItemByStockAdjustId = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const item = await StockAdjustItemService.getStockAdjustItemByStockAdjustId(id);
            return item;
        } catch (err: any) {
            setError(err.message || `Error fetching stock adjust item with stock adjust ID: ${id}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Create a new stock adjust item
    const createStockAdjustItem = async (data: Omit<StockAdjustItem, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const newItemRes = await StockAdjustItemService.createStockAdjustItem(data);
            return newItemRes;
        } catch (err: any) {
            setError(err.message || 'Error creating stock adjust item');
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Create multiple stock-adjust items
    const createMultipleStockAdjustItems = async (items: Omit<StockAdjustItem, 'id' | 'created_time' | 'updated_time'>[]) => {
        setLoading(true);
        setError(null);
        const createdItems: StockAdjustItem[] = [];
        const dbSuccesses: boolean[] = [];

        try {
            // Loop through the items array and create each item
            for (const item of items) {
                const newItemRes = await StockAdjustItemService.createStockAdjustItem(item);
                createdItems.push(newItemRes.data);
                dbSuccesses.push(newItemRes.dbSuccess); // Add each success/failure to dbSuccesses
            }

            if (dbSuccesses.includes(false)) {
                return { dbsuccess: false }
            } else {
                return { dbsuccess: true }
            }

        } catch (err: any) {
            setError(err.message || 'Error creating multiple stock-adjust items');
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Update an existing stock adjust item
    const updateStockAdjustItem = async (id: number, data: Omit<StockAdjustItem, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const updatedItemRes = await StockAdjustItemService.updateStockAdjustItem(id, data);
            return updatedItemRes;
        } catch (err: any) {
            setError(err.message || `Error updating stock adjust item with ID: ${id}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Delete a stock adjust item
    const deleteStockAdjustItem = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            await StockAdjustItemService.deleteStockAdjustItem(id);
            setStockAdjustItems(prevItems => prevItems.filter(item => item.id !== BigInt(id)));
        } catch (err: any) {
            setError(err.message || `Error deleting stock adjust item with ID: ${id}`);
        } finally {
            setLoading(false);
        }
    };

    // Fetch stock adjust items on mount
    useEffect(() => {
        fetchStockAdjustItems();
    }, []);

    return {
        StockAdjustItems,
        loading,
        error,
        fetchStockAdjustItems,
        fetchStockAdjustItemById,
        fetchStockAdjustItemByStockAdjustId,
        createStockAdjustItem,
        createMultipleStockAdjustItems,
        updateStockAdjustItem,
        deleteStockAdjustItem,
    };
};
