// frontend/src/pages/Dashboard.tsx
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/userContext';
import { Heading, Subheading } from '../../components/Heading';
import { Select } from '../../components/Select';
import { Badge } from '../../components/Badge';
import { useTranslation } from 'react-i18next';
import { SuperAdminProvider } from '../../provider/superadminProvider';
import { useStockIn } from '../../hooks/useStockIn';
import useUserService from '../../hooks/useUserService';
import { useStockOut } from '../../hooks/useStockOut';
import { useWarehouseProduct } from '../../hooks/useWarehouseProduct';
import { useWarehouseProductItem } from '../../hooks/useWarehouseProductItem';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/Table';
import { Avatar } from '../../components/Avatar';
import dateFormat from 'dateformat';
import { StockOut } from '../../models/StockOut';
import { StockIn } from '../../models/StockIn';
import { WarehouseProduct } from '../../models/WarehouseProduct';
import { WarehouseProductItem } from '../../models/WarehouseProductItem';
import showErrorToast from '../../components/Toasts/ShowErrorToast';
import { useSetupUsergroup } from '../../hooks/useSetupUsergroup';
import { WarehouseProductItemMovement } from '../../models/WarehouseProductItemMovement';
import { WarehouseProductItemBatchNoMovement } from '../../models/WarehouseProductItemBatchNoMovement';
import useWarehouseProductItemMovement from '../../hooks/useWarehouseProductItemMovement';
import useWarehouseProductItemBatchNoMovement from '../../hooks/useWarehouseProductItemBatchNoMovement';
import { Divider } from '../../components/Divider';
import { Text } from '../../components/Text';

export function Stat({ title, value, change, period }: { title: string; value: string; change: string; period: string }) {
    return (
        <div className='bg-zinc-100 p-6 rounded-md ring-1 ring-zinc-200'>
            <div className="text-lg/6 font-medium sm:text-sm/6">{title}</div>
            <div className="mt-3 text-3xl/8 font-semibold sm:text-2xl/8">{value}</div>
            <div className="mt-3 text-sm/6 sm:text-xs/6">
                <Badge color={change.startsWith('+') ? 'lime' : 'pink'}>{change}</Badge>{' '}
                <span className="text-zinc-500">from {period}</span>
            </div>
        </div>
    )
}

// Get current hour to set greeting message
const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) return 'Good morning';
    if (currentHour < 18) return 'Good afternoon';
    return 'Good evening';
};

function SupplierDashboard() {
    const { currentUser } = useContext(UserContext);
    const { fetchSupplierByUserId } = useSetupUsergroup();
    const { movements } = useWarehouseProductItemMovement();
    const { batchNoMovements } = useWarehouseProductItemBatchNoMovement();
    const [period, setPeriod] = useState<string>('last week');

    useEffect(() => {
        document.title = "Dashboard";
    }, []);

    // Helper function to filter by selected period
    const filterByPeriod = (data: any[], period: string) => {
        const now = new Date();
        const startDate = new Date();

        if (period === 'last week') startDate.setDate(now.getDate() - 7);
        else if (period === 'last two') startDate.setDate(now.getDate() - 14);
        else if (period === 'last month') startDate.setMonth(now.getMonth() - 1);

        return data.filter(item => new Date(item.date) >= startDate);
    };

    // Calculate changes
    const calculateChange = (data: any[], period: string) => {
        const filteredData = filterByPeriod(data, period);
        const currentPeriodCount = filteredData.length;
        const previousPeriodCount = data.length - currentPeriodCount;

        if (previousPeriodCount === 0) return '0%';

        const change = ((currentPeriodCount - previousPeriodCount) / previousPeriodCount) * 100;
        return `${change > 0 ? '+' : ''}${change.toFixed(2)}%`;
    };

    return (
        <div>
            <Heading>{getGreeting()}, {currentUser ? currentUser.fullname : "Guest"}</Heading>
            <div className="mt-8 flex items-end justify-between">
                <Subheading>Supplier Dashboard</Subheading>
                <div>
                    <Select
                        name="period"
                        value={period}
                        onChange={(e) => setPeriod(e.target.value)} // Update period state
                    >
                        <option value="last week">Last week</option>
                        <option value="last two weeks">Last two weeks</option>
                        <option value="last month">Last month</option>
                    </Select>
                </div>
            </div>
            <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
                <Stat title="Stock Movement" value={movements.length.toString() || '0'} change={calculateChange(movements, period)} period={period} />
                <Stat title="Stock By Batch" value={batchNoMovements.length.toString() || '0'} change={calculateChange(batchNoMovements, period)} period={period} />
            </div>
        </div>
    )
}

const GHDashboard: React.FC = () => {
    const { currentUser } = useContext(UserContext);
    const { t } = useTranslation();
    const { users } = useUserService();
    const { stockIns } = useStockIn();
    const { stockOuts } = useStockOut();
    const { products } = useWarehouseProduct();
    const { productItemsStockRemind } = useWarehouseProductItem();
    const [period, setPeriod] = useState<string>('last week');
    const { loading, error, fetchCompletedStockOuts, fetchCompletedStockOutsByWarehouseId } = useStockOut();
    const [completedStockOutList, setCompletedStockOutList] = useState<StockOut[]>([]);

    useEffect(() => {
        document.title = "Dashboard";
    }, []);

    // Fetch stockOuts based on user's HQ status
    useEffect(() => {
        const fetchStockOutList = async () => {
            try {
                let fetchedStockOuts;
                // Check if user has HQ access
                if (currentUser?.warehouse_hq === "1") {
                    fetchedStockOuts = await fetchCompletedStockOuts();
                    setCompletedStockOutList(fetchedStockOuts || []); // Set the fetched stockOuts to the state

                } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1") {
                    fetchedStockOuts = await fetchCompletedStockOutsByWarehouseId(currentUser.warehouse_id.toString());
                    setCompletedStockOutList(fetchedStockOuts || []); // Set the fetched stockOuts to the state
                }
            } catch (error) {
                console.error('Failed to fetch stock outs:', error);
            }
        };
        fetchStockOutList();
    }, [currentUser]); // Dependencies to re-run on change

    if (currentUser?.role.toString() === "5") return <SupplierDashboard />;

    // Helper function to filter by selected period
    const filterByPeriod = (data: any[], period: string) => {
        const now = new Date();
        const startDate = new Date();

        if (period === 'last week') startDate.setDate(now.getDate() - 7);
        else if (period === 'last two') startDate.setDate(now.getDate() - 14);
        else if (period === 'last month') startDate.setMonth(now.getMonth() - 1);

        return data.filter(item => new Date(item.date) >= startDate);
    };

    // Calculate changes
    const calculateChange = (data: any[], period: string) => {
        const filteredData = filterByPeriod(data, period);
        const currentPeriodCount = filteredData.length;
        const previousPeriodCount = data.length - currentPeriodCount;

        if (previousPeriodCount === 0) return '0%';

        const change = ((currentPeriodCount - previousPeriodCount) / previousPeriodCount) * 100;
        return `${change > 0 ? '+' : ''}${change.toFixed(2)}%`;
    };

    return (
        <>
            <Heading>{getGreeting()}, {currentUser ? currentUser.fullname : "Guest"}</Heading>
            <div className="mt-8 flex items-end justify-between">
                <Subheading>Dashboard</Subheading>
                <div>
                    <Select
                        name="period"
                        value={period}
                        onChange={(e) => setPeriod(e.target.value)} // Update period state
                    >
                        <option value="last week">Last week</option>
                        <option value="last two weeks">Last two weeks</option>
                        <option value="last month">Last month</option>
                    </Select>
                </div>
            </div>
            <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
                <Stat title="Stock In" value={stockIns.length.toString() || '0'} change={calculateChange(stockIns, period)} period={period} />
                <Stat title="Stock Out" value={stockOuts.length.toString() || '0'} change={calculateChange(stockOuts, period)} period={period} />
                <Stat title="Completed Stock Out" value={completedStockOutList.length.toString() || '0'} change={calculateChange(completedStockOutList, period)} period={period} />
                <Stat title="Out of Stock Reminder" value={productItemsStockRemind.length.toString() || '0'} change="N/A" period={period} />
            </div>

            <div className='mt-14'>
                <div className='justify-between flex'>
                    <div>
                        <Subheading>Inventory Check</Subheading>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            These products are below 100 units. Please reorder as necessary.
                        </p>
                    </div>
                    <div className='bg-red-200 text-center justify-center self-center px-2.5 py-1 rounded-3xl'>
                        <Text>{productItemsStockRemind.length.toString()} Products</Text>
                    </div>
                </div>
                <Table className="mt-6 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>Product Item No</TableHeader>
                            <TableHeader>Product Second Item No</TableHeader>
                            <TableHeader>Description</TableHeader>
                            <TableHeader>Minimum Quantity</TableHeader>
                            <TableHeader className="text-right">Current Quantity</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(productItemsStockRemind.length > 0) ? productItemsStockRemind.map((productItem) => (
                            <TableRow key={productItem.id} title={`${productItem.item_no}`}>
                                <TableCell >{productItem.item_no}</TableCell>
                                <TableCell className="text-zinc-500">{productItem.second_item_no || '-'}</TableCell>
                                <TableCell className="text-zinc-500">{productItem.description || '-'}</TableCell>
                                <TableCell className="text-zinc-500">{productItem.minimum_quantity}</TableCell>
                                <TableCell className="text-red-500 text-right">{productItem.current_quantity}</TableCell>
                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={9} className="text-center py-8">No product found.</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>

            <div className="mt-14">
                <Subheading >Latest Stock Outs</Subheading>
                <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>Reference No</TableHeader>
                            <TableHeader>Invoice No</TableHeader>
                            <TableHeader>Customer</TableHeader>
                            <TableHeader>Status</TableHeader>
                            <TableHeader className="text-right">Created on</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(stockOuts.length > 0) ? stockOuts.slice(0, 3).map((stockOut) => (
                            <TableRow key={stockOut.id} title={`${stockOut.reference_no}`}>
                                <TableCell >{stockOut.reference_no}</TableCell>
                                <TableCell className="text-zinc-500">{stockOut.invoice_no}</TableCell>
                                <TableCell className="text-zinc-500">{stockOut.customer_fullname}</TableCell>
                                <TableCell className="text-zinc-500">{stockOut.status}</TableCell>
                                <TableCell className="text-zinc-400 text-right">{dateFormat(stockOut.created_time, "mmmm dd, yyyy")}</TableCell>
                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={9} className="text-center py-8">No stock out found.</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>

            <div className="mt-14">
                <Subheading>Latest Stock Ins</Subheading>
                <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>Reference No</TableHeader>
                            <TableHeader>Supplier</TableHeader>
                            <TableHeader className="text-right">Created on</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(stockIns.length > 0) ? stockIns.slice(0, 3).map((stockIn) => (
                            <TableRow key={stockIn.id} title={`${stockIn.reference_no}`}>
                                <TableCell >{stockIn.reference_no}</TableCell>
                                <TableCell className="text-zinc-500">{stockIn.supplier_fullname}</TableCell>
                                <TableCell className="text-zinc-400 text-right">{dateFormat(stockIn.created_time, "mmmm dd, yyyy")}</TableCell>
                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={9} className="text-center py-8">No stock in found.</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
        </>
    );
};

export default GHDashboard;
