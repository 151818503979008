// frontend/src/hooks/useAuth.tsx
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
    const [hasAccess, setHasAccess] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('gpcwstoken_kw');

        if (!token) {
            navigate('/'); // Redirect to login if token is missing
            return;
        }

    }, [navigate]);

    return { hasAccess };
};

export default useAuth;
