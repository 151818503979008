import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Text } from '../../../../components/Text';
import { Select } from '../../../../components/Select';
import { Textarea } from '../../../../components/Textarea';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import Loading from '../../../../components/Loading';
import { useWarehouses } from '../../../../hooks/useWarehouse';
import { WarehouseProductCategory } from '../../../../models/WarehouseProductCategory';
import { useWarehouseProductCategory } from '../../../../hooks/useWarehouseProductCategory';
import { useWarehouseProductDepartment } from '../../../../hooks/useWarehouseProductDepartment';
import { WarehouseProductDepartment } from '../../../../models/WarehouseProductDepartment';
import ReactSelect, { SingleValue } from 'react-select';

const EditWarehouseProductCategoryPage = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>(); // Assuming `id` is passed as a URL parameter
    const [category, setCategory] = useState<WarehouseProductCategory | null>(null);
    const { updateCategory, fetchCategoryById, loading, error } = useWarehouseProductCategory();
    const { departments } = useWarehouseProductDepartment();

    useEffect(() => {
        document.title = "Edit Product Category";
    }, []);

    useEffect(() => {
        const fetchCategoryDetails = async () => {
            if (!id) return; // Ensure ID is valid before making the request

            try {
                const fetchedCategory = await fetchCategoryById(Number(id));
                setCategory(fetchedCategory);
            } catch (err) {
                showErrorToast('Failed to fetch category details.');
            }
        };
        fetchCategoryDetails();
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setCategory(prev => prev ? ({ ...prev, [name]: value }) : null);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!category) return;
        if (!category.category_name || !category.department_id) {
            showErrorToast("Please fill in all fields before submit.");
            return;
        }

        try {
            const res = await updateCategory(Number(category.id!), category);
            showLoadingToast(`Updating ${category.category_name}...`, 100);
            if (res.dbsuccess) {
                setTimeout(() => {
                    navigate('/gp/inventories/categories');
                    showSuccessToast(`${category.category_name} updated successfully`);
                }, 1000);
            } else {
                showErrorToast(`Failed updating category data`);
            }
        } catch (err) {
            showErrorToast(`Failed updating category data: ${err}`);
        }
    };

    const selectDepartmentOptions = departments.map((department: WarehouseProductDepartment) => ({
        value: department.id,
        label: `${department.department_name || "-"}`,
    }));

    // Handle ReactSelect change
    const handleDepartmentChange = (newValue: SingleValue<{ value: number; label: string }>) => {
        if (newValue) {
            setCategory((prev) => prev ? ({ ...prev, department_id: newValue.value }) : null);
        }
    };

    if (loading) return <Loading />;
    if (error) return <p>{error}</p>;
    if (!category) return <p>Category not found.</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Edit Product Category</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className="ml-3" disabled={(!category.category_name || !category.department_id)}>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Product Category</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Grouping of products that share similar characteristics.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Category Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Category Name"
                                        name="category_name"
                                        value={category.category_name!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Category Code</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Category Code"
                                        name="category_code"
                                        value={category.category_code!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Department</Subheading>
                                </div>
                                <div className="mt-2">
                                    <ReactSelect
                                        id="department_id"
                                        name="department_id"
                                        options={selectDepartmentOptions}
                                        className="text-sm"
                                        placeholder="Select Department"
                                        onChange={handleDepartmentChange}
                                        value={selectDepartmentOptions.find(option => option.value === category.department_id)}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Remarks</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Remarks"
                                        name="remarks"
                                        value={category.remarks || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button type="submit" disabled={!category.category_name || !category.department_id}>Save</Button>
            </div>
        </form>
    );
};

export default EditWarehouseProductCategoryPage;
