// frontend/src/context/UserContext.tsx
import React, { createContext, useState, useEffect } from 'react';
import { User } from '../models/User';
import { UserService } from '../services/userService';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../components/Dialog';
import { Button } from '../components/Button';
import Loading from '../components/Loading';
import { useLocation } from 'react-router-dom';

interface UserContextType {
    currentUser: User | null;
    setCurrentUser: (user: User | null) => void;
    logout: () => void;
    triggerSessionExpiry: () => void;
}

export const UserContext = createContext<UserContextType>({
    currentUser: null,
    setCurrentUser: () => { },
    logout: () => { },
    triggerSessionExpiry: () => { },
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('gpcwstoken_kw');
        if (token) {
            UserService.getProfile()
                .then((user) => {
                    if (user) {
                        setCurrentUser(user);
                    } else {
                        triggerSessionExpiry(); // Trigger dialog if the user is null
                    }
                })
                .catch(() => triggerSessionExpiry()); // Trigger dialog on error
        } else {
            if (pathname.includes('/gp') && !pathname.includes('/gp/tracking/delivery/confirmation')) {
                triggerSessionExpiry();  // Trigger dialog only if not on login page
            }
        }
    }, [pathname]);

    const logout = () => {
        window.location.assign('/'); // Redirect to login page
        UserService.logout();
        setCurrentUser(null);
    };

    const triggerSessionExpiry = () => {
        setIsDialogOpen(true); // Open the session expired dialog
    };

    const handleLogout = () => {
        setIsLoggingOut(true);
        try {
            logout();
        } catch (error) {
            console.error('Error logging out:', error);
        } finally {
            setIsLoggingOut(false);
            setIsDialogOpen(false);
        }
    };

    return (
        <UserContext.Provider value={{ currentUser, setCurrentUser, logout, triggerSessionExpiry }}>
            {children}
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} >
                <DialogTitle>Session Expired</DialogTitle>
                <DialogDescription>Your session has expired. Please log in again.</DialogDescription>
                <DialogActions>
                    <Button onClick={handleLogout} disabled={isLoggingOut}>
                        {isLoggingOut ? <Loading /> : 'Back to Login'}
                    </Button>
                </DialogActions>
            </Dialog>
        </UserContext.Provider>
    );
};
