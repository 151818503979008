import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom'; // useParams to get route params
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Avatar } from '../../../../components/Avatar';
import { UserService } from '../../../../services/userService'; // Import the UserService
import Loading from '../../../../components/Loading';
import dateFormat from 'dateformat';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { Warehouse } from '../../../../models/Warehouse';
import { useWarehouses } from '../../../../hooks/useWarehouse';

const SetupViewWarehouseIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Use useParams to get the id from the URL
    const [warehouse, setWarehouse] = useState<Warehouse | null>(null);
    const { fetchWarehouseById, loading, error } = useWarehouses();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchWarehouseDetails = async () => {
            if (!id) return; // Ensure ID is valid before making the request

            try {
                const fetchedWarehouse = await fetchWarehouseById(Number(id));
                setWarehouse(fetchedWarehouse);
            } catch (err) {
                showErrorToast('Failed to fetch warehouse details.');
            }
        };
        fetchWarehouseDetails();
    }, [id]);

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!warehouse) return <p className='py-16 text-center'>Warehouse not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/warehouses" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Warehouse
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={warehouse.warehouse_name![0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{warehouse.warehouse_name}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span>{dateFormat(warehouse.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={() => navigate(`/gp/warehouses/edit/${warehouse.id}`)}>Edit</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>customer Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Warehouse Name</DescriptionTerm>
                    <DescriptionDetails>{warehouse.warehouse_name}</DescriptionDetails>
                    <DescriptionTerm>Warehouse</DescriptionTerm>
                    <DescriptionDetails>{warehouse.location}</DescriptionDetails>
                    <DescriptionTerm>HQ</DescriptionTerm>
                    <DescriptionDetails>{(warehouse.hq == '1') ? 'Yes' : 'No'}</DescriptionDetails>
                </DescriptionList>
            </div>
        </>
    );
};

export default SetupViewWarehouseIdPage;
