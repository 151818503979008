import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserService } from '../../services/userService';
import { User } from '../../models/User';
import { Heading, Subheading } from '../../components/Heading';
import { Divider } from '../../components/Divider';
import { Input } from '../../components/Input';
import { Textarea } from '../../components/Textarea';
import { Select } from '../../components/Select';
import { Button } from '../../components/Button';
import showLoadingToast from '../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../components/Toasts/ShowErrorToast';
import { useRoles } from '../../hooks/useRoles';
import Loading from '../../components/Loading';
import { useWarehouses } from '../../hooks/useWarehouse';

interface AddUser {
    username: string;
    password: string;
    confirmPassword?: string; // Optional as it's not part of the AddUser interface but used for validation
    role: bigint;
    fullname: string;
    email: string;
    mobile_no: string;
    street_address: string;
    remarks: string;
    warehouse_id: bigint;
}

const DeveloperAddUserPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [user, setUser] = useState<AddUser>({
        username: '',
        password: '',
        confirmPassword: '',
        role: BigInt(0),
        fullname: '',
        email: '',
        mobile_no: '',
        street_address: '',
        remarks: '',
        warehouse_id: BigInt(0),
    });

    const [allUsers, setAllUsers] = useState<User[]>([]);
    const { roles, loading, error } = useRoles();
    const { warehouses } = useWarehouses();

    useEffect(() => {
        document.title = "Welcome Developer";
    }, []);

    useEffect(() => {
        // Check the 'devid' parameter in the URL
        const params = new URLSearchParams(window.location.search);
        const devid = params.get('devid');

        if (devid !== 'ew24kw_53fox') {
            navigate('/accessdenied');
        }
    }, [navigate]);

    useEffect(() => {
        if (roles.length > 0) {
            setUser(prev => ({ ...prev, role: roles[0].id }));
        }
        if (warehouses.length > 0) {
            setUser(prev => ({ ...prev, warehouse_id: warehouses[0].id }));
        }
    }, [roles, warehouses]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setUser(prev => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {
        if (!user.fullname) {
            showErrorToast('Full name is required');
            return false;
        }
        if (!user.username) {
            showErrorToast('Username is required');
            return false;
        }
        if (!user.password) {
            showErrorToast('Password is required');
            return false;
        }
        if (user.password !== user.confirmPassword) {
            showErrorToast('Passwords do not match');
            return false;
        }
        if (user.role === BigInt(0)) {
            showErrorToast('Role is required');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const userToSubmit = {
                ...user,
                role: user.role.toString(),
            };

            const res = await UserService.addUserDev(userToSubmit);
            if (res.dbsuccess) {
                showLoadingToast(`Creating ${user.fullname} ...`, 100);
                setTimeout(() => {
                    showSuccessToast(`${user.fullname} added successfully`);
                }, 1000);
            } else {
                showErrorToast(`Error creating user: ${res.message}`);
            }
        } catch (err) {
            showErrorToast(`Failed to add user: ${err}`);
        }
    };

    if (loading) return <Loading />;
    if (error) return <p>{error}</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl py-16">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add User</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className='ml-3'>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            This information will be displayed publicly so be careful what you share.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Full Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Full Name"
                                        name="fullname"
                                        value={user.fullname}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Email</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="email"
                                        aria-label="Email"
                                        name="email"
                                        value={user.email?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Mobile Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Mobile Number"
                                        name="mobile_no"
                                        value={user.mobile_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Street Address</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Street Address"
                                        name="street_address"
                                        value={user.street_address?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Remarks</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Textarea
                                        aria-label="Remarks"
                                        name="remarks"
                                        value={user.remarks?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Account Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Provide account details for the user.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Username</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Username"
                                        name="username"
                                        value={user.username}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Password</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="password"
                                        aria-label="Password"
                                        name="password"
                                        value={user.password}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Confirm Password</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="password"
                                        aria-label="Confirm Password"
                                        name="confirmPassword"
                                        value={user.confirmPassword}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Role</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Role"
                                        name="role"
                                        value={user.role.toString()}
                                        onChange={handleChange}
                                    >
                                        {roles.map(role => (
                                            <option key={role.id.toString()} value={role.id.toString()}>
                                                {role.rolename}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Warehouse</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Warehouse"
                                        name="warehouse_id"
                                        value={user.warehouse_id.toString()}
                                        onChange={handleChange}
                                    >
                                        {warehouses.map(warehouse => (
                                            <option key={warehouse.id.toString()} value={warehouse.id.toString()}>
                                                {warehouse.warehouse_name}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-4 my-6">
                    <Button type="reset" plain onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit">Save</Button>
                </div>
            </div>
        </form>
    );
};

export default DeveloperAddUserPage;
