// services/WarehouseProductItemService.ts
import axios from 'axios';
import { WarehouseProductItem } from '../models/WarehouseProductItem';

const API_URL = process.env.REACT_APP_SERVER_URL + "/warehouseproductitem";

export const WarehouseProductItemService = {
    getAllProductItems: async () => {
        const response = await axios.get(`${API_URL}/getAllProductItems`);
        return response.data;
    },

    getAllProductItemsByWarehouseId: async (warehouseId: string) => {
        const response = await axios.get(`${API_URL}/getAllProductItemsByWarehouseId/${warehouseId}`);
        return response.data;
    },

    getAllProductItemsStockRemind: async () => {
        const response = await axios.get(`${API_URL}/getAllProductItemsStockRemind`);
        return response.data;
    },

    getAllProductItemsStockRemindByWarehouseId: async (warehouseId: string) => {
        const response = await axios.get(`${API_URL}/getAllProductItemsStockRemindByWarehouseId/${warehouseId}`);
        return response.data;
    },

    getProductItemById: async (id: number): Promise<WarehouseProductItem> => {
        const response = await axios.get(`${API_URL}/getProductItemById/${id}`);
        return response.data;
    },

    getProductItemByProductId: async (id: number): Promise<WarehouseProductItem[]> => {
        const response = await axios.get(`${API_URL}/getProductItemByProductId/${id}`);
        return response.data;
    },

    createProductItem: async (item: Omit<WarehouseProductItem, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, item);
        return response.data;
    },

    updateProductItem: async (id: number, item: Omit<WarehouseProductItem, 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/edit/${id}`, item);
        return response.data;
    },

    deleteProductItem: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};
