// hooks/useSetupUsergroup.tsx
import { useState, useEffect, useContext } from 'react';
import SetupUsergroupService from '../services/setupUsergroupService';
import { SetupUsergroup } from '../models/SetupUsergroup';
import { UserContext } from '../context/userContext';

export const useSetupUsergroup = () => {
    const [userGroups, setUserGroups] = useState<SetupUsergroup[]>([]);
    const [suppliers, setSuppliers] = useState<SetupUsergroup[]>([]);
    const [customers, setCustomers] = useState<SetupUsergroup[]>([]);
    const [logistics, setLogistics] = useState<SetupUsergroup[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [totalUsergroups, setTotalUsergroups] = useState<number>(0); // Total number of usergroups
    const [totalSuppliers, setTotalSuppliers] = useState<number>(0); // Total number of usergroups
    const [totalCustomers, setTotalCustomers] = useState<number>(0); // Total number of usergroups
    const [totalLogistics, setTotalLogistics] = useState<number>(0); // Total number of usergroups
    const { currentUser } = useContext(UserContext);

    const fetchFilteredUserGroups = async () => {
        setLoading(true);
        setError(null);
        try {
            if (currentUser?.warehouse_hq === "1") {
                const supplierData = await SetupUsergroupService.getAllSupplier();
                setSuppliers(supplierData);
                setTotalSuppliers(supplierData.length);

                const losgiticData = await SetupUsergroupService.getAllLogistic();
                setLogistics(losgiticData);
                setTotalLogistics(losgiticData.length);

                const customerData = await SetupUsergroupService.getAllCustomer();
                setCustomers(customerData);
                setTotalCustomers(customerData.length);
            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1") {
                const supplierData = await SetupUsergroupService.getAllSupplierByWarehouseId(currentUser.warehouse_id.toString());
                setSuppliers(supplierData);
                setTotalSuppliers(supplierData.length);

                const losgiticData = await SetupUsergroupService.getAllLogisticByWarehouseId(currentUser.warehouse_id.toString());
                setLogistics(losgiticData);
                setTotalLogistics(losgiticData.length);

                const customerData = await SetupUsergroupService.getAllCustomerByWarehouseId(currentUser.warehouse_id.toString());
                setCustomers(customerData);
                setTotalCustomers(customerData.length);
            }
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    }

    const fetchUserGroups = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await SetupUsergroupService.getAllUserGroups();
            setUserGroups(data);
            setTotalUsergroups(data.length);
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    };

    const getUserGroups = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await SetupUsergroupService.getAllUserGroups();
            setUserGroups(data);
            setTotalUsergroups(data.length);
            return data;
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    };

    const fetchSupplier = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await SetupUsergroupService.getAllSupplier();
            setSuppliers(data);
            setTotalSuppliers(data.length);
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    };

    const fetchSupplierByUserId = async (userId: string) => {
        setLoading(true);
        setError(null);
        try {
            const data = await SetupUsergroupService.getSupplierByUserId(userId);
            return data;
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    };

    const getSuppliers = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await SetupUsergroupService.getAllSupplier();
            return data;
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    };

    const getSuppliersByWarehouseId = async (warehouseId: string) => {
        setLoading(true);
        setError(null);
        try {
            const data = await SetupUsergroupService.getAllSupplierByWarehouseId(warehouseId);
            return data;
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    };

    const fetchCustomer = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await SetupUsergroupService.getAllCustomer();
            setCustomers(data);
            setTotalCustomers(data.length);
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    };

    const getCustomers = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await SetupUsergroupService.getAllCustomer();
            return data;
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    };

    const getCustomersByWarehouseId = async (warehouseId: string) => {
        setLoading(true);
        setError(null);
        try {
            const data = await SetupUsergroupService.getAllCustomerByWarehouseId(warehouseId);
            return data;
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    };

    const fetchLogistic = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await SetupUsergroupService.getAllLogistic();
            setLogistics(data);
            setTotalLogistics(data.length);
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    };

    const getLogistics = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await SetupUsergroupService.getAllLogistic();
            return data;
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    };

    const getLogisticsByWarehouseId = async (warehouseId: string) => {
        setLoading(true);
        setError(null);
        try {
            const data = await SetupUsergroupService.getAllLogisticByWarehouseId(warehouseId);
            return data;
        } catch (err) {
            setError('Failed to fetch user groups');
        } finally {
            setLoading(false);
        }
    };

    const fetchUserGroupById = async (id: number) => {
        try {
            setLoading(true);
            const singleUserGroup = await SetupUsergroupService.getUserGroupById(id);
            return singleUserGroup;
        } catch (err) {
            setError('Failed to load this user group');
            throw err;
        } finally {
            setLoading(false);
        }
    }

    const createUserGroup = async (data: any) => {
        try {
            const newUsergroup = await SetupUsergroupService.createUserGroup(data);
            setUserGroups((prev) => [...prev, newUsergroup]);
            return newUsergroup;
        } catch (err) {
            setError('Failed to create user group');
        }
    };

    const updateUserGroup = async (id: number, data: any) => {
        try {
            const updatedUsergroup = await SetupUsergroupService.updateUserGroup(id, data);
            setUserGroups((prev) =>
                prev.map((ug) => (ug.id === id ? updatedUsergroup : ug))
            );
            return updatedUsergroup;
        } catch (err) {
            setError('Failed to update user group');
        }
    };

    const deleteUserGroup = async (id: number) => {
        try {
            await SetupUsergroupService.deleteUserGroup(id);
            setUserGroups((prev) => prev.filter((ug) => ug.id !== id));
        } catch (err) {
            setError('Failed to delete user group');
        }
    };

    useEffect(() => {
        fetchUserGroups();
        fetchSupplier();
        fetchCustomer();
        fetchLogistic();
        fetchFilteredUserGroups();
    }, [currentUser]);

    return {
        userGroups,
        suppliers,
        customers,
        logistics,
        loading,
        error,
        fetchUserGroups,
        fetchSupplier,
        fetchSupplierByUserId,
        fetchCustomer,
        fetchLogistic,
        fetchUserGroupById,
        createUserGroup,
        updateUserGroup,
        deleteUserGroup,
        totalUsergroups,
        totalCustomers,
        totalSuppliers,
        totalLogistics,
        getUserGroups,
        getCustomersByWarehouseId,
        getLogisticsByWarehouseId,
        getSuppliersByWarehouseId,
        getCustomers,
        getSuppliers,
        getLogistics
    };
};
