// src/hooks/useTrackingPickup.ts
import { useEffect, useState } from 'react';
import { TrackingPickup } from '../models/TrackingPickup';
import TrackingPickupService from '../services/trackingPickupService';
import showErrorToast from '../components/Toasts/ShowErrorToast';

export const useTrackingPickup = () => {
    const [trackingPickups, setTrackingPickups] = useState<TrackingPickup[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchTrackingPickups = async () => {
        try {
            const data = await TrackingPickupService.getAllTrackingPickups();
            setTrackingPickups(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
            showErrorToast(`Error fetching tracking pickup: ${err}`);
        } finally {
            setLoading(false);
        }
    };

    // Fetch a single stock-in by ID
    const fetchTrackingPickupById = async (id: number): Promise<TrackingPickup | null> => {
        setLoading(true);
        setError(null);
        try {
            return await TrackingPickupService.getTrackingPickupById(id);
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-in');
            showErrorToast(`Error fetching tracking pickup by id: ${err}, ${id}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Fetch a single stock-in by ID
    const fetchTrackingPickupByStockOutId = async (id: number): Promise<TrackingPickup | null> => {
        setLoading(true);
        setError(null);
        try {
            return await TrackingPickupService.getTrackingPickupByStockOutId(id);
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-in');
            showErrorToast(`Error fetching tracking pickup by stock out id: ${err}, ${id}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const createTrackingPickup = async (data: Omit<TrackingPickup, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const newPickup = await TrackingPickupService.createTrackingPickup(data);
            setTrackingPickups((prev) => [...prev, newPickup]);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
            showErrorToast(`Error creating tracking pickup: ${err}`);
            throw err;
        }
    };

    const updateTrackingPickup = async (id: number, data: Omit<TrackingPickup, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const updatedPickup = await TrackingPickupService.updateTrackingPickup(id, data);
            setTrackingPickups((prev) => prev.map((pickup) => (pickup.id === id ? updatedPickup : pickup)));
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
            showErrorToast(`Error updating tracking pickup: ${err} , ${id}`);
            throw err;
        }
    };

    const deleteTrackingPickup = async (id: number) => {
        try {
            await TrackingPickupService.deleteTrackingPickup(id);
            setTrackingPickups((prev) => prev.filter((pickup) => pickup.id !== id));
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
            showErrorToast(`Error deleting tracking pickup: ${err}, ${id}`);
        }
    };

    useEffect(() => {
        fetchTrackingPickups();
    }, []);

    return {
        trackingPickups,
        loading,
        error,
        fetchTrackingPickups,
        fetchTrackingPickupById,
        fetchTrackingPickupByStockOutId,
        createTrackingPickup,
        updateTrackingPickup,
        deleteTrackingPickup,
    };
};
