// services/StockInService.ts
import axios from 'axios';
import { StockIn } from '../models/StockIn';

const API_URL = process.env.REACT_APP_SERVER_URL + "/stockin";

const StockInService = {
    // Fetch all stock-ins
    getAllStockIns: async () => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getAllStockIns`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    },

    // Fetch all stock-ins
    getAllStockInsByWarehouseId: async (warehouseId: string) => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getAllStockInsByWarehouseId/${warehouseId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    // Fetch stock-in by ID
    getStockInById: async (id: number): Promise<StockIn> => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getStockInById/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    // Create a new stock-in
    createStockIn: async (stockInData: Omit<StockIn, 'id' | 'created_time' | 'updated_time'>) => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.post(`${API_URL}/add`, stockInData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    // Update an existing stock-in by ID
    updateStockIn: async (id: number, stockInData: Omit<StockIn, 'id' | 'created_time' | 'updated_time'>) => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.put(`${API_URL}/edit/${id}`, stockInData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    // Delete a stock-in by ID
    deleteStockIn: async (id: number): Promise<void> => {
        const token = localStorage.getItem('gpcwstoken_kw');
        await axios.delete(`${API_URL}/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
};

export default StockInService;
