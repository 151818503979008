// hooks/useWarehouseProductItemBatchNoMovement.ts

import { useContext, useEffect, useState } from 'react';
import WarehouseProductItemBatchNoMovementService from '../services/warehouseProductItemBatchNoMovementService';
import { WarehouseProductItemBatchNoMovement } from '../models/WarehouseProductItemBatchNoMovement';
import { UserContext } from '../context/userContext';
import showErrorToast from '../components/Toasts/ShowErrorToast';
import { useSetupUsergroup } from './useSetupUsergroup';

const useWarehouseProductItemBatchNoMovement = (warehouseId?: number) => {
    const [batchNoMovements, setBatchNoMovements] = useState<WarehouseProductItemBatchNoMovement[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { currentUser } = useContext(UserContext);
    const { fetchSupplierByUserId } = useSetupUsergroup();

    const fetchAllFilteredBatchNoMovements = async () => {
        setLoading(true);
        setError(null);
        try {
            if (currentUser?.warehouse_hq === "1") {
                const movementData = await WarehouseProductItemBatchNoMovementService.getAllMovements();
                setBatchNoMovements(movementData);

            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1" && currentUser?.role.toString() !== "5") {
                const movementData = await WarehouseProductItemBatchNoMovementService.getAllMovementsByWarehouseId(currentUser.warehouse_id.toString());
                setBatchNoMovements(movementData);
            } else if (currentUser?.role.toString() === "5") {
                const supplierIdRes = await fetchSupplierByUserId(currentUser.id.toString());
                if (supplierIdRes) {
                    try {
                        const movementData = await WarehouseProductItemBatchNoMovementService.getAllMovementsBySupplierId(supplierIdRes.id);
                        setBatchNoMovements(movementData);
                    } catch (err) {
                        showErrorToast(`Error fetching stock movement by supplier id ${err}`)
                    }
                }
            }
        } catch (err) {
            setError('Failed to fetch profuct items');
        } finally {
            setLoading(false);
        }
    }

    const fetchMovementById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const data = await WarehouseProductItemBatchNoMovementService.getMovementById(id);
            return data;
        } catch (err) {
            showErrorToast(`Error fetching stock movement by id ${err}`)
            setError(`Error fetching stock movement by id ${err}`)
            throw err;
        } finally {
            setLoading(false);
        }
    }

    const createMovement = async (movementData: Omit<WarehouseProductItemBatchNoMovement, 'id' | 'created_time' | 'updated_time'>) => {
        const newMovement = await WarehouseProductItemBatchNoMovementService.createMovement(movementData);
        setBatchNoMovements(prev => [...prev, newMovement]);
    };

    const updateMovement = async (id: number, movementData: WarehouseProductItemBatchNoMovement) => {
        const updatedMovement = await WarehouseProductItemBatchNoMovementService.updateMovement(id, movementData);
        setBatchNoMovements(prev => prev.map(movement => movement.id === id ? updatedMovement : movement));
    };

    const deleteMovement = async (id: number) => {
        await WarehouseProductItemBatchNoMovementService.deleteMovement(id);
        setBatchNoMovements(prev => prev.filter(movement => movement.id !== id));
    };

    useEffect(() => {
        fetchAllFilteredBatchNoMovements();
    }, [currentUser]);

    return {
        batchNoMovements,
        loading,
        error,
        createMovement,
        updateMovement,
        deleteMovement,
        fetchMovementById,
    };
};

export default useWarehouseProductItemBatchNoMovement;
