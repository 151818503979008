import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon, PencilIcon, TrashIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom'; // useParams to get route params
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Avatar } from '../../../../components/Avatar';
import { UserService } from '../../../../services/userService'; // Import the UserService
import Loading from '../../../../components/Loading';
import dateFormat from 'dateformat';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { WarehouseProduct } from '../../../../models/WarehouseProduct';
import { useWarehouseProduct } from '../../../../hooks/useWarehouseProduct';
import { WarehouseProductItem } from '../../../../models/WarehouseProductItem';
import { useWarehouseProductItem } from '../../../../hooks/useWarehouseProductItem';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/Table';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import { Input } from '../../../../components/Input';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';

const ViewWarehouseProductIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Use useParams to get the id from the URL
    const [product, setProduct] = useState<WarehouseProduct | null>(null);
    const { fetchProductById, loading, error } = useWarehouseProduct();
    const [productItems, setProductItems] = useState<WarehouseProductItem[]>([]);
    const { fetchProductItemByProductId, updateProductItem, deleteProductItem } = useWarehouseProductItem();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false); // Dialog open state
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // Dialog open state
    const [editForm, setEditForm] = useState<Partial<WarehouseProductItem>>({}); // Form state for item editing
    const [editingItemId, setEditingItemId] = useState<number | null>(null); // Track the item being edited
    const [selectedProductItemId, setSelectedProductItemId] = useState<string | null>(null); // ID of the user to be deleted
    const [isDeleting, setIsDeleting] = useState(false); // Loading state for deletion  

    useEffect(() => {
        document.title = "View Product";
    }, []);

    const fetchProductDetails = async () => {
        if (!id) return; // Ensure ID is valid before making the request

        try {
            const fetchedProduct = await fetchProductById(Number(id));
            setProduct(fetchedProduct);
            const fetchedProductItems = await fetchProductItemByProductId(Number(id));
            setProductItems(fetchedProductItems);
        } catch (err) {
            showErrorToast('Failed to fetch product details.');
        }
    };

    useEffect(() => {
        fetchProductDetails();
    }, [id]);

    const openEditDialog = (item: WarehouseProductItem) => {
        setEditingItemId(Number(item.id));
        setEditForm({
            ccode: item.ccode,
            sku: item.sku,
            item_no: item.item_no,
            second_item_no: item.second_item_no,
            packing: item.packing,
            uom: item.uom,
            minimum_quantity: item.minimum_quantity,
            product_id: item.product_id
        });
        setIsOpen(true);
    };

    const handleEditFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    };

    const handleEditSubmit = async () => {
        if (editingItemId !== null) {
            showLoadingToast(`Updating ${editForm.item_no}...`, 100);
            const res = await updateProductItem(editingItemId, {
                ...editForm
            } as WarehouseProductItem);

            if (res && res.dbsuccess) {
                showSuccessToast(`${editForm.item_no} updated successfully`);
                setProductItems((prevItems) =>
                    prevItems.map((item) =>
                        Number(item.id) === editingItemId ? { ...item, ...editForm } : item
                    )
                );
                setIsOpen(false); // Close dialog
            } else {
                showErrorToast(`Failed updating product item data`);
            }
        }
    };

    // Open the delete dialog with the selected user's ID
    const handleOpenDeleteDialog = (stockInId: string) => {
        setSelectedProductItemId(stockInId);
        setIsDeleteDialogOpen(true);
    };

    // Handle delete user
    const handleDelete = async () => {
        if (!selectedProductItemId) return;

        setIsDeleting(true);
        try {
            await deleteProductItem(Number(selectedProductItemId)); // Call the delete API
            fetchProductDetails();
            setIsDeleteDialogOpen(false);
        } catch (error) {
            console.error('Failed to delete product item data:', error);
            showErrorToast(`Failed deleting product item data: ${error}`);
        } finally {
            setIsDeleting(false);
        }
    };

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!product) return <p className='py-16 text-center'>Product not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/inventories/products" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Products
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={product.barcode![0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{product.barcode}</Heading>
                    <Badge color="lime">{product.sku!}</Badge>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span className='pr-1 text-zinc-400'>Created on</span><span>{dateFormat(product.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button outline onClick={() => navigate(`/gp/inventories/products/add/items/${product.id}`)}>Add Items</Button>
                        <Button onClick={() => navigate(`/gp/inventories/products/edit/${product.id}`)}>Edit</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>Product Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>SKU</DescriptionTerm>
                    <DescriptionDetails>{product.sku}</DescriptionDetails>
                    <DescriptionTerm>Barcode</DescriptionTerm>
                    <DescriptionDetails>{product.barcode}</DescriptionDetails>
                    <DescriptionTerm>Description</DescriptionTerm>
                    <DescriptionDetails>{product.description}</DescriptionDetails>
                    <DescriptionTerm>Category</DescriptionTerm>
                    <DescriptionDetails>{product.category_name}</DescriptionDetails>
                    <DescriptionTerm>Group</DescriptionTerm>
                    <DescriptionDetails>{product.group_name}</DescriptionDetails>
                    <DescriptionTerm>Location</DescriptionTerm>
                    <DescriptionDetails>{product.location}</DescriptionDetails>
                </DescriptionList>
            </div>
            <Divider className='py-4' />
            <div>
                <div>
                    <Subheading>Product Items List</Subheading>
                    <div>
                        <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>CCode</TableHeader>
                                    <TableHeader>SKU</TableHeader>
                                    <TableHeader>Item No</TableHeader>
                                    <TableHeader>Second Item No</TableHeader>
                                    <TableHeader>Packing</TableHeader>
                                    <TableHeader>UOM</TableHeader>
                                    <TableHeader>Minimum Quantity</TableHeader>
                                    <TableHeader>Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(productItems.length > 0) ? productItems.map((item, index) => (
                                    <TableRow key={index} title={`${index}`}>
                                        <TableCell >{index + 1}</TableCell>
                                        <TableCell >{item.ccode}</TableCell>
                                        <TableCell >{item.sku}</TableCell>
                                        <TableCell className="text-zinc-500">{item.item_no}</TableCell>
                                        <TableCell className="text-zinc-500">{item.second_item_no}</TableCell>
                                        <TableCell className="text-zinc-500">{item.packing}</TableCell>
                                        <TableCell className="text-zinc-500">{item.uom}</TableCell>
                                        <TableCell className="text-zinc-400">{item.minimum_quantity}</TableCell>
                                        <TableCell className="py-5 flex gap-4">
                                            <a onClick={() => openEditDialog(item)} className="text-zinc-600 hover:text-zinc-900">
                                                <PencilIcon className='h-5 w-5' />
                                            </a>
                                            <a onClick={() => handleOpenDeleteDialog(item.id.toString())} className="text-zinc-600 hover:text-zinc-900">
                                                <TrashIcon className='h-5 w-5' />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={9} className="text-center py-8">No item found.</TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </div>

                </div>
                {/* Edit Product Item Dialog */}
                <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                    <DialogTitle>Edit Product Item</DialogTitle>
                    <DialogDescription>
                        You can edit the information of the product item.
                    </DialogDescription>
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>CCode</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="CCode"
                                        type="text"
                                        name="ccode"
                                        value={editForm.ccode || ''}
                                        onChange={handleEditFormChange}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>SKU</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="SKU"
                                        type="text"
                                        name="sku"
                                        value={editForm.sku || ''}
                                        onChange={handleEditFormChange}
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Item No</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Item No"
                                        type="text"
                                        name="item_no"
                                        value={editForm.item_no || ''}
                                        onChange={handleEditFormChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Second Item No</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Second Item No"
                                        type="text"
                                        name="second_item_no"
                                        value={editForm.second_item_no || ''}
                                        onChange={handleEditFormChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Packing</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Packing"
                                        type="text"
                                        name="packing"
                                        value={editForm.packing || ''}
                                        onChange={handleEditFormChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>UOM</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="UOM"
                                        type="text"
                                        name="uom"
                                        value={editForm.uom || ''}
                                        onChange={handleEditFormChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section>
                                <div className="space-y-1">
                                    <Subheading>Min Quantity</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Min Quantity"
                                        type="text"
                                        name="minimum_quantity"
                                        value={editForm.minimum_quantity || ''}
                                        onChange={handleEditFormChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                    <DialogActions>
                        <Button plain onClick={() => setIsOpen(false)}>
                            Cancel
                        </Button>
                        <Button onClick={handleEditSubmit}>Save</Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Confirmation Dialog */}
                <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                    <DialogTitle>Delete Product Item</DialogTitle>
                    <DialogDescription>
                        Are you sure you want to delete this product item? This action cannot be undone.
                    </DialogDescription>
                    <DialogActions>
                        <Button plain onClick={() => setIsDeleteDialogOpen(false)} disabled={isDeleting}>
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} disabled={isDeleting}>
                            {isDeleting ? <Loading /> : 'Delete'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default ViewWarehouseProductIdPage;
