import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend) // Load translations using http backend
    .use(LanguageDetector) // Detects user language
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        backend: {
            loadPath: '/languages/{{lng}}.json', // Path to language files
        },
        fallbackLng: 'cn', // Fallback language
        interpolation: {
            escapeValue: false, // React already escapes by default
        },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie'], // Caching to remember user language preference
        },
    });

export default i18n;
