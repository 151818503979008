import { useState, useEffect } from 'react';
import { StockInItem } from '../models/StockInItem';
import StockInItemService from '../services/stockinItemService';

export const useStockInItems = () => {
    const [stockInItems, setStockInItems] = useState<StockInItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Fetch all stock in items
    const fetchStockInItems = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await StockInItemService.getAllStockInItems();
            setStockInItems(data);
        } catch (err: any) {
            setError(err.message || 'Error fetching stock in items');
        } finally {
            setLoading(false);
        }
    };

    // Fetch a stock in item by ID
    const fetchStockInItemById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const item = await StockInItemService.getStockInItemById(id);
            return item;
        } catch (err: any) {
            setError(err.message || `Error fetching stock in item with ID: ${id}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Fetch a stock in item by ID
    const fetchStockInItemByStockInId = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const item = await StockInItemService.getStockInItemByStockInId(id);
            return item;
        } catch (err: any) {
            setError(err.message || `Error fetching stock in item with stock in ID: ${id}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Create a new stock in item
    const createStockInItem = async (data: Omit<StockInItem, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const newItemRes = await StockInItemService.createStockInItem(data);
            return newItemRes;
        } catch (err: any) {
            setError(err.message || 'Error creating stock in item');
            return null;
        } finally {
            setLoading(false);
        }
    };

    const createMultipleStockInItems = async (items: Omit<StockInItem, 'id' | 'created_time' | 'updated_time'>[]) => {
        setLoading(true);
        setError(null);
        const createdItems: StockInItem[] = [];
        const dbSuccesses: boolean[] = [];

        try {
            // Loop through the items array and create each item
            for (const item of items) {
                const newItemRes = await StockInItemService.createStockInItem(item);
                createdItems.push(newItemRes.data);
                dbSuccesses.push(newItemRes.dbSuccess); // Add each success/failure to dbSuccesses
            }

            if (dbSuccesses.includes(false)) {
                return { dbsuccess: false }
            } else {
                return { dbsuccess: true }
            }

        } catch (err: any) {
            setError(err.message || 'Error creating multiple stock-in items');
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Update an existing stock in item
    const updateStockInItem = async (id: number, data: Omit<StockInItem, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const updatedItemRes = await StockInItemService.updateStockInItem(id, data);
            return updatedItemRes;
        } catch (err: any) {
            setError(err.message || `Error updating stock in item with ID: ${id}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Delete a stock in item
    const deleteStockInItem = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            await StockInItemService.deleteStockInItem(id);
            setStockInItems(prevItems => prevItems.filter(item => item.id !== BigInt(id)));
        } catch (err: any) {
            setError(err.message || `Error deleting stock in item with ID: ${id}`);
        } finally {
            setLoading(false);
        }
    };

    // Fetch stock in items on mount
    useEffect(() => {
        fetchStockInItems();
    }, []);

    return {
        stockInItems,
        loading,
        error,
        fetchStockInItems,
        fetchStockInItemById,
        fetchStockInItemByStockInId,
        createStockInItem,
        createMultipleStockInItems,
        updateStockInItem,
        deleteStockInItem,
    };
};
