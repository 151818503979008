// hooks/useWarehouses.tsx

import { useState, useEffect, useCallback, useContext } from 'react';
import { Warehouse } from '../models/Warehouse';
import warehouseService from '../services/warehouseService';
import { UserContext } from '../context/userContext';

export const useWarehouses = () => {
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse | null>(null);
    const [totalWarehouse, setTotalWarehouse] = useState<number>(0)
    const { currentUser } = useContext(UserContext);

    const fetchWarehouses = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await warehouseService.getAllWarehouses();
            setWarehouses(data);
            setTotalWarehouse(data.length);
        } catch (err) {
            setError('Failed to load warehouses');
            throw err;
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchWarehouseById = async (id: Number) => {
        setLoading(true);
        setError(null);
        try {
            const warehouse = await warehouseService.getWarehouseById(id);
            setSelectedWarehouse(warehouse);
            return warehouse;
        } catch (err) {
            setError(`Failed to load warehouse with ID ${id}`);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const addWarehouse = async (warehouseData: Omit<Warehouse, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        try {
            const newWarehouse = await warehouseService.createWarehouse(warehouseData);
            setWarehouses((prev) => [...prev, newWarehouse]);
        } catch (err) {
            setError('Failed to add warehouse');
        } finally {
            setLoading(false);
        }
    };

    const updateWarehouse = async (id: Number, warehouseData: Omit<Warehouse, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        try {
            const updatedWarehouse = await warehouseService.updateWarehouse(id, warehouseData);
            setWarehouses((prev) =>
                prev.map((warehouse) => (warehouse.id === BigInt(id.toString()) ? updatedWarehouse : warehouse))
            );
        } catch (err) {
            setError('Failed to update warehouse');
        } finally {
            setLoading(false);
        }
    };

    const deleteWarehouse = async (id: Number) => {
        setLoading(true);
        try {
            await warehouseService.deleteWarehouse(id);
            setWarehouses((prev) => prev.filter((warehouse) => warehouse.id !== BigInt(id!.toString())));
        } catch (err) {
            setError('Failed to delete warehouse');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchWarehouses();
    }, [fetchWarehouses]);

    return {
        warehouses,
        totalWarehouse,
        loading,
        error,
        selectedWarehouse,
        fetchWarehouses,
        fetchWarehouseById,
        addWarehouse,
        updateWarehouse,
        deleteWarehouse,
    };
};
