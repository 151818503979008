import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from '../../../../models/User';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Select } from '../../../../components/Select';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { useRoles } from '../../../../hooks/useRoles';
import Loading from '../../../../components/Loading';
import { useSetupBrand } from '../../../../hooks/useSetupBrand';
import { PhotoIcon } from '@heroicons/react/16/solid';
import { useStockOut } from '../../../../hooks/useStockOut';
import { StockOutItem } from '../../../../models/StockOutItem';
import { StockOut } from '../../../../models/StockOut';
import { useStockOutItems } from '../../../../hooks/useStockOutItem';
import { useTrackingDelivery } from '../../../../hooks/useTrackingDelivery';

interface AddTrackingAddProven {
    stock_out_id: Number | null;
    proven_image: Blob | null;
    driver_name: string | null;
    recipient_name: string | null;
}

const TrackingDeliveryConfirmationPage = () => {
    const id = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [fileNames, setFileNames] = useState<{ [key: string]: string }>({});
    const [stockOut, setStockOut] = useState<StockOut | null>(null);
    const { fetchStockOutById, loading, error } = useStockOut();
    const [stockOutItems, setStockOutItems] = useState<StockOutItem[]>([]);
    const { fetchStockOutItemByStockOutId } = useStockOutItems();
    const { createTrackingDelivery } = useTrackingDelivery();

    useEffect(() => {
        document.title = "Stock Out Delivery Confirmation";
    }, []);

    const [confirmation, setConfirmation] = useState<AddTrackingAddProven>({
        stock_out_id: Number(id),
        proven_image: null,
        driver_name: '',
        recipient_name: '',
    });

    const [images, setImages] = useState({
        proven_image: null,
    })


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setConfirmation(prev => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        const file = e.target.files![0];
        if (file) {
            setFileNames((prevNames) => ({
                ...prevNames,
                [inputName]: file.name,
            }));
        }
        const { name, files } = e.target;
        if (files && files[0]) {
            setConfirmation((prevData) => ({
                ...prevData,
                [name]: files[0],
            }));
            setImages((prev) => ({ ...prev, [name]: file }));
        }
    };

    useEffect(() => {
        const fetchStockOutDetails = async () => {
            if (id) {
                try {
                    const fetchedStockOut = await fetchStockOutById(Number(id.id!));
                    setStockOut(fetchedStockOut);
                    const fetchedStockOutItems = await fetchStockOutItemByStockOutId(Number(id));
                    setStockOutItems(fetchedStockOutItems);
                } catch (err) {
                    showErrorToast('Failed to fetch stock out details.');
                }

                setConfirmation((prevData) => ({
                    ...prevData,
                    stock_out_id: Number(id.id!),
                }));
            }; // Ensure ID is valid before making the request

        };
        fetchStockOutDetails();
    }, [id]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!confirmation.driver_name || !confirmation.recipient_name || !confirmation.proven_image) {
            showErrorToast("Please fill in all fields");
            return;
        } else {
            try {
                showLoadingToast(`Comfirming ${stockOut?.reference_no} ...`, 100);
                const result = await createTrackingDelivery(confirmation, images);
                if (result.dbsuccess) {
                    setTimeout(() => {
                        navigate('/gp/tracking/delivery/success')
                        showSuccessToast(`${stockOut?.reference_no} confirmed successfully`);
                    }, 1000);
                }
            } catch (err) {
                showErrorToast(`Failed to add user: ${err}`);
            }
        }

    };

    return (
        <form method="post" onSubmit={handleSubmit} className="py-12 mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Delivery Confirmation</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="submit" className='ml-3'>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Delivery Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            This information will be displayed publicly so be careful what you share.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <Subheading>Stock Out Reference Number</Subheading>
                            <label className='mt-2'>{stockOut?.reference_no || '-'}</label>
                        </div>

                        <div className="sm:col-span-full">
                            <Subheading>Stock Out Invoice Number</Subheading>
                            <label className='mt-2'>{stockOut?.invoice_no || '-'}</label>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Rider / Driver Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Driver Name"
                                        name="driver_name"
                                        value={confirmation.driver_name!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className=''>
                                <div className="col-span-full">
                                    <Subheading>Upload Delivery Proven Images</Subheading>
                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                        <div className="text-center">
                                            <PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
                                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="proven_image"
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                >
                                                    <span>Upload a file</span>
                                                    <input
                                                        id="proven_image"
                                                        name="proven_image"
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                        multiple
                                                        onChange={(e) => handleFileChange(e, 'proven_image')}
                                                        className="sr-only"
                                                    />
                                                </label>
                                                <p className="pl-1">of parcel reached destination</p>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                    </div>
                                    {fileNames['proven_image'] && (
                                        <p className="mt-2 text-sm text-gray-700">
                                            Selected file: <strong>{fileNames.proven_image}</strong>
                                        </p>
                                    )}
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Recipient Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Recipient Name"
                                        name="recipient_name"
                                        value={confirmation.recipient_name!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default TrackingDeliveryConfirmationPage;
