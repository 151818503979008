// hooks/useSetupBrand.tsx
import { useState, useEffect } from 'react';
import { SetupBrand } from '../models/SetupBrand';
import { SetupBrandService } from '../services/setupBrandService';

export const useSetupBrand = () => {
    const [brands, setBrands] = useState<SetupBrand[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [totalBrands, setTotalBrands] = useState<number>(0); // Total number of brands

    const fetchBrands = async () => {
        setLoading(true);
        setError(null);
        try {
            const brandsData = await SetupBrandService.getAllBrands();
            setBrands(brandsData);
        } catch (err) {
            setError((err as Error).message);
        } finally {
            setLoading(false);
        }
    };

    const getAllBrands = async () => {
        setLoading(true);
        setError(null);
        try {
            const brandsData = await SetupBrandService.getAllBrands();
            return brandsData;
        } catch (err) {
            setError((err as Error).message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const fetchBrandById = async (id: number) => {
        try {
            setLoading(true);
            const brandData = await SetupBrandService.getBrandById(id);
            return brandData;
        } catch (err) {
            setError('Failed to load this user group');
            throw err;
        } finally {
            setLoading(false);
        }
    }

    const createBrand = async (newBrand: Omit<SetupBrand, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const createdBrand = await SetupBrandService.createBrand(newBrand);
            setBrands((prevBrands) => [...prevBrands, createdBrand]);
        } catch (err) {
            setError((err as Error).message);
        } finally {
            setLoading(false);
        }
    };

    const updateBrand = async (id: number, updatedBrand: Omit<SetupBrand, 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const newBrandRes = await SetupBrandService.updateBrand(id, updatedBrand);
            return newBrandRes;
        } catch (err) {
            setError((err as Error).message);
        } finally {
            setLoading(false);
        }
    };

    const deleteBrand = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const updatedBrandRes = await SetupBrandService.deleteBrand(id);
            return updatedBrandRes;
        } catch (err) {
            setError((err as Error).message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBrands();
    }, []);

    return {
        brands,
        loading,
        error,
        fetchBrands,
        fetchBrandById,
        createBrand,
        updateBrand,
        deleteBrand,
        totalBrands,
        getAllBrands,
    };
};
