import React, { useContext, useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate } from 'react-router-dom'; // useParams is not needed
import { Heading, Subheading } from '../../../components/Heading';
import { Badge } from '../../../components/Badge';
import { Button } from '../../../components/Button';
import { Divider } from '../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../components/Description-List';
import { Avatar } from '../../../components/Avatar';
import { UserService } from '../../../services/userService'; // Import the UserService
import Loading from '../../../components/Loading';
import dateFormat from 'dateformat';
import { User } from '../../../models/User';
import { UserContext } from '../../../context/userContext';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../components/Dialog';
import { Input } from '../../../components/Input';
import showSuccessToast from '../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../components/Toasts/ShowErrorToast';

const ViewMyAccountPage: React.FC = () => {
    const { currentUser } = useContext(UserContext);
    const [user, setUser] = useState<User | null>(null); // State to hold the user details
    const [loading, setLoading] = useState<boolean>(true); // State to handle loading status
    const [error, setError] = useState<string | null>(null); // State to handle errors
    const [isOpen, setIsOpen] = useState(false); // State for dialog open/close
    const [currentPassword, setCurrentPassword] = useState(''); // State for current password
    const [newPassword, setNewPassword] = useState(''); // State for new password
    const [confirmPassword, setConfirmPassword] = useState(''); // State for confirm password
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "My Account";
    }, []);

    useEffect(() => {
        if (!currentUser) return; // If id is not available, return early

        // Fetch user details when the component mounts
        const fetchUser = async () => {
            try {
                setLoading(true);
                const userData = await UserService.getUserById(currentUser.id!.toString());
                setUser(userData);
            } catch (err) {
                console.error('Failed to fetch user details:', err);
                setError('Failed to load user details. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [currentUser?.id]); // Ensure to add optional chaining for safety

    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
            setError('New password and confirmation do not match.');
            return;
        }

        try {
            const res = await UserService.resetPassword(Number(currentUser!.id), currentPassword, newPassword);
            if (res.dbsuccess) {
                showSuccessToast("Password successfully reset");
            } else {
                showErrorToast(`Error resetting password: ${res.message}`);
            }
            setIsOpen(false); // Close the dialog on success
            setError(null); // Reset error
            resetField();
        } catch (err) {
            console.error('Error resetting password:', err);
            setError('Failed to reset password. Please try again later.');
            showErrorToast('Error resetting password:' + err);
        }
    };

    const resetField = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!user) return <p className='py-16 text-center'>User not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/dashboard" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Dashboard
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={user.fullname[0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{user.fullname}</Heading>
                    <Badge color="lime">{user.rolename.toUpperCase()}</Badge>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span className='pr-1 text-zinc-400'>Created on</span><span>{dateFormat(user.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button outline onClick={() => setIsOpen(true)}>Reset Password</Button>
                        <Button onClick={() => navigate(`/gp/myaccount/edit`)}>Edit</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>User Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Name</DescriptionTerm>
                    <DescriptionDetails>{user.fullname}</DescriptionDetails>
                    <DescriptionTerm>Email</DescriptionTerm>
                    <DescriptionDetails>{user.email || '-'}</DescriptionDetails>
                    <DescriptionTerm>Phone</DescriptionTerm>
                    <DescriptionDetails>{user.mobile_no || '-'}</DescriptionDetails>
                    <DescriptionTerm>Address</DescriptionTerm>
                    <DescriptionDetails>{user.street_address || '-'}</DescriptionDetails>
                    <DescriptionTerm>Remarks</DescriptionTerm>
                    <DescriptionDetails>{user.remarks || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>
            <div className="mt-12">
                <Subheading>Account Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Username</DescriptionTerm>
                    <DescriptionDetails>{user.username}</DescriptionDetails>
                    <DescriptionTerm>Role</DescriptionTerm>
                    <DescriptionDetails className='font-medium'>{user.rolename}</DescriptionDetails>
                    <DescriptionTerm>Warehouse</DescriptionTerm>
                    <DescriptionDetails className='font-medium'>{user.warehouse_name!}</DescriptionDetails>
                </DescriptionList>
            </div>

            {/* Dialog for Reset Password */}

            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Reset Password</DialogTitle>
                <DialogDescription>
                    Reset your password by filling current password and new password.
                </DialogDescription>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Current Password</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    type="password"
                                    name="currentPassword"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>New password</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    type="password"
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Confirm New Password</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    type="password"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                        </section>
                    </div>
                </div>
                <DialogActions>
                    <Button plain onClick={() => { setIsOpen(false); resetField(); }}>
                        Cancel
                    </Button>
                    <Button onClick={handleResetPassword} disabled={currentPassword == '' || newPassword == '' || confirmPassword == ''}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ViewMyAccountPage;
