import React, { useContext, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon, EyeIcon, MagnifyingGlassIcon, PencilIcon, PrinterIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from '../../../components/Dropdown';
import { Link } from '../../../components/Link';
import { Button } from '../../../components/Button';
import { Heading, Subheading } from '../../../components/Heading';
import { Input, InputGroup } from '../../../components/Input';
import Loading from '../../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import dateFormat from 'dateformat';
import { UserContext } from '../../../context/userContext';
import { WarehouseProduct } from '../../../models/WarehouseProduct';
import { useWarehouseProductItem } from '../../../hooks/useWarehouseProductItem';
import { WarehouseProductItem } from '../../../models/WarehouseProductItem';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import useWarehouseProductItemMovement from '../../../hooks/useWarehouseProductItemMovement';
import { WarehouseProductItemMovement } from '../../../models/WarehouseProductItemMovement';
import useWarehouseProductItemBatchNoMovement from '../../../hooks/useWarehouseProductItemBatchNoMovement';
import { WarehouseProductItemBatchNoMovement } from '../../../models/WarehouseProductItemBatchNoMovement';
import { useSetupUsergroup } from '../../../hooks/useSetupUsergroup';
import showErrorToast from '../../../components/Toasts/ShowErrorToast';
import { Text } from '../../../components/Text';

const ReportStockByBatchPage: React.FC = () => {
    const { jsPDF } = require('jspdf');
    require('jspdf-autotable');
    const { currentUser } = useContext(UserContext); // Get current user info
    const {
        loading,
        error,
        batchNoMovements
    } = useWarehouseProductItemBatchNoMovement();
    const { suppliers } = useSetupUsergroup();
    const [aggregatedMovementList, setAggregatedMovementList] = useState<WarehouseProductItemBatchNoMovement[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>(''); // State to store the search query
    const [filterStartDate, setFilterStartDate] = useState<string>('');
    const [filterEndDate, setFilterEndDate] = useState<string>('');
    const [filterSupplierId, setFilterSupplierId] = useState<string>('');
    const [filterYear, setFilterYear] = useState<string>('');
    const navigate = useNavigate();
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    useEffect(() => {
        document.title = "Stock By Batch Report";
    }, []);

    const aggregateMovements = (movements: WarehouseProductItemBatchNoMovement[]): WarehouseProductItemBatchNoMovement[] => {
        const aggregated: Record<string, WarehouseProductItemBatchNoMovement> = {};

        movements.forEach(movement => {
            const key = movement.batch_no; // Using batch_no as the unique key

            // Ensure key is valid (not null or undefined)
            if (key) { // Only proceed if key is a valid string
                if (!aggregated[key]) {
                    // Initialize the entry if it doesn't exist
                    aggregated[key] = { ...movement };
                } else {
                    // Aggregate the quantities
                    aggregated[key].total_quantity_in = (parseFloat(aggregated[key].total_quantity_in || '0') + parseFloat(movement.total_quantity_in || '0')).toString();
                    aggregated[key].total_quantity_out = (parseFloat(aggregated[key].total_quantity_out || '0') + parseFloat(movement.total_quantity_out || '0')).toString();
                    aggregated[key].total_quantity_adjust = (parseFloat(aggregated[key].total_quantity_adjust || '0') + parseFloat(movement.total_quantity_adjust || '0')).toString();
                }
            }
        });

        // Return the values as an array
        return Object.values(aggregated);
    };

    // Aggregate the movements when productItemMovementList changes
    useEffect(() => {
        const aggregated = aggregateMovements(batchNoMovements);
        setAggregatedMovementList(aggregated);
    }, [batchNoMovements]);

    // Filter logic
    const filteredProductItemMovement = Array.isArray(aggregatedMovementList)
        ? aggregatedMovementList?.filter((productItemMovement) => {
            const itemDate = new Date(productItemMovement.created_time!);

            return (
                (productItemMovement.item_no?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    productItemMovement.second_item_no?.toLowerCase().includes(searchQuery.toLowerCase())) &&
                (!filterStartDate || itemDate >= new Date(filterStartDate)) &&
                (!filterEndDate || itemDate <= new Date(filterEndDate)) &&
                (!filterSupplierId || productItemMovement.supplier_id === Number(filterSupplierId)) &&
                (!filterYear || itemDate.getFullYear().toString() === filterYear)
            );
        }) : [];

    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Function to generate PDF
    const generatePDF = () => {
        const today = formatDate(new Date());

        if (!filteredProductItemMovement) {
            showErrorToast("PDF generation failed. Product item is not available.");
            return; // Exit the function early if productItem is undefined
        }

        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.text(`Stock By Batch Report | ${today}`, 14, 10);

        doc.setFontSize(12);
        doc.autoTable({
            head: [['S/N', 'Batch No', 'Item No', 'Second Item No', 'Description', 'Total Quantity In', 'Total Quantity Out', 'Total Quantity Adjust']],
            body: filteredProductItemMovement.map((item, index) => [
                index + 1,
                item.batch_no || "N/A",
                item.item_no || "N/A",
                item.second_item_no || "N/A",
                item.description || "N/A",
                item.total_quantity_in || 0,
                item.total_quantity_out || 0,
                item.total_quantity_adjust || 0
            ])
        });

        doc.save(`stock_by_batch_report_${today}.pdf`);
    };

    // Function to generate CSV
    const generateCSV = () => {
        if (!filteredProductItemMovement) {
            showErrorToast("CSV generation failed. Product item is not available.");
            return; // Exit the function early if productItem is undefined
        }

        const csvData = [
            ['S/N', 'Batch No', 'Item No', 'Second Item No', 'Description', 'Total Quantity In', 'Total Quantity Out', 'Total Quantity Adjust'],
            ...filteredProductItemMovement.map((item, index) => [
                index + 1,
                item.batch_no || "N/A",
                item.item_no || "N/A",
                item.second_item_no || "N/A",
                item.description || "N/A",
                item.total_quantity_in || 0,
                item.total_quantity_out || 0,
                item.total_quantity_adjust || 0
            ])
        ];

        const csvContent = 'data:text/csv;charset=utf-8,' + csvData.map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        const today = formatDate(new Date());
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `stock_by_batch_report_${today}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Function to generate Excel
    const generateExcel = () => {
        if (!filteredProductItemMovement) {
            showErrorToast("Excel generation failed. Product item is not available.");
            return; // Exit the function early if productItem is undefined
        }

        const worksheet = XLSX.utils.json_to_sheet(filteredProductItemMovement.map((item, index) => ({
            SNo: index + 1,
            BatchNo: item.batch_no || "N/A",
            ItemNo: item.item_no || "N/A",
            SecondItemNo: item.second_item_no || "N/A",
            Description: item.description || "N/A",
            TotalQuantityIn: item.total_quantity_in || 0,
            TotalQuantityOut: item.total_quantity_out || 0,
            TotalQuantityAdjust: item.total_quantity_adjust || 0,
        })));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Stock By Batch");

        const today = formatDate(new Date());
        XLSX.writeFile(workbook, `stock_movement_report_${today}.xlsx`);
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Stock By Batch</Heading>
                    <div className="mt-4 flex gap-4 justify-between">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder="Search stock by batch&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Dropdown>
                    <DropdownButton aria-label="More options">
                        Print Report
                    </DropdownButton>
                    <DropdownMenu anchor="bottom end">
                        <DropdownItem onClick={generatePDF}>PDF</DropdownItem>
                        <DropdownItem onClick={generateCSV}>CSV</DropdownItem>
                        <DropdownItem onClick={generateExcel}>Excel</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>

            {/* Date, Year, and Warehouse Filters */}
            <div className="mt-4 px-6 py-4 bg-zinc-50 ring-1 ring-zinc-200 rounded-md">
                <div className='flex flex-wrap items-end justify-between gap-4'>
                    <Subheading>Filters & Sorting</Subheading>
                    {(isFilterOpen) ? <ChevronUpIcon onClick={() => setIsFilterOpen(false)} className='w-5 h-5' /> : <ChevronDownIcon onClick={() => setIsFilterOpen(true)} className='w-5 h-5' />}
                </div>
                {(isFilterOpen) && <div className='mt-4 grid gap-4 sm:grid-cols-1'>
                    <div>
                        <Text>From Date</Text>
                        <Input
                            type="date"
                            value={filterStartDate}
                            onChange={(e) => setFilterStartDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>To Date</Text>
                        <Input
                            type="date"
                            value={filterEndDate}
                            onChange={(e) => setFilterEndDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>By Year</Text>
                        <Input
                            type="number"
                            placeholder="Year"
                            value={filterYear}
                            onChange={(e) => setFilterYear(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button onClick={() => {
                            setFilterStartDate('');
                            setFilterEndDate('');
                            setFilterYear('');
                            setFilterSupplierId('');
                        }}>
                            Reset Filter
                        </Button>
                    </div>
                    {(currentUser?.role.toString() !== "5") && (
                        <div className='max-w-full'>
                            <Text>By Supplier</Text>
                            <Dropdown>
                                <DropdownButton outline>
                                    {filterSupplierId ? suppliers?.find(w => w.id?.toString() === filterSupplierId)?.fullname : 'All Suppliers'}
                                    <ChevronDownIcon />
                                </DropdownButton>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setFilterSupplierId('')}>All Suppliers</DropdownItem>
                                    {suppliers?.map((supplier) => (
                                        <DropdownItem key={supplier.id} onClick={() => setFilterSupplierId(supplier.id?.toString()!)}>
                                            {supplier.fullname}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    )}
                </div>}
            </div>

            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Batch No</TableHeader>
                                    <TableHeader>Item No</TableHeader>
                                    <TableHeader>Second Item No</TableHeader>
                                    <TableHeader>Description</TableHeader>
                                    <TableHeader>Total Quantity In</TableHeader>
                                    <TableHeader>Total Quantity Out</TableHeader>
                                    <TableHeader>Total Quantity Adjust</TableHeader>
                                    <TableHeader>Supplier</TableHeader>
                                    <TableHeader>Movement Date</TableHeader>
                                    <TableHeader>Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(filteredProductItemMovement.length > 0) ? filteredProductItemMovement.map((item, index) => (
                                    <TableRow key={index} title={`${index}`}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell className="text-zinc-500">{item.batch_no}</TableCell>
                                        <TableCell className="text-zinc-500">{item.item_no}</TableCell>
                                        <TableCell className="text-zinc-500">{item.second_item_no}</TableCell>
                                        <TableCell className="text-zinc-500">{item.description}</TableCell>
                                        <TableCell className="text-zinc-400">{item.total_quantity_in || '0'}</TableCell>
                                        <TableCell className="text-zinc-400">{item.total_quantity_out || '0'}</TableCell>
                                        <TableCell className="text-zinc-400">{item.total_quantity_adjust || '0'}</TableCell>
                                        <TableCell className="text-zinc-400">{item.supplier_fullname}</TableCell>
                                        <TableCell className="text-zinc-400">{dateFormat(item.created_time?.toString(), "mmm dd, yyyy")}</TableCell>
                                        <TableCell className="py-5 flex gap-4">
                                            <a className="text-zinc-600 hover:text-zinc-900" onClick={() => navigate(`/gp/reports/stockbybatch/view/${item.id}`)}>
                                                <EyeIcon className='h-5 w-5' />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={9} className="text-center py-8">No stock by batch found.</TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>
        </>
    );
};

export default ReportStockByBatchPage;
