// frontend/src/pages/LoginPage.tsx
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserService } from '../../services/userService';
import { UserContext } from '../../context/userContext';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/16/solid';
import { Avatar } from '../../components/Avatar';
import Loading from '../../components/Loading';

const LoginPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { setCurrentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setLoading(true);
            // Add a delay of 1 second before navigating to "/home"
            setTimeout(async () => {
                setLoading(false);
                const loginSuccess = await UserService.login(username, password);

                if (loginSuccess) {
                    // Fetch the profile to set the current user in context
                    const userProfile = await UserService.getProfile();
                    setCurrentUser(userProfile);
                    navigate('/gp/dashboard');
                }
            }, 1000);

        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const trimmedValue = value.trim();
        const sanitizedValue = trimmedValue.toLowerCase().replace(/\s/g, '');
        const sanitizedValueWithoutSpecialChars = sanitizedValue.replace(/[^\w]/g, '');
        setUsername(sanitizedValueWithoutSpecialChars);
    };

    useEffect(() => {
        document.title = "Login";
    }, []);

    return (
        <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="my-10 sm:mx-auto sm:w-full sm:max-w-sm grid justify-items-center gap-6">
                <Avatar src="/images/GPCLogo.png" className="bg-white text-gray-700 w-48" />
                <h3 className="bg-gradient-to-r from-green-700 via-emerald-900 to-zinc-900 text-transparent bg-clip-text text-center font-extrabold text-4xl">Genuine Partnership Corporation<br></br></h3 >
                <h3 className="bg-gradient-to-r from-zinc-700 via-green-700 to-zinc-700 text-transparent bg-clip-text text-center font-extrabold text-sm">Your Healthcare Supply Chain Partner<br></br></h3 >

            </div>
            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                            Username
                        </label>
                        <div className="mt-2">
                            <input
                                id="text"
                                name="username"
                                type="username"
                                autoComplete="username"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6 px-2"
                                onChange={handleUsernameChange} value={username}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Password
                            </label>

                        </div>
                        <div className="mt-2">
                            <div className="relative">
                                <input
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="password"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-600 sm:text-sm sm:leading-6 px-2"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-500 cursor-pointer focus:outline-none"
                                >
                                    {showPassword ? (
                                        <EyeSlashIcon className="h-4 w-4 text-grey-300" />
                                    ) : (
                                        <EyeIcon className="h-4 w-4 text-grey-500" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        {/* <div className="flex w-full justify-center rounded-md bg-zinc-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600">
                                <MoonLoader color="#FFFFFF" size={14} />
                            </div> */}
                        {(!loading) ? <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-zinc-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
                        >
                            Login
                        </button>

                            : <button type="button" className="flex w-full items-center justify-center rounded-md bg-zinc-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600" disabled>
                                <Loading />
                                <p className='ml-2'>Logging in...</p>
                            </button>}
                    </div>
                </form>
            </div>

            {/* <div className="mt-8 text-sm text-center">
                <a href="/forgotPassword" className="font-semibold text-zinc-600 hover:text-zinc-500">
                    Forgot password?
                </a>
            </div> */}

            <p className="mt-32 text-[12px] leading-6 text-gray-500 dark:text-gray-500 text-center text-bold">
                ©Global Pharma Management System by Kingdom Codeworks Malaysia. All rights reserved.
            </p>
        </div>

    );
};

export default LoginPage;
