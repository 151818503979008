import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Select } from '../../../../components/Select';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import Loading from '../../../../components/Loading';
import { useWarehouses } from '../../../../hooks/useWarehouse';
import { useWarehouseProductDepartment } from '../../../../hooks/useWarehouseProductDepartment';

interface AddWarehouseProductDepartment {
    department_name: string;
}

const AddWarehouseProductDepartmentPage = () => {
    const navigate = useNavigate();

    const [department, setDepartment] = useState<AddWarehouseProductDepartment>({
        department_name: '',
    });
    const { createDepartment } = useWarehouseProductDepartment();

    useEffect(() => {
        document.title = "Add Product Department";
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setDepartment(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!department.department_name) {
            showErrorToast("Please fill in all fields before submit.");
            return;
        }

        try {
            const res = await createDepartment(department);
            if (res.dbsuccess) {
                showLoadingToast(`Creating ${department.department_name} ...`, 100);
                setTimeout(() => {
                    navigate('/gp/inventories/departments');
                    showSuccessToast(`${department.department_name} added successfully`);
                }, 1000);

            } else {
                showErrorToast(`Failed creating department data`);
            }
        } catch (err) {
            showErrorToast(`Failed to add department: ${err}`);
        }
    };

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Product Department</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className='ml-3' disabled={!department.department_name}>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Product Department Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            A specific organizational unit within a company responsible for managing product.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Department Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Name"
                                        name="department_name"
                                        value={department.department_name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button type="submit" disabled={!department.department_name}>Save</Button>
            </div>
        </form>
    );
};

export default AddWarehouseProductDepartmentPage;
