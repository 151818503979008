import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from '../../../../models/User';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Select } from '../../../../components/Select';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { useRoles } from '../../../../hooks/useRoles';
import Loading from '../../../../components/Loading';
import { useStockOut } from '../../../../hooks/useStockOut';
import { useSetupUsergroup } from '../../../../hooks/useSetupUsergroup';
import useUserService from '../../../../hooks/useUserService';
import { UserContext } from '../../../../context/userContext';
import { useWarehouses } from '../../../../hooks/useWarehouse';
import { StockOut } from '../../../../models/StockOut';

// Utility function to format date to yyyy-MM-dd based on local time
const formatDate = (isoString: string): string => {
    const date = new Date(isoString);

    // Get the year, month, and day using local time
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day

    return `${year}-${month}-${day}`; // Format as yyyy-MM-dd
};

const EditStockOutPage = () => {
    const id = useParams<{ id: string }>()
    const navigate = useNavigate();
    const [stockOut, setStockOut] = useState<StockOut | null>(null);
    const { fetchStockOutById, updateStockOut, loading, error } = useStockOut();
    const { customers, logistics } = useSetupUsergroup();
    const { users } = useUserService();
    const { currentUser } = useContext(UserContext);
    const { warehouses } = useWarehouses();
    const filteredWarehouses = warehouses.filter(warehouse => warehouse.hq !== '1');

    useEffect(() => {
        document.title = "Edit Stock Out";
    }, []);

    useEffect(() => {
        const fetchedStockOutDetails = async () => {
            try {
                const fetchedStockOut = await fetchStockOutById(Number(id.id));
                if (fetchedStockOut!.date && fetchedStockOut!.issue_date) {
                    fetchedStockOut!.date = formatDate(fetchedStockOut!.date);
                    fetchedStockOut!.issue_date = formatDate(fetchedStockOut!.issue_date);
                }
                setStockOut(fetchedStockOut!);
            } catch (err) {
                showErrorToast(`Error fetching stock out ${id}: ${err}`);
            }
        }
        fetchedStockOutDetails();
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setStockOut(prev => prev ? ({ ...prev, [name]: value }) : null);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!stockOut) {
            return;
        }
        if (!stockOut.customer_id || !stockOut.date || !stockOut.invoice_no || !stockOut.issue_date || !stockOut.issue_time || !stockOut.issuer_id || !stockOut.logistic_id || !stockOut.reference_no || !stockOut.warehouse_id) {
            showErrorToast("Please fill in all field before submit");
            return;
        }

        try {
            const res = await updateStockOut(Number(id.id), stockOut!);
            showLoadingToast(`Updating ${stockOut!.reference_no} ...`, 100);
            if (res.dbsuccess) {
                setTimeout(() => {
                    navigate('/gp/stocks/stockOut');
                    showSuccessToast(`${stockOut!.reference_no} updated successfully`);
                }, 1000);
            } else {
                showErrorToast(`Failed updating stock-out data`);
            }
        } catch (err) {
            showErrorToast(`Failed updating stock-out data: ${err}`);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (!stockOut) return <p>Stock out data not found.</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Stock Out Data</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className='ml-3' disabled={(!stockOut.customer_id || !stockOut.date || !stockOut.invoice_no || !stockOut.issue_date || !stockOut.issue_time || !stockOut.issuer_id || !stockOut.logistic_id || !stockOut.reference_no || !stockOut.warehouse_id)}>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Stock Out Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Information on outgoing inventory from the warehouse.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Stock-out Date</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type='date'
                                        aria-label="Date"
                                        name="date"
                                        value={stockOut.date!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Warehouse</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Warehouse"
                                        name="warehouse_id"
                                        value={stockOut.warehouse_id}
                                        onChange={handleChange}
                                    >
                                        {filteredWarehouses.map(warehouse => (
                                            <option key={warehouse.id.toString()} value={warehouse.id.toString()}>
                                                {warehouse.warehouse_name}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Reference No</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Reference No"
                                        name="reference_no"
                                        value={stockOut.reference_no!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Customer</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Customer"
                                        name="customer_id"
                                        value={stockOut.customer_id!.toString()}
                                        onChange={handleChange}
                                    >
                                        {customers.map(customer => (
                                            <option key={customer.id} value={customer.id!.toString()}>
                                                {customer.fullname}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Invoice Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Invoice Number"
                                        name="invoice_no"
                                        value={stockOut.invoice_no!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Logistic</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Logistic"
                                        name="logistic_id"
                                        value={stockOut.logistic_id!.toString()}
                                        onChange={handleChange}
                                    >
                                        {logistics.map(logistic => (
                                            <option key={logistic.id} value={logistic.id!.toString()}>
                                                {logistic.fullname}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Issue Date</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type='date'
                                        aria-label="Issue Date"
                                        name="issue_date"
                                        value={stockOut.issue_date!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Issue Time</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type='time'
                                        aria-label="Issue Time"
                                        name="issue_time"
                                        value={stockOut.issue_time!}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Issued by</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Issuer Id"
                                        name="issuer_id"
                                        value={stockOut.issuer_id!.toString()}
                                        onChange={handleChange}
                                    >
                                        {users.map(user => (
                                            <option key={user.id} value={user.id!.toString()}>
                                                {user.fullname}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-4 my-6">
                    <Button type="reset" plain onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" disabled={(!stockOut.customer_id || !stockOut.date || !stockOut.invoice_no || !stockOut.issue_date || !stockOut.issue_time || !stockOut.issuer_id || !stockOut.logistic_id || !stockOut.reference_no || !stockOut.warehouse_id)}>Save</Button>
                </div>
            </div>
        </form>
    );
};

export default EditStockOutPage;
