// hooks/useWarehouseProductItem.tsx
import { useState, useEffect, useContext } from 'react';
import { WarehouseProductItemService } from '../services/warehouseProductItemService';
import { WarehouseProductItem } from '../models/WarehouseProductItem';
import { UserContext } from '../context/userContext';

// Hook to manage state and API interactions for WarehouseProductItems
export const useWarehouseProductItem = () => {
    const [productItems, setProductItems] = useState<WarehouseProductItem[]>([]);
    const [productItemsStockRemind, setProductItemsStockRemind] = useState<WarehouseProductItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { currentUser } = useContext(UserContext);

    const fetchFilteredProductItems = async () => {
        setLoading(true);
        setError(null);
        try {
            if (currentUser?.warehouse_hq === "1") {
                const productItemData = await WarehouseProductItemService.getAllProductItems();
                setProductItems(productItemData);

            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1") {
                const productItemData = await WarehouseProductItemService.getAllProductItemsByWarehouseId(currentUser.warehouse_id.toString());
                setProductItems(productItemData);
            }
        } catch (err) {
            setError('Failed to fetch profuct items');
        } finally {
            setLoading(false);
        }
    }

    const fetchProductItemsStockRemind = async () => {
        setLoading(true);
        setError(null);
        try {
            if (currentUser?.warehouse_hq === "1") {
                const productItemData = await WarehouseProductItemService.getAllProductItemsStockRemind();
                setProductItemsStockRemind(productItemData);

            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1") {
                const productItemData = await WarehouseProductItemService.getAllProductItemsStockRemindByWarehouseId(currentUser.warehouse_id.toString());
                setProductItemsStockRemind(productItemData);
            }
        } catch (err) {
            setError('Failed to fetch product items');
        } finally {
            setLoading(false);
        }
    };

    // Fetch a product item by ID
    const fetchProductItemById = async (id: number) => {
        try {
            setLoading(true);
            const productItem = await WarehouseProductItemService.getProductItemById(id);
            return productItem;
        } catch (err) {
            setError('Failed to fetch the product item');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Fetch a product item by Product ID
    const fetchProductItemByProductId = async (id: number) => {
        try {
            setLoading(true);
            const productItem = await WarehouseProductItemService.getProductItemByProductId(id);
            return productItem;
        } catch (err) {
            setError('Failed to fetch the product item');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Create a new product item
    const createProductItem = async (item: Omit<WarehouseProductItem, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const newItemRes = await WarehouseProductItemService.createProductItem(item);
            return newItemRes;
        } catch (err) {
            setError('Failed to create product item');
        } finally {
            setLoading(false);
        }
    };

    const createMultipleProductItems = async (items: Omit<WarehouseProductItem, 'id' | 'created_time' | 'updated_time'>[]) => {
        setLoading(true);
        setError(null);
        const createdItems: WarehouseProductItem[] = [];
        const dbSuccesses: boolean[] = [];

        try {
            // Loop through the items array and create each item
            for (const item of items) {
                const newItemRes = await WarehouseProductItemService.createProductItem(item);
                createdItems.push(newItemRes.data);
                dbSuccesses.push(newItemRes.dbSuccess); // Add each success/failure to dbSuccesses
            }

            if (dbSuccesses.includes(false)) {
                return { dbsuccess: false }
            } else {
                return { dbsuccess: true }
            }

        } catch (err: any) {
            setError(err.message || 'Error creating multiple product items');
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Update an existing product item
    const updateProductItem = async (id: number, updatedItem: Omit<WarehouseProductItem, 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const updatedItemRes = await WarehouseProductItemService.updateProductItem(id, updatedItem);
            return updatedItemRes;
        } catch (err) {
            setError('Failed to update product item');
        } finally {
            setLoading(false);
        }
    };

    // Delete a product item by ID
    const deleteProductItem = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            await WarehouseProductItemService.deleteProductItem(id);
            setProductItems(productItems.filter((i) => i.id !== BigInt(id)));
        } catch (err) {
            setError('Failed to delete product item');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFilteredProductItems();
        fetchProductItemsStockRemind();
    }, [currentUser]);

    return {
        productItems,
        productItemsStockRemind,
        loading,
        error,
        fetchProductItemsStockRemind,
        fetchProductItemById,
        fetchProductItemByProductId,
        createProductItem,
        createMultipleProductItems,
        updateProductItem,
        deleteProductItem,
    };
};
