// services/StockOutService.ts
import axios from 'axios';
import { StockOut } from '../models/StockOut';

const API_URL = process.env.REACT_APP_SERVER_URL + "/stockout";

const StockOutService = {
    getAllStockOuts: async () => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getAllStockOuts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    getAllStockOutsByWarehouseId: async (warehouseId: string) => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getAllStockOutsByWarehouseId/${warehouseId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    getAllPickupStockOuts: async () => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getAllPickupStockOuts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    getAllPickupStockOutsByWarehouseId: async (warehouseId: string) => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getAllPickupStockOutsByWarehouseId/${warehouseId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    getAllCompletedStockOuts: async () => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getAllCompletedStockOuts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    getAllCompletedStockOutsByWarehouseId: async (warehouseId: string) => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getAllCompletedStockOutsByWarehouseId/${warehouseId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    getStockOutById: async (id: number): Promise<StockOut> => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getStockOutById/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    createStockOut: async (StockOutData: Omit<StockOut, 'id' | 'created_time' | 'updated_time' | 'status'>) => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.post(`${API_URL}/add`, StockOutData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    updateStockOut: async (id: number, StockOutData: Omit<StockOut, 'id' | 'created_time' | 'updated_time' | 'status'>) => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.put(`${API_URL}/update/${id}`, StockOutData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    deleteStockOut: async (id: number): Promise<void> => {
        const token = localStorage.getItem('gpcwstoken_kw');
        await axios.delete(`${API_URL}/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
};

export default StockOutService;
