// frontend/src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './app/Login/Page';
import PrivateRoute from './app/PrivateRoute';
import { UserProvider } from './context/userContext';
import { ApplicationLayout } from './app/ApplicationLayout';
import { Toaster } from 'react-hot-toast';
import { SuperAdminProvider } from './provider/superadminProvider';
import { MainLayout } from './app/Layout/MainLayout';
import AccessDenied from './app/404/AccessDenied/Page';
import SetupSuppliersPage from './app/Setup/Supplier/Page';
import SetupAddSupplierPage from './app/Setup/Supplier/add/Page';
import SetupEditSupplierPage from './app/Setup/Supplier/edit/Page';
import SetupViewSupplierIdPage from './app/Setup/Supplier/[id]/Page';
import SetupLogisticsPage from './app/Setup/Logistic/Page';
import SetupAddLogisticPage from './app/Setup/Logistic/add/Page';
import SetupEditLogisticPage from './app/Setup/Logistic/edit/Page';
import SetupViewLogisticIdPage from './app/Setup/Logistic/[id]/Page';
import SetupCustomersPage from './app/Setup/Customer/Page';
import SetupAddCustomerPage from './app/Setup/Customer/add/Page';
import SetupEditCustomerPage from './app/Setup/Customer/edit/Page';
import SetupViewCustomerIdPage from './app/Setup/Customer/[id]/Page';
import SetupBrandPage from './app/Products/Brands/Page';
import SetupAddBrandPage from './app/Products/Brands/add/Page';
import SetupEditBrandPage from './app/Products/Brands/edit/Page';
import SetupViewBrandIdPage from './app/Products/Brands/[id]/Page';
import WarehouseProductPage from './app/Products/Product/Page';
import AddWarehouseProductPage from './app/Products/Product/add/Page';
import EditWarehouseProductPage from './app/Products/Product/edit/Page';
import ViewWarehouseProductIdPage from './app/Products/Product/[id]/Page';
import StockInPage from './app/Stocks/StockIn/Page';
import AddStockInPage from './app/Stocks/StockIn/add/Page';
import AddStockInItemPage from './app/Stocks/StockIn/StockInItems/add/Page';
import ViewStockInIdPage from './app/Stocks/StockIn/[id]/Page';
import StockOutPage from './app/Stocks/StockOut/Page';
import AddStockOutPage from './app/Stocks/StockOut/add/Page';
import AddStockOutItemPage from './app/Stocks/StockOut/StockOutItems/add/Page';
import StockAdjustPage from './app/Stocks/StockAdjust/Page';
import AddStockAdjustItemPage from './app/Stocks/StockAdjust/StockAdjustItem/add/Page';
import AddStockAdjustPage from './app/Stocks/StockAdjust/add/Page';
import PickupStockOutPage from './app/Tracking/Pickup/Page';
import ViewStockOutIdPage from './app/Stocks/StockOut/[id]/Page';
import ViewStockAdjustIdPage from './app/Stocks/StockAdjust/[id]/Page';
import TrackingDeliveryConfirmationPage from './app/Tracking/Delivery/Confirmation/Page';
import ViewTrackingPickupIdPage from './app/Tracking/Pickup/[id]/Page';
import CompletedStockOutPage from './app/Tracking/Completed/Page';
import SetupWarehousesPage from './app/Setup/Warehouse/Page';
import SetupAddWarehousePage from './app/Setup/Warehouse/add/Page';
import SetupEditWarehousePage from './app/Setup/Warehouse/edit/Page';
import SetupViewWarehouseIdPage from './app/Setup/Warehouse/[id]/Page';
import AddWarehouseProductItemPageId from './app/Products/ProductItems/add [id]/Page';
import EditStockInPage from './app/Stocks/StockIn/edit/Page';
import AddStockInItemPageId from './app/Stocks/StockIn/StockInItems/add [id]/Page';
import EditStockOutPage from './app/Stocks/StockOut/edit/Page';
import AddStockOutItemPageId from './app/Stocks/StockOut/StockOutItems/add [id]/Page';
import EditStockAdjustPage from './app/Stocks/StockAdjust/edit/Page';
import AddStockAdjustItemPageId from './app/Stocks/StockAdjust/StockAdjustItem/add [id]/Page';
import EditMyAccountPage from './app/MyAccount/edit/Page';
import ViewMyAccountPage from './app/MyAccount/[id]/Page';
import UsersPage from './app/Users/Page';
import GHDashboard from './app/Dashboard/Page';
import ViewUserIdPage from './app/Users/[id]/Page';
import EditUserPage from './app/Users/edit/Page';
import AddUserPage from './app/Users/add/Page';
import NotFoundPage from './app/404/404NotFound/Page';
import TrackingDeliverySuccessPage from './app/Tracking/Delivery/Success/Page';
import ReportStockBalancePage from './app/Report/StockBalance/Page';
import ReportStockMovementPage from './app/Report/StockMovement/Page';
import ReportStockByBatchPage from './app/Report/StockByBatch/Page';
import PageAccess from './app/PageAccess';
import DeveloperAddUserPage from './app/DeveloperPage/Page';
import WarehouseProductCategoryPage from './app/Products/Category/Page';
import AddWarehouseProductCategoryPage from './app/Products/Category/add/Page';
import EditWarehouseProductCategoryPage from './app/Products/Category/edit/Page';
import ViewWarehouseProductCategoryIdPage from './app/Products/Category/[id]/Page';
import WarehouseProductDepartmentPage from './app/Products/Department/Page';
import AddWarehouseProductDepartmentPage from './app/Products/Department/add/Page';
import EditWarehouseProductDepartmentPage from './app/Products/Department/edit/Page';
import ViewWarehouseProductDepartmentIdPage from './app/Products/Department/[id]/Page';
import WarehouseProductGroupPage from './app/Products/Group/Page';
import AddWarehouseProductGroupPage from './app/Products/Group/add/Page';
import EditWarehouseProductGroupPage from './app/Products/Group/edit/Page';
import ViewWarehouseProductGroupIdPage from './app/Products/Group/[id]/Page';
import ReportStockBalanceIdPage from './app/Report/StockBalance/[id]/Page';
import ReportStockMovementIdPage from './app/Report/StockMovement/[id]/Page';
import ReportStockByBatchIdPage from './app/Report/StockByBatch/[id]/Page';

const App: React.FC = () => {
  return (
    <Router>
      <UserProvider>
        <Toaster
          position="bottom-right"
        />

        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="accessdenied" element={<AccessDenied />} />
          <Route path="gp/tracking/delivery/confirmation/:id" element={<TrackingDeliveryConfirmationPage />} />
          <Route path="gp/tracking/delivery/success" element={<TrackingDeliverySuccessPage />} />

          <Route element={<PrivateRoute />}>
            {/* <Route element={<ApplicationLayout />}>
              <Route path="dashboard" element={<Dashboard />} />
            </Route> */}

            {/* Superadmin Pages */}
            <Route element={<MainLayout />}>
              <Route path="gp/dashboard" element={<GHDashboard />} />

              <Route element={<PageAccess requiredRole='1' />}>
                <Route path="gp/users" element={<UsersPage />} />
                <Route path="gp/users/view/:id" element={<ViewUserIdPage />} />
                <Route path="gp/users/edit/:id" element={<EditUserPage />} />
                <Route path="gp/users/add" element={<AddUserPage />} />

              </Route>

              <Route element={<PageAccess requiredRole='1' requiredRole2='2' />}>
                {/* Brand Pages */}
                <Route path="gp/inventories/brands" element={<SetupBrandPage />} />
                <Route path="gp/inventories/brands/add" element={<SetupAddBrandPage />} />
                <Route path="gp/inventories/brands/edit/:id" element={<SetupEditBrandPage />} />
                <Route path="gp/inventories/brands/view/:id" element={<SetupViewBrandIdPage />} />

                {/* Categories Pages */}
                <Route path="gp/inventories/categories" element={<WarehouseProductCategoryPage />} />
                <Route path="gp/inventories/categories/add" element={<AddWarehouseProductCategoryPage />} />
                <Route path="gp/inventories/categories/edit/:id" element={<EditWarehouseProductCategoryPage />} />
                <Route path="gp/inventories/categories/view/:id" element={<ViewWarehouseProductCategoryIdPage />} />

                {/* Departments Pages */}
                <Route path="gp/inventories/departments" element={<WarehouseProductDepartmentPage />} />
                <Route path="gp/inventories/departments/add" element={<AddWarehouseProductDepartmentPage />} />
                <Route path="gp/inventories/departments/edit/:id" element={<EditWarehouseProductDepartmentPage />} />
                <Route path="gp/inventories/departments/view/:id" element={<ViewWarehouseProductDepartmentIdPage />} />

                {/* Groups Pages */}
                <Route path="gp/inventories/groups" element={<WarehouseProductGroupPage />} />
                <Route path="gp/inventories/groups/add" element={<AddWarehouseProductGroupPage />} />
                <Route path="gp/inventories/groups/edit/:id" element={<EditWarehouseProductGroupPage />} />
                <Route path="gp/inventories/groups/view/:id" element={<ViewWarehouseProductGroupIdPage />} />

                {/* Product Pages */}
                <Route path="gp/inventories/products" element={<WarehouseProductPage />} />
                <Route path="gp/inventories/products/add" element={<AddWarehouseProductPage />} />
                <Route path="gp/inventories/products/add/items/:id" element={<AddWarehouseProductItemPageId />} />
                <Route path="gp/inventories/products/edit/:id" element={<EditWarehouseProductPage />} />
                <Route path="gp/inventories/products/view/:id" element={<ViewWarehouseProductIdPage />} />

                {/* Product Items Pages */}
                {/* <Route path="gp/inventories/products/items/add" element={<AddWarehouseProductItemPage />} /> */}

                {/* Stock in Pages */}
                <Route path="gp/stocks/stockin" element={<StockInPage />} />
                <Route path="gp/stocks/stockin/add" element={<AddStockInPage />} />
                <Route path="gp/stocks/stockin/add/items/:id" element={<AddStockInItemPageId />} />
                <Route path="gp/stocks/stockin/edit/:id" element={<EditStockInPage />} />
                <Route path="gp/stocks/stockin/view/:id" element={<ViewStockInIdPage />} />

                {/* Stock in items Pages */}
                {/* <Route path="gp/stocks/stockin/items/add" element={<AddStockInItemPage />} /> */}

                {/* Stock out Pages */}
                <Route path="gp/stocks/stockout" element={<StockOutPage />} />
                <Route path="gp/stocks/stockout/add" element={<AddStockOutPage />} />
                <Route path="gp/stocks/stockout/add/items/:id" element={<AddStockOutItemPageId />} />
                <Route path="gp/stocks/stockout/view/:id" element={<ViewStockOutIdPage />} />
                <Route path="gp/stocks/stockout/edit/:id" element={<EditStockOutPage />} />

                {/* Stock out items Pages */}
                {/* <Route path="gp/stocks/stockout/items/add" element={<AddStockOutItemPage />} /> */}

                {/* Stock adjust Pages */}
                <Route path="gp/stocks/stockadjust" element={<StockAdjustPage />} />
                <Route path="gp/stocks/stockadjust/add" element={<AddStockAdjustPage />} />
                <Route path="gp/stocks/stockadjust/add/items/:id" element={<AddStockAdjustItemPageId />} />
                <Route path="gp/stocks/stockadjust/view/:id" element={<ViewStockAdjustIdPage />} />
                <Route path="gp/stocks/stockadjust/edit/:id" element={<EditStockAdjustPage />} />

                {/* Stock adjust items Pages */}
                {/* <Route path="gp/stocks/stockadjust/items/add" element={<AddStockAdjustItemPage />} /> */}

                {/* Pickup Pages */}
                <Route path="gp/tracking/pickups" element={<PickupStockOutPage />} />
                <Route path="gp/tracking/pickups/view/:id" element={<ViewTrackingPickupIdPage />} />

                {/* Completed Pages */}
                <Route path="gp/tracking/completed" element={<CompletedStockOutPage />} />

                {/* Supplier Pages */}
                <Route path="gp/setup/suppliers" element={<SetupSuppliersPage />} />
                <Route path="gp/setup/suppliers/add" element={<SetupAddSupplierPage />} />
                <Route path="gp/setup/suppliers/edit/:id" element={<SetupEditSupplierPage />} />
                <Route path="gp/setup/suppliers/view/:id" element={<SetupViewSupplierIdPage />} />

                {/* Logistic Pages */}
                <Route path="gp/setup/logistics" element={<SetupLogisticsPage />} />
                <Route path="gp/setup/logistics/add" element={<SetupAddLogisticPage />} />
                <Route path="gp/setup/logistics/edit/:id" element={<SetupEditLogisticPage />} />
                <Route path="gp/setup/logistics/view/:id" element={<SetupViewLogisticIdPage />} />

                {/* Customer Pages */}
                <Route path="gp/setup/customers" element={<SetupCustomersPage />} />
                <Route path="gp/setup/customers/add" element={<SetupAddCustomerPage />} />
                <Route path="gp/setup/customers/edit/:id" element={<SetupEditCustomerPage />} />
                <Route path="gp/setup/customers/view/:id" element={<SetupViewCustomerIdPage />} />

                {/* Warehouses Pages */}
                <Route path="gp/warehouses" element={<SetupWarehousesPage />} />
                <Route path="gp/warehouses/add" element={<SetupAddWarehousePage />} />
                <Route path="gp/warehouses/edit/:id" element={<SetupEditWarehousePage />} />
                <Route path="gp/warehouses/view/:id" element={<SetupViewWarehouseIdPage />} />
              </Route>

              {/* Report Pages */}
              <Route path="gp/reports/stockbalance" element={<ReportStockBalancePage />} />
              <Route path="gp/reports/stockbalance/view/:id" element={<ReportStockBalanceIdPage />} />
              <Route path="gp/reports/stockmovement" element={<ReportStockMovementPage />} />
              <Route path="gp/reports/stockmovement/view/:id" element={<ReportStockMovementIdPage />} />
              <Route path="gp/reports/stockbybatch" element={<ReportStockByBatchPage />} />
              <Route path="gp/reports/stockbybatch/view/:id" element={<ReportStockByBatchIdPage />} />

              {/* My Account Pages */}
              <Route path="gp/myaccount/edit" element={<EditMyAccountPage />} />
              <Route path="gp/myaccount/view" element={<ViewMyAccountPage />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/developers/kingdomcodeworks/adduser" element={<DeveloperAddUserPage />} />

        </Routes>

      </UserProvider>
    </Router>
  );
};

export default App;
