// services/warehouseService.ts

import axios from 'axios';
import { Warehouse } from '../models/Warehouse';

const API_URL = process.env.REACT_APP_SERVER_URL + "/warehouse";

const warehouseService = {

    getAllWarehouses: async (): Promise<Warehouse[]> => {
        try {
            const response = await axios.get<Warehouse[]>(`${API_URL}/getAllWarehouses`);
            return response.data;
        } catch (error) {
            console.error('Error fetching warehouses:', error);
            throw error;
        }
    },

    getWarehouseById: async (id: Number): Promise<Warehouse> => {
        try {
            const response = await axios.get<Warehouse>(`${API_URL}/getWarehouseById/${id}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching warehouse with id ${id}:`, error);
            throw error;
        }
    },

    createWarehouse: async (warehouseData: Omit<Warehouse, 'id' | 'created_time' | 'updated_time'>): Promise<Warehouse> => {
        try {
            const response = await axios.post<Warehouse>(`${API_URL}/add`, warehouseData);
            return response.data;
        } catch (error) {
            console.error('Error creating warehouse:', error);
            throw error;
        }
    },

    updateWarehouse: async (id: Number, warehouseData: Omit<Warehouse, 'id' | 'created_time' | 'updated_time'>): Promise<Warehouse> => {
        try {
            const response = await axios.put<Warehouse>(`${API_URL}/update/${id}`, warehouseData);
            return response.data;
        } catch (error) {
            console.error(`Error updating warehouse with id ${id}:`, error);
            throw error;
        }
    },

    deleteWarehouse: async (id: Number): Promise<void> => {
        try {
            await axios.delete(`${API_URL}/delete/${id}`);
        } catch (error) {
            console.error(`Error deleting warehouse with id ${id}:`, error);
            throw error;
        }
    }
};

export default warehouseService;
