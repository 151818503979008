'use client'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Avatar } from '../../components/Avatar'
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from '../../components/Dropdown'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from '../../components/Navbar'
import {
    Sidebar,
    SidebarBody,
    SidebarFooter,
    SidebarHeader,
    SidebarHeading,
    SidebarItem,
    SidebarLabel,
    SidebarSection,
    SidebarSpacer,
} from '../../components/Sidebar'
import { SidebarLayout } from '../../components/SidebarLayout'
import {
    ArrowRightStartOnRectangleIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    Cog8ToothIcon,
    LightBulbIcon,
    PlusIcon,
    ShieldCheckIcon,
    UserCircleIcon,
    LanguageIcon,
    ChevronRightIcon,
    CheckIcon,
    BellIcon,
    QueueListIcon,
    ClipboardDocumentCheckIcon,
    TruckIcon,
    CheckBadgeIcon,
    ShoppingBagIcon,
    PlusCircleIcon,
    MinusCircleIcon,
    PauseCircleIcon,
    CogIcon,
    BuildingStorefrontIcon,
    RocketLaunchIcon,
    Squares2X2Icon,
    ShoppingCartIcon,
    ExclamationTriangleIcon,
    GlobeAltIcon,
    TableCellsIcon,
    RectangleGroupIcon,
    Square3Stack3DIcon,
} from '@heroicons/react/16/solid'
import {
    Cog6ToothIcon,
    HomeIcon,
    QuestionMarkCircleIcon,
    SparklesIcon,
    Square2StackIcon,
    TicketIcon,
    UserGroupIcon,
    RectangleStackIcon,
} from '@heroicons/react/20/solid'
import { useContext, useState } from 'react'
import { UserContext } from '../../context/userContext'
import { Divider } from '../../components/Divider'
import showLoadingToast from '../../components/Toasts/ShowLoadingToast'
import { SuperAdminProvider } from '../../provider/superadminProvider'
import { useTranslation } from 'react-i18next';
import { useWarehouses } from '../../hooks/useWarehouse'
import { TableCell } from '../../components/Table'

function AccountDropdownMenu({ anchor }: { anchor: 'top start' | 'bottom end' }) {
    const navigate = useNavigate();
    const { logout } = useContext(UserContext);

    function logoutClick(event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) {
        event.preventDefault();
        showLoadingToast("Signing out...", 100);
        setTimeout(() => {
            logout();
        }, 1000)
    }

    return (
        <DropdownMenu className="min-w-64" anchor={anchor}>
            <DropdownItem onClick={() => navigate(`/gp/myaccount/view`)}>
                <UserCircleIcon />
                <DropdownLabel>My account</DropdownLabel>
            </DropdownItem>
            {/* <DropdownItem onClick={() => navigate("#")}>
                <ShieldCheckIcon />
                <DropdownLabel>Privacy policy</DropdownLabel>
            </DropdownItem>
            <DropdownItem onClick={() => navigate("#")}>
                <LightBulbIcon />
                <DropdownLabel>Share feedback</DropdownLabel>
            </DropdownItem> */}
            {/* <DropdownDivider /> */}
            <DropdownItem onClick={logoutClick}>
                <ArrowRightStartOnRectangleIcon />
                <DropdownLabel>Sign out</DropdownLabel>
            </DropdownItem>
        </DropdownMenu>
    )
}

function LanguageDropdownMenu({ anchor }: { anchor: 'top start' | 'bottom end' }) {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language || 'en');

    const changeLanguage = (lng: string) => {
        showLoadingToast("Changing language...", 100)
        setTimeout(() => {
            setLanguage(lng);
            i18n.changeLanguage(lng);
        }, 1000)
    };

    return (
        <DropdownMenu className="min-w-64" anchor={anchor}>
            <DropdownItem onClick={() => changeLanguage('en')} className={language === 'en' ? 'font-bold' : 'font-normal'}>
                <DropdownLabel>English</DropdownLabel>
                {language === 'en' && <CheckIcon />}
            </DropdownItem>
            <DropdownItem onClick={() => changeLanguage('cn')} className={language === 'cn' ? 'font-bold' : 'font-normal'}>
                <DropdownLabel>中文</DropdownLabel>
                {language === 'cn' && <CheckIcon />}
            </DropdownItem>
        </DropdownMenu>
    );
}

function TrackingSidebarItem() {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <div className="tracking-sidebar">
            <SidebarItem onClick={toggleExpand} current={pathname.includes('gp/tracking')}>
                <ClipboardDocumentCheckIcon />
                <SidebarLabel>Tracking</SidebarLabel>
                {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </SidebarItem>

            {isExpanded && (
                <div className="ml-6 py-2">
                    <SidebarItem onClick={() => navigate("/gp/tracking/pickups")} current={pathname.includes('gp/tracking/pickups')}>
                        <TruckIcon />
                        <SidebarLabel>Pickup</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem onClick={() => navigate("/gp/tracking/completed")} current={pathname.includes('gp/tracking/completed')}>
                        <CheckBadgeIcon />
                        <SidebarLabel>Completed</SidebarLabel>
                    </SidebarItem>
                </div>
            )}
        </div>
    );
}

function StockSidebarItem() {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <div className="tracking-sidebar">
            <SidebarItem onClick={toggleExpand} current={pathname.includes('gp/stocks')}>
                <ShoppingBagIcon />
                <SidebarLabel>Stocks</SidebarLabel>
                {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </SidebarItem>

            {isExpanded && (
                <div className="ml-6 py-2">
                    <SidebarItem onClick={() => navigate("/gp/stocks/stockin")} current={pathname.includes('gp/stocks/stockin')}>
                        <PlusCircleIcon />
                        <SidebarLabel>Stock In</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem onClick={() => navigate("/gp/stocks/stockout")} current={pathname.includes('gp/stocks/stockout')}>
                        <MinusCircleIcon />
                        <SidebarLabel>Stock Out</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem onClick={() => navigate("/gp/stocks/stockadjust")} current={pathname.includes('gp/stocks/stockadjust')}>
                        <PauseCircleIcon />
                        <SidebarLabel>Stock Adjust</SidebarLabel>
                    </SidebarItem>
                </div>
            )}
        </div>
    );
}

function InventorySidebarItem() {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <div className="tracking-sidebar">
            <SidebarItem onClick={toggleExpand} current={pathname.includes('gp/inventories')}>
                <ShoppingCartIcon />
                <SidebarLabel>Inventories</SidebarLabel>
                {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </SidebarItem>

            {isExpanded && (
                <div className="ml-6 py-2">
                    <SidebarItem onClick={() => navigate("/gp/inventories/products")} current={pathname.includes('gp/inventories/products')}>
                        <ShoppingBagIcon />
                        <SidebarLabel>Products</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem onClick={() => navigate("/gp/inventories/groups")} current={pathname.includes('gp/inventories/groups')}>
                        <RectangleGroupIcon />
                        <SidebarLabel>Groups</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem onClick={() => navigate("/gp/inventories/categories")} current={pathname.includes('gp/inventories/categories')}>
                        <RectangleStackIcon />
                        <SidebarLabel>Categories</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem onClick={() => navigate("/gp/inventories/departments")} current={pathname.includes('gp/inventories/departments')}>
                        <Square3Stack3DIcon />
                        <SidebarLabel>Departments</SidebarLabel>
                    </SidebarItem>
                </div>
            )}
        </div>
    );
}

function SetupSidebarItem() {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <div className="tracking-sidebar">
            <SidebarItem onClick={toggleExpand} current={pathname.includes('gp/setup')}>
                <CogIcon />
                <SidebarLabel>Setup</SidebarLabel>
                {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </SidebarItem>

            {isExpanded && (
                <div className="ml-6 py-2">
                    <SidebarItem onClick={() => navigate("/gp/setup/suppliers")} current={pathname.includes('gp/setup/suppliers')}>
                        <GlobeAltIcon />
                        <SidebarLabel>Suppliers</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem onClick={() => navigate("/gp/setup/customers")} current={pathname.includes('gp/setup/customers')}>
                        <UserGroupIcon />
                        <SidebarLabel>Customers</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem onClick={() => navigate("/gp/setup/logistics")} current={pathname.includes('gp/setup/logistics')}>
                        <TruckIcon />
                        <SidebarLabel>Logistics</SidebarLabel>
                    </SidebarItem>

                </div>
            )}
        </div>
    );
}

function ReportSidebarItem() {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const [isExpanded, setIsExpanded] = useState(false);
    const { currentUser } = useContext(UserContext);

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <div className="tracking-sidebar">
            <SidebarItem onClick={toggleExpand} current={pathname.includes('gp/report')}>
                <ClipboardDocumentCheckIcon />
                <SidebarLabel>Reports</SidebarLabel>
                {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </SidebarItem>

            {isExpanded && (
                <div className="ml-6 py-2">
                    {(currentUser?.role.toString() !== "5") && <SidebarItem onClick={() => navigate("/gp/reports/stockbalance")} current={pathname.includes('gp/reports/stockbalance')}>
                        <TableCellsIcon />
                        <SidebarLabel>Stock Balance</SidebarLabel>
                    </SidebarItem>}
                    <SidebarItem onClick={() => navigate("/gp/reports/stockmovement")} current={pathname.includes('gp/reports/stockmovement')}>
                        <TableCellsIcon />
                        <SidebarLabel>Stock Movement</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem onClick={() => navigate("/gp/reports/stockbybatch")} current={pathname.includes('gp/reports/stockbybatch')}>
                        <TableCellsIcon />
                        <SidebarLabel>Stock By Batch</SidebarLabel>
                    </SidebarItem>
                </div>
            )}
        </div>
    );
}

export function MainLayout() {
    const location = useLocation();
    const pathname = location.pathname;
    const { currentUser } = useContext(UserContext);
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language || 'en');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { warehouses } = useWarehouses();


    // Define the sidebar items based on the role
    const renderSidebarItems = () => {
        switch (currentUser?.rolename) {
            case 'Superadmin':
                return (
                    <>
                        <SidebarItem onClick={() => navigate("/gp/dashboard")} current={pathname.includes('gp/dashboard')}>
                            <HomeIcon />
                            <SidebarLabel>Dashboard</SidebarLabel>
                        </SidebarItem>
                        <SidebarItem onClick={() => navigate("/gp/users")} current={pathname.includes('/gp/users')}>
                            <UserGroupIcon />
                            <SidebarLabel>Users</SidebarLabel>
                        </SidebarItem>
                        <SidebarItem onClick={() => navigate("/gp/warehouses")} current={pathname.includes('gp/warehouses')}>
                            <BuildingStorefrontIcon />
                            <SidebarLabel>Warehouses</SidebarLabel>
                        </SidebarItem>
                        <TrackingSidebarItem />
                        <StockSidebarItem />
                        <InventorySidebarItem />
                        <SetupSidebarItem />
                        <ReportSidebarItem />
                    </>
                );
            case 'Staff':
                return (
                    <>
                        <SidebarItem onClick={() => navigate("/gp/dashboard")} current={pathname.includes('gp/dashboard')}>
                            <HomeIcon />
                            <SidebarLabel>Dashboard</SidebarLabel>
                        </SidebarItem>
                        <TrackingSidebarItem />
                        <StockSidebarItem />
                        <InventorySidebarItem />
                        <SetupSidebarItem />
                        <ReportSidebarItem />
                    </>
                );
            case 'Supplier':
                return (
                    <>
                        <SidebarItem onClick={() => navigate("/gp/dashboard")} current={pathname.includes('gp/dashboard')}>
                            <HomeIcon />
                            <SidebarLabel>Dashboard</SidebarLabel>
                        </SidebarItem>
                        <ReportSidebarItem />
                    </>
                );
            default:
                return null; // Or render a message, or redirect
        }
    };

    return (
        <SidebarLayout
            navbar={
                <Navbar>
                    <NavbarSpacer />
                    <NavbarSection>
                        <Dropdown>
                            <DropdownButton as={NavbarItem}>
                                <Avatar square initials={currentUser ? currentUser.fullname[0] : "GP"} className="bg-gray-300 text-gray-700" />
                            </DropdownButton>
                            <AccountDropdownMenu anchor="bottom end" />
                        </Dropdown>
                    </NavbarSection>
                </Navbar>
            }
            sidebar={
                <Sidebar>
                    <SidebarHeader>
                        <Dropdown>
                            <DropdownButton as={SidebarItem}>
                                <Avatar slot="icon" initials={`${currentUser?.warehouse_name![0]}` || "G"} className="bg-emerald-700 text-white" />
                                <SidebarLabel>{currentUser?.warehouse_name}</SidebarLabel>
                                {/* <ChevronDownIcon /> */}
                            </DropdownButton>

                            {/* <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                                {warehouses && warehouses.map((warehouse, index) => (
                                    <DropdownItem key={warehouse.id}>
                                        <Avatar slot="icon" initials={warehouse.warehouse_name![0].toString()} className="bg-zinc-400 text-white" />
                                        <DropdownLabel>{warehouse.warehouse_name}</DropdownLabel>
                                    </DropdownItem>
                                ))
                                }
                                <DropdownItem href="/settings">
                                    <Cog8ToothIcon />
                                    <DropdownLabel>Settings</DropdownLabel>
                                </DropdownItem>
                            </DropdownMenu> */}
                        </Dropdown>
                    </SidebarHeader>

                    <SidebarBody>
                        <SidebarSection>
                            {renderSidebarItems()}
                        </SidebarSection>

                        {/* <SidebarSection>

                        </SidebarSection>

                        <SidebarSpacer />

                        <SidebarSection>
                            <SidebarItem href="#">
                                <QuestionMarkCircleIcon />
                                <SidebarLabel>Support</SidebarLabel>
                            </SidebarItem>
                            <SidebarItem href="#">
                                <SparklesIcon />
                                <SidebarLabel>Changelog</SidebarLabel>
                            </SidebarItem>
                        </SidebarSection> */}
                    </SidebarBody>

                    <SidebarFooter className="max-lg:hidden">
                        <Dropdown>
                            <DropdownButton as={SidebarItem}>
                                <span className="flex min-w-0 items-center gap-3">
                                    <Avatar square initials={currentUser ? currentUser.fullname[0] : "GH"} className="bg-gray-300 text-gray-700 size-10" />
                                    <span className="min-w-0">
                                        <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{currentUser ? currentUser.fullname : "Guest"}</span>
                                        <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                                            {currentUser ? currentUser.rolename || "Undefined Role" : "Undefined Role"}
                                        </span>
                                    </span>
                                </span>
                                <ChevronUpIcon />
                            </DropdownButton>
                            <AccountDropdownMenu anchor="top start" />
                        </Dropdown>
                    </SidebarFooter>
                </Sidebar>
            }
        >
            {/* Main Content */}
            <main className="mx-auto max-w-screen-2xl text-zinc-950 antialiased dark:text-white dark:lg:bg-zinc-950">
                <Outlet />
            </main>
        </SidebarLayout>
    )
}
