import { CheckBadgeIcon } from "@heroicons/react/16/solid";
import { useEffect } from "react";

const TrackingDeliverySuccessPage = () => {
    useEffect(() => {
        document.title = "Success"
    }, []);

    return (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <div className="flex text-center gap-2 justify-center pb-4 items-center">
                    <CheckBadgeIcon className="text-green-600 w-8 h-8"></CheckBadgeIcon>
                    <p className="text-xl font-semibold text-green-600">Success</p>
                </div>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Delivery Order Confirmed</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">Thank you. We've received your confirmation.</p>
            </div>
        </main>
    );
}

export default TrackingDeliverySuccessPage;