// services/WarehouseProductItemGroupService.ts
import axios from 'axios';
import { WarehouseProductGroup } from '../models/WarehouseProductGroup';

const API_URL = `${process.env.REACT_APP_SERVER_URL}/warehouseproductgroup`;

export const WarehouseProductGroupService = {
    getAllGroups: async (): Promise<WarehouseProductGroup[]> => {
        const response = await axios.get(`${API_URL}/getAllGroups`);
        return response.data;
    },

    getGroupById: async (id: number): Promise<WarehouseProductGroup> => {
        const response = await axios.get(`${API_URL}/getAllGroupById/${id}`);
        return response.data;
    },

    createGroup: async (group: Omit<WarehouseProductGroup, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, group);
        return response.data;
    },

    updateGroup: async (id: number, group: Omit<WarehouseProductGroup, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/update/${id}`, group);
        return response.data;
    },

    deleteGroup: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};
