// hooks/useWarehouseProduct.tsx
import { useState, useEffect, useCallback, useContext } from 'react';
import { WarehouseProduct } from '../models/WarehouseProduct';
import { WarehouseProductService } from '../services/warehouseProductService';
import { UserContext } from '../context/userContext';

export const useWarehouseProduct = () => {
    const [products, setProducts] = useState<WarehouseProduct[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [totalProducts, setTotalProducts] = useState<number>(0);
    const { currentUser } = useContext(UserContext);

    const fetchAllFilteredProducts = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await WarehouseProductService.getAllProducts();
            setProducts(data);
            setTotalProducts(data.length);
            if (currentUser?.warehouse_hq === "1") {
                const productData = await WarehouseProductService.getAllProducts();
                setProducts(productData);
                setTotalProducts(productData.length);

            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1") {
                const productData = await WarehouseProductService.getAllProductsByWarehouseId(currentUser.warehouse_id.toString());
                setProducts(productData);
                setTotalProducts(productData.length);
            }
        } catch (err) {
            setError('Failed to fetch products');
        } finally {
            setLoading(false);
        }
    };

    const fetchProductById = async (id: number) => {
        try {
            setLoading(true);
            const product = await WarehouseProductService.getProductById(id);
            return product;
        } catch (err) {
            setError('Failed to fetch this product');
            throw err;
        } finally {
            setLoading(false);
        }
    }

    const createProduct = async (product: Omit<WarehouseProduct, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const newProductRes = await WarehouseProductService.createProduct(product);
            return newProductRes;
        } catch (err) {
            setError('Failed to add product');
        }
    };

    const updateProduct = async (id: number, updatedProduct: Omit<WarehouseProduct, 'created_time' | 'updated_time'>) => {
        try {
            const updatedProductRes = await WarehouseProductService.updateProduct(id, updatedProduct);
            return updatedProductRes;
        } catch (err) {
            setError('Failed to update product');
        }
    };

    const deleteProduct = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            await WarehouseProductService.deleteProduct(id);
            setProducts(products.filter((i) => BigInt(i.id) !== BigInt(id)));
        } catch (err) {
            setError('Failed to delete product item');
        } finally {
            setLoading(false);
        }
    };

    // Automatically fetch products when the hook is first used
    useEffect(() => {
        fetchAllFilteredProducts();
    }, [currentUser]);

    return {
        products,
        loading,
        error,
        createProduct,
        updateProduct,
        deleteProduct,
        fetchProductById,
        totalProducts,
    };
};
