// hooks/useStockIn.ts
import { useState, useEffect, useContext } from 'react';
import { StockIn } from '../models/StockIn';
import StockInService from '../services/stockinService';
import { UserContext } from '../context/userContext';

export const useStockIn = () => {
    const [stockIns, setStockIns] = useState<StockIn[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [totalStockIns, setTotalStockIns] = useState<number>(0);
    const { currentUser } = useContext(UserContext);

    const fetchAllFilteredStockIns = async () => {
        setLoading(true);
        setError(null);
        try {
            if (currentUser?.warehouse_hq === "1") {
                const stockInData = await StockInService.getAllStockIns();
                setStockIns(stockInData);
                setTotalStockIns(stockInData.length);
            } else if (currentUser?.warehouse_id && currentUser.role!.toString() !== "1") {
                const stockInData = await StockInService.getAllStockInsByWarehouseId(currentUser.warehouse_id.toString());
                setStockIns(stockInData);
                setTotalStockIns(stockInData.length);
            }
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-ins');
        } finally {
            setLoading(false);
        }
    }

    // Fetch all stock-ins
    const fetchStockIns = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await StockInService.getAllStockIns();
            setStockIns(data);
            setTotalStockIns(data.length);
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-ins');
        } finally {
            setLoading(false);
        }
    };

    // Fetch all stock-ins
    const getStockIns = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await StockInService.getAllStockIns();
            setStockIns(data);
            setTotalStockIns(data.length);
            return data;
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-ins');
        } finally {
            setLoading(false);
        }
    };

    // Fetch all stock-ins
    const getStockInsByWarehouseId = async (warehouseId: string) => {
        setLoading(true);
        setError(null);
        try {
            const data = await StockInService.getAllStockInsByWarehouseId(warehouseId);
            setStockIns(data);
            setTotalStockIns(data.length);
            return data;
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-ins');
        } finally {
            setLoading(false);
        }
    };

    // Fetch a single stock-in by ID
    const fetchStockInById = async (id: number): Promise<StockIn | null> => {
        setLoading(true);
        setError(null);
        try {
            return await StockInService.getStockInById(id);
        } catch (err: any) {
            setError(err.message || 'Error fetching stock-in');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // Create a new stock-in
    const createStockIn = async (stockInData: Omit<StockIn, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const newStockInRes = await StockInService.createStockIn(stockInData);
            return newStockInRes;
        } catch (err: any) {
            setError(err.message || 'Error creating stock-in');
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Update an existing stock-in
    const updateStockIn = async (id: number, stockInData: Omit<StockIn, 'id' | 'created_time' | 'updated_time'>) => {
        setLoading(true);
        setError(null);
        try {
            const updatedStockInRes = await StockInService.updateStockIn(id, stockInData);
            return updatedStockInRes;
        } catch (err: any) {
            setError(err.message || 'Error updating stock-in');
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Delete a stock-in
    const deleteStockIn = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            await StockInService.deleteStockIn(id);
            setStockIns((prevStockIns) => prevStockIns.filter((stockIn) => stockIn.id !== id));
        } catch (err: any) {
            setError(err.message || 'Error deleting stock-in');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllFilteredStockIns();
    }, [currentUser]);

    return {
        stockIns,
        fetchStockIns,
        fetchStockInById,
        createStockIn,
        updateStockIn,
        deleteStockIn,
        loading,
        error,
        totalStockIns,
        getStockIns,
        getStockInsByWarehouseId,
    };
};
