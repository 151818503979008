import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Text } from '../../../../components/Text';
import { Select } from '../../../../components/Select';
import { Textarea } from '../../../../components/Textarea';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import { SetupUsergroup } from '../../../../models/SetupUsergroup';
import { useSetupUsergroup } from '../../../../hooks/useSetupUsergroup';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import Loading from '../../../../components/Loading';
import { useWarehouses } from '../../../../hooks/useWarehouse';

const SetupEditLogisticPage = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>(); // Assuming `id` is passed as a URL parameter
    const [logistic, setLogistic] = useState<SetupUsergroup | null>(null);
    const { updateUserGroup, fetchUserGroupById, loading, error } = useSetupUsergroup();
    const { warehouses } = useWarehouses();
    const filteredWarehouses = warehouses.filter(warehouse => warehouse.hq !== '1');

    useEffect(() => {
        document.title = "Edit Logistic";
    }, []);

    useEffect(() => {
        const fetchLogisticDetails = async () => {
            if (!id) return; // Ensure ID is valid before making the request

            try {
                const fetchedlogistic = await fetchUserGroupById(Number(id));
                setLogistic(fetchedlogistic);
            } catch (err) {
                showErrorToast('Failed to fetch logistic details.');
            }
        };
        fetchLogisticDetails();
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setLogistic(prev => prev ? ({ ...prev, [name]: value }) : null);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!logistic) return;
        if (!logistic.fullname || !logistic.account_code || !logistic.email || !logistic.address || !logistic.warehouse_id) {
            showErrorToast("Please fill in all field before submit");
            return;
        }


        try {
            await updateUserGroup(Number(logistic.id!), logistic);
            showLoadingToast(`Updating ${logistic.fullname}...`, 100);
            setTimeout(() => {
                navigate('/gp/setup/logistics');
                showSuccessToast(`${logistic.fullname} updated successfully`);
            }, 1000);
        } catch (err) {
            showErrorToast('Failed to update logistic.');
        }
    };

    if (loading) return <Loading />;
    if (error) return <p>{error}</p>;
    if (!logistic) return <p>logistic not found.</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Edit User</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className="ml-3" disabled={(!logistic.fullname || !logistic.account_code || !logistic.email || !logistic.address || !logistic.warehouse_id)}>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Details related to the transportation, storage, and distribution of goods.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Full Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Full Name"
                                        name="fullname"
                                        value={logistic.fullname}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Second Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Second Name"
                                        name="second_name"
                                        value={logistic.second_name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Area</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Area"
                                        name="area"
                                        value={logistic.area}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Group</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Group"
                                        name="group"
                                        value={logistic.group}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Office Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Office Number"
                                        name="office_no"
                                        value={logistic.office_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Fax Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Fax Number"
                                        name="fax_no"
                                        value={logistic.fax_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Mobile Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Mobile Number"
                                        name="mobile_no"
                                        value={logistic.mobile_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Address</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Address"
                                        name="address"
                                        value={logistic.address?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Address 2</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Address 2"
                                        name="address_2"
                                        value={logistic.address_2?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Address 3</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Address 3"
                                        name="address_3"
                                        value={logistic.address_3?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Address 4</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Address 4"
                                        name="address_4"
                                        value={logistic.address_4?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Email</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="email"
                                        aria-label="Email"
                                        name="email"
                                        value={logistic.email?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Warehouse</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Warehouse"
                                        name="warehouse_id"
                                        value={logistic.warehouse_id!}
                                        onChange={handleChange}
                                    >
                                        {filteredWarehouses.map(warehouse => (
                                            <option key={warehouse.id.toString()} value={warehouse.id.toString()}>
                                                {warehouse.warehouse_name}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button type="submit" disabled={(!logistic.fullname || !logistic.account_code || !logistic.email || !logistic.address || !logistic.warehouse_id)}>Save</Button>
            </div>
        </form>
    );
};

export default SetupEditLogisticPage;
