// src/hooks/useTrackingDelivery.ts
import { useEffect, useState } from 'react';
import { TrackingDelivery } from '../models/TrackingDelivery';
import TrackingDeliveryService from '../services/trackingDeliveryService';
import showErrorToast from '../components/Toasts/ShowErrorToast';

export const useTrackingDelivery = () => {
    const [trackingDeliveries, setTrackingDeliveries] = useState<TrackingDelivery[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchTrackingDeliveries = async () => {
        try {
            const data = await TrackingDeliveryService.getAllTrackingDeliveries();
            setTrackingDeliveries(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
            showErrorToast(`Error fetching tracking deliveries: ${err}`);
        } finally {
            setLoading(false);
        }
    };

    const fetchTrackingDeliveryById = async (id: number): Promise<TrackingDelivery | null> => {
        setLoading(true);
        setError(null);
        try {
            return await TrackingDeliveryService.getTrackingDeliveryById(id);
        } catch (err: any) {
            setError(err.message || 'Error fetching delivery by ID');
            showErrorToast(`Error fetching tracking delivery by id: ${err}, ${id}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const fetchTrackingDeliveryByStockOutId = async (stockOutId: number): Promise<TrackingDelivery | null> => {
        setLoading(true);
        setError(null);
        try {
            return await TrackingDeliveryService.getTrackingDeliveryByStockOutId(stockOutId);
        } catch (err: any) {
            setError(err.message || 'Error fetching delivery by stock out ID');
            showErrorToast(`Error fetching tracking delivery by stock out ID: ${err}, ${stockOutId}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const fetchTrackingDeliveryImagesByStockOutId = async (stockOutId: number) => {
        setLoading(true);
        setError(null);
        try {
            return await TrackingDeliveryService.getTrackingDeliveryImagesByStockOutId(stockOutId);
        } catch (err: any) {
            setError(err.message || 'Error fetching delivery images by stock out ID');
            showErrorToast(`Error fetching tracking delivery images by stock out ID: ${err}, ${stockOutId}`);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const createTrackingDelivery = async (data: Omit<TrackingDelivery, 'id' | 'created_time' | 'updated_time'>, images: any) => {
        try {
            setLoading(true);
            const newDeliveryRes = await TrackingDeliveryService.createTrackingDelivery(data, images);
            setLoading(false);
            return newDeliveryRes;
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
            showErrorToast(`Error creating tracking delivery: ${err}`);
            throw err;
        }
    };

    const updateTrackingDelivery = async (id: number, data: Omit<TrackingDelivery, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const updatedDeliveryRes = await TrackingDeliveryService.updateTrackingDelivery(id, data);
            return updatedDeliveryRes;
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
            showErrorToast(`Error updating tracking delivery: ${err}, ${id}`);
            throw err;
        }
    };

    const deleteTrackingDelivery = async (id: number) => {
        try {
            await TrackingDeliveryService.deleteTrackingDelivery(id);
            setTrackingDeliveries((prev) => prev.filter((delivery) => delivery.id !== id));
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
            showErrorToast(`Error deleting tracking delivery: ${err}, ${id}`);
        }
    };

    useEffect(() => {
        fetchTrackingDeliveries();
    }, []);

    return {
        trackingDeliveries,
        loading,
        error,
        fetchTrackingDeliveries,
        fetchTrackingDeliveryById,
        fetchTrackingDeliveryByStockOutId,
        fetchTrackingDeliveryImagesByStockOutId,
        createTrackingDelivery,
        updateTrackingDelivery,
        deleteTrackingDelivery,
    };
};
