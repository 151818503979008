import * as Headless from '@headlessui/react'
import React, { forwardRef } from 'react'

// Define the type for the component props
interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    href: string;
}

// Link component
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
    { href, ...props },
    ref
) {
    return (
        <Headless.DataInteractive>
            <a href={href} ref={ref} {...props} />
        </Headless.DataInteractive>
    )
})
