import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon, PencilIcon, TrashIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom'; // useParams to get route params
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Avatar } from '../../../../components/Avatar';
import Loading from '../../../../components/Loading';
import dateFormat from 'dateformat';
import { useStockOut } from '../../../../hooks/useStockOut';
import { StockOut } from '../../../../models/StockOut';
import { useStockOutItems } from '../../../../hooks/useStockOutItem';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { StockOutItem } from '../../../../models/StockOutItem';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/Table';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '../../../../components/Dialog';
import { Input } from '../../../../components/Input';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';

// Utility function to format date to yyyy-MM-dd based on local time
const formatDate = (isoString: string): string => {
    const date = new Date(isoString);

    // Get the year, month, and day using local time
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day

    return `${year}-${month}-${day}`; // Format as yyyy-MM-dd
};

const ViewStockOutIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [stockOut, setStockOut] = useState<StockOut | null>(null);
    const { fetchStockOutById, loading, error } = useStockOut();
    const [stockOutItems, setStockOutItems] = useState<StockOutItem[]>([]);
    const { fetchStockOutItemByStockOutId, updateStockOutItem, deleteStockOutItem } = useStockOutItems();

    const [isOpen, setIsOpen] = useState(false); // Dialog open state
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // Dialog open state
    const [editForm, setEditForm] = useState<Partial<StockOutItem>>({}); // Form state for item editing
    const [editingItemId, setEditingItemId] = useState<number | null>(null); // Track the item being edited
    const [selectedStockOutItemId, setSelectedStockOutItemId] = useState<string | null>(null); // ID of the user to be deleted
    const [isDeleting, setIsDeleting] = useState(false); // Loading state for deletion  

    useEffect(() => {
        document.title = "View Stock Out";
    }, []);

    const fetchStockOutDetails = async () => {
        if (!id) return; // Ensure ID is valid before making the request

        try {
            const fetchedStockOut = await fetchStockOutById(Number(id));
            setStockOut(fetchedStockOut);
            const fetchedStockOutItems = await fetchStockOutItemByStockOutId(Number(id));
            setStockOutItems(fetchedStockOutItems);
        } catch (err) {
            showErrorToast('Failed to fetch stock out & items details.');
        }
    };

    useEffect(() => {
        fetchStockOutDetails();
    }, [id]);

    const openEditDialog = (item: StockOutItem) => {
        setEditingItemId(Number(item.id));
        setEditForm({
            product_item_id: item.product_item_id,
            item_no: item.item_no,
            quantity: item.quantity,
            batch_no: item.batch_no,
            expiry_date: formatDate(item.expiry_date!),
            stock_out_id: item.stock_out_id,
        });
        setIsOpen(true);
    };

    const handleEditFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    };

    const handleEditSubmit = async () => {
        if (editingItemId !== null) {
            showLoadingToast(`Updating ${editForm.item_no}...`, 100);
            const res = await updateStockOutItem(editingItemId, {
                ...editForm
            } as StockOutItem);

            if (res && res.dbsuccess) {
                showSuccessToast(`${editForm.item_no} updated successfully`);
                setStockOutItems((prevItems) =>
                    prevItems.map((item) =>
                        Number(item.id) === editingItemId ? { ...item, ...editForm } : item
                    )
                );
                setIsOpen(false); // Close dialog
            } else {
                showErrorToast(`Failed updating stock-in item data`);
            }
        }
    };

    // Open the delete dialog with the selected user's ID
    const handleOpenDeleteDialog = (stockOutId: string) => {
        setSelectedStockOutItemId(stockOutId);
        setIsDeleteDialogOpen(true);
    };

    // Handle delete user
    const handleDelete = async () => {
        if (!selectedStockOutItemId) return;

        setIsDeleting(true);
        try {
            await deleteStockOutItem(Number(selectedStockOutItemId)); // Call the delete API
            fetchStockOutDetails();
            setIsDeleteDialogOpen(false);
        } catch (error) {
            console.error('Failed to delete stock-out item data:', error);
            showErrorToast(`Failed deleting stock-out item data: ${error}`);
        } finally {
            setIsDeleting(false);
        }
    };

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!stockOut) return <p className='py-16 text-center'>Stock Out not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/stocks/stockout" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Stock Out
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={stockOut.reference_no![0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{stockOut.reference_no}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span className='pr-1 text-zinc-400'>Created on</span><span>{dateFormat(stockOut.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button outline onClick={() => navigate(`/gp/stocks/stockout/add/items/${stockOut.id}`)}>Add Items</Button>
                        <Button onClick={() => navigate(`/gp/stocks/stockout/edit/${stockOut.id}`)}>Edit</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>Stock Out Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Reference</DescriptionTerm>
                    <DescriptionDetails>{stockOut.reference_no!}</DescriptionDetails>
                    <DescriptionTerm>Customer</DescriptionTerm>
                    <DescriptionDetails>{stockOut.customer_fullname || '-'}</DescriptionDetails>
                    <DescriptionTerm>Customer Account Code</DescriptionTerm>
                    <DescriptionDetails>{stockOut.customer_account_code || '-'}</DescriptionDetails>
                    <DescriptionTerm>Invoice Number</DescriptionTerm>
                    <DescriptionDetails>{stockOut.invoice_no || '-'}</DescriptionDetails>
                    <DescriptionTerm>Logistic</DescriptionTerm>
                    <DescriptionDetails>{stockOut.logistic_fullname || '-'}</DescriptionDetails>
                    <DescriptionTerm>Issue Date</DescriptionTerm>
                    <DescriptionDetails>{dateFormat(stockOut.issue_date?.toString(), "mmmm dd, yyyy")}</DescriptionDetails>
                    <DescriptionTerm>Issue Time</DescriptionTerm>
                    <DescriptionDetails>{stockOut.issue_time || '-'}</DescriptionDetails>
                    <DescriptionTerm>Issuer</DescriptionTerm>
                    <DescriptionDetails>{stockOut.issuer_fullname || '-'}</DescriptionDetails>
                    <DescriptionTerm>Warehouse</DescriptionTerm>
                    <DescriptionDetails>{stockOut.warehouse_name || '-'}</DescriptionDetails>

                </DescriptionList>
            </div>
            <Divider className='py-4' />
            <div>
                <Subheading>Stock Out Items List</Subheading>
                <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>Product Item No</TableHeader>
                            <TableHeader>Description</TableHeader>
                            <TableHeader>Quantity</TableHeader>
                            <TableHeader>UOM</TableHeader>
                            <TableHeader>Packing</TableHeader>
                            <TableHeader>Batch No</TableHeader>
                            <TableHeader>Expiry Date</TableHeader>
                            <TableHeader>Actions</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(stockOutItems.length > 0) ? stockOutItems.map((stockOutItem) => (
                            <TableRow key={stockOutItem.id} title={`${stockOutItem.item_no}`}>
                                <TableCell>{stockOutItem.item_no}</TableCell>
                                <TableCell>{stockOutItem.description}</TableCell>
                                <TableCell className="text-zinc-500">{stockOutItem.quantity}</TableCell>
                                <TableCell className="text-zinc-500">{stockOutItem.uom}</TableCell>
                                <TableCell className="text-zinc-500">{stockOutItem.packing}</TableCell>
                                <TableCell className="text-zinc-500">{stockOutItem.batch_no}</TableCell>
                                <TableCell className="text-zinc-400">{dateFormat(stockOutItem.expiry_date?.toString(), "mmmm dd, yyyy")}</TableCell>
                                <TableCell className="py-5 flex gap-4">
                                    <a onClick={() => openEditDialog(stockOutItem)} className="text-zinc-600 hover:text-zinc-900">
                                        <PencilIcon className='h-5 w-5' />
                                    </a>
                                    <a onClick={() => handleOpenDeleteDialog(stockOutItem.id.toString())} className="text-zinc-600 hover:text-zinc-900">
                                        <TrashIcon className='h-5 w-5' />
                                    </a>
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            <TableCell colSpan={9} className="text-center py-8">No items found.</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </div>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Edit Stock Out Item</DialogTitle>
                <DialogDescription>
                    You can edit the quantity, batch no, and expiry date of the stock-in item.
                </DialogDescription>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Quantity</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Quantity"
                                    type="number"
                                    name="quantity"
                                    value={editForm.quantity || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Batch Number</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Batch No"
                                    type="text"
                                    name="batch_no"
                                    value={editForm.batch_no || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Expiry Date</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Edit Expiry Date"
                                    type="date"
                                    name="expiry_date"
                                    value={editForm.expiry_date || ''}
                                    onChange={handleEditFormChange}
                                />
                            </div>
                        </section>
                    </div>
                </div>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleEditSubmit}>Save</Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                <DialogTitle>Delete Stock Out Item</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this stock out item? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsDeleteDialogOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ViewStockOutIdPage;
