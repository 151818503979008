// services/StockOutItemService.ts
import axios from 'axios';
import { StockOutItem } from '../models/StockOutItem';

const API_URL = process.env.REACT_APP_SERVER_URL + "/stockoutitem";

const StockOutItemService = {
    // Fetch all stock_out_items
    getAllStockOutItems: async (): Promise<StockOutItem[]> => {
        const response = await axios.get(`${API_URL}/getAllStockOutItems`);
        return response.data;
    },

    // Fetch stock_out_item by ID
    getStockOutItemById: async (id: number): Promise<StockOutItem> => {
        const response = await axios.get(`${API_URL}/getStockOutItemById/${id}`);
        return response.data;
    },

    // Fetch stock_out_item by ID
    getStockOutItemByStockOutId: async (id: number): Promise<StockOutItem[]> => {
        const response = await axios.get(`${API_URL}/getStockOutItemByStockOutId/${id}`);
        return response.data;
    },

    // Create new stock_out_item
    createStockOutItem: async (data: Omit<StockOutItem, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, data);
        return response.data;
    },

    // Update stock_out_item by ID
    updateStockOutItem: async (id: number, data: Omit<StockOutItem, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/edit/${id}`, data);
        return response.data;
    },

    // Delete stock_out_item by ID
    deleteStockOutItem: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    },
};

export default StockOutItemService;
