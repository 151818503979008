// services/StockInItemService.ts
import axios from 'axios';
import { StockInItem } from '../models/StockInItem';

const API_URL = process.env.REACT_APP_SERVER_URL + "/stockinitem";

const StockInItemService = {
    // Fetch all stock_in_items
    getAllStockInItems: async (): Promise<StockInItem[]> => {
        const response = await axios.get(`${API_URL}/getAllStockInItems`);
        return response.data;
    },

    // Fetch stock_in_item by ID
    getStockInItemById: async (id: number): Promise<StockInItem> => {
        const response = await axios.get(`${API_URL}/getStockInItemById/${id}`);
        return response.data;
    },

    // Fetch stock_in_item by ID
    getStockInItemByStockInId: async (id: number): Promise<StockInItem[]> => {
        const response = await axios.get(`${API_URL}/getStockInItemByStockInId/${id}`);
        return response.data;
    },

    // Create new stock_in_item
    createStockInItem: async (data: Omit<StockInItem, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, data);
        return response.data;
    },

    // Update stock_in_item by ID
    updateStockInItem: async (id: number, data: Omit<StockInItem, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/edit/${id}`, data);
        return response.data;
    },

    // Delete stock_in_item by ID
    deleteStockInItem: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    },
};

export default StockInItemService;
