// hooks/useWarehouseProductItemCategory.ts
import { useState, useEffect, useCallback } from 'react';
import { WarehouseProductCategory } from '../models/WarehouseProductCategory';
import { WarehouseProductCategoryService } from '../services/warehouseProductCategoryService';

export const useWarehouseProductCategory = () => {
    const [categories, setCategories] = useState<WarehouseProductCategory[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchCategories = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await WarehouseProductCategoryService.getAllCategories();
            setCategories(data);
        } catch (err) {
            setError('Failed to fetch categories');
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchCategoryById = async (id: number) => {
        setLoading(true);
        setError(null);
        try {
            const data = await WarehouseProductCategoryService.getCategoryById(id);
            return data;
        } catch (err) {
            setError('Failed to fetch the category by ID.');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const addCategory = async (newCategory: Omit<WarehouseProductCategory, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const createdCategoryRes = await WarehouseProductCategoryService.createCategory(newCategory);
            return createdCategoryRes;
        } catch (err) {
            setError('Failed to add category');
            console.error(err);
        }
    };

    const updateCategory = async (id: number, updatedCategory: Omit<WarehouseProductCategory, 'id' | 'created_time' | 'updated_time'>) => {
        try {
            const updatedRes = await WarehouseProductCategoryService.updateCategory(id, updatedCategory);
            return updatedRes;
        } catch (err) {
            setError('Failed to update category');
            console.error(err);
        }
    };

    const deleteCategory = async (id: number) => {
        try {
            await WarehouseProductCategoryService.deleteCategory(id);
            setCategories(prev => prev.filter(cat => cat.id !== id));
        } catch (err) {
            setError('Failed to delete category');
            console.error(err);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    return {
        categories,
        loading,
        error,
        fetchCategories,
        fetchCategoryById,
        addCategory,
        updateCategory,
        deleteCategory
    };
};
