import React, { useContext, useEffect, useState } from 'react';
import { EllipsisVerticalIcon, EyeIcon, MagnifyingGlassIcon, PencilIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/Dropdown';
import { Badge } from '../../../components/Badge';
import { Link } from '../../../components/Link';
import { Button } from '../../../components/Button';
import { Heading } from '../../../components/Heading';
import { Input, InputGroup } from '../../../components/Input';
import { Divider } from '../../../components/Divider';
import { Avatar } from '../../../components/Avatar';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination'; // Import the Pagination component
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../components/Dialog';
import { useSetupBrand } from '../../../hooks/useSetupBrand';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import dateFormat from 'dateformat';
import { UserContext } from '../../../context/userContext';
import { SetupBrand } from '../../../models/SetupBrand';

const SetupBrandPage: React.FC = () => {
    const { currentUser } = useContext(UserContext); // Get current user info
    const { loading, error, deleteBrand, fetchBrands, brands } = useSetupBrand();
    const [searchQuery, setSearchQuery] = useState<string>(''); // State to store the search query
    const [isOpen, setIsOpen] = useState(false); // Dialog open state
    const [isDeleting, setIsDeleting] = useState(false); // Loading state for deletion
    const [selectedBrandId, setSelectedBrandId] = useState<string | null>(null); // ID of the brand to be deleted
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Brands";
    }, []);

    // Filter brands based on the search query
    const filteredBrand = brands.filter((brand) =>
        brand.name!.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleOpenDeleteDialog = (brandId: string) => {
        setSelectedBrandId(brandId);
        setIsOpen(true);
    };

    const handleDelete = async () => {
        if (!selectedBrandId) return;

        setIsDeleting(true);
        try {
            await deleteBrand(Number(selectedBrandId));
            fetchBrands();
            setIsOpen(false);
        } catch (error) {
            console.error('Failed to delete brand:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Brands</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder="Search brand&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Button onClick={() => navigate('/gp/inventories/brands/add')}>Create new brand</Button>
            </div>

            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Brand Name</TableHeader>
                                    <TableHeader>Created on</TableHeader>
                                    <TableHeader className="text-center">Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(filteredBrand.length > 0) ? filteredBrand.map((brand, index) => (
                                    <TableRow key={brand.id} title={`${brand.name}`}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{brand.name}</TableCell>
                                        <TableCell className="text-zinc-500">{dateFormat(brand.created_time?.toString(), "mmm dd, yyyy")}</TableCell>
                                        <TableCell className="py-5 flex gap-4 text-zinc-500 justify-center">
                                            <a onClick={() => navigate(`/gp/inventories/brands/edit/${brand.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <PencilIcon className='h-5 w-5' />
                                            </a>
                                            <a onClick={() => navigate(`/gp/inventories/brands/view/${brand.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <EyeIcon className='h-5 w-5' />
                                            </a>
                                            <Dropdown>
                                                <DropdownButton plain aria-label="More options" className='h-5 w-5' >
                                                    <EllipsisVerticalIcon />
                                                </DropdownButton>
                                                <DropdownMenu anchor="bottom end">
                                                    <DropdownItem onClick={() => handleOpenDeleteDialog(brand.id.toString())}>Delete</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={9} className="text-center py-8">No brands found.</TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Delete Brand</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this brand? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SetupBrandPage;
