// services/TrackingDeliveryService.ts
import axios from 'axios';
import { TrackingDelivery } from '../models/TrackingDelivery'; // Make sure to define the TrackingDelivery model

const API_URL = process.env.REACT_APP_SERVER_URL + "/trackingdelivery";

const TrackingDeliveryService = {
    // Fetch all tracking deliveries
    getAllTrackingDeliveries: async (): Promise<TrackingDelivery[]> => {
        const response = await axios.get(`${API_URL}/getAllTrackingDeliveries`);
        return response.data;
    },

    // Fetch tracking delivery by ID
    getTrackingDeliveryById: async (id: number): Promise<TrackingDelivery> => {
        const response = await axios.get(`${API_URL}/getTrackingDeliveryById/${id}`);
        return response.data;
    },

    // Fetch tracking delivery by stock out ID
    getTrackingDeliveryByStockOutId: async (stockOutId: number): Promise<TrackingDelivery> => {
        const response = await axios.get(`${API_URL}/getTrackingDeliveryByStockOutId/${stockOutId}`);
        return response.data;
    },

    // Fetch tracking delivery by stock out ID
    getTrackingDeliveryImagesByStockOutId: async (stockOutId: number) => {
        const response = await axios.get(`${API_URL}/getTrackingDeliveryImagesByStockOutId/${stockOutId}`);
        return response.data;
    },

    // Create a new tracking delivery
    createTrackingDelivery: async (trackingDeliveryData: Omit<TrackingDelivery, 'id' | 'created_time' | 'updated_time'>, images: any) => {
        try {
            const formData = new FormData();

            formData.append('stock_out_id', trackingDeliveryData.stock_out_id!.toString());
            formData.append('driver_name', trackingDeliveryData.driver_name || '');
            formData.append('recipient_name', trackingDeliveryData.recipient_name || '');

            if (images.proven_image) formData.append('proven_image', images.proven_image);

            // Send the form data using axios
            const response = await axios.post(`${API_URL}/add`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        } catch (error) {
            console.error('Error creating tracking delivery data:', error);
            throw error;
        }
    },

    // Update an existing tracking delivery by ID
    updateTrackingDelivery: async (id: number, trackingDeliveryData: Omit<TrackingDelivery, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/update/${id}`, trackingDeliveryData);
        return response.data;
    },

    // Delete a tracking delivery by ID
    deleteTrackingDelivery: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};

export default TrackingDeliveryService;
