import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Select } from '../../../../components/Select';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import Loading from '../../../../components/Loading';
import { useSetupBrand } from '../../../../hooks/useSetupBrand';
import { useWarehouses } from '../../../../hooks/useWarehouse';

interface AddSetupBrandPage {
    name: string;
}

const SetupAddBrandPage = () => {
    const navigate = useNavigate();

    const [brand, setBrand] = useState<AddSetupBrandPage>({
        name: '',
    });
    const { createBrand } = useSetupBrand();
    const { warehouses } = useWarehouses();
    const filteredWarehouses = warehouses.filter(warehouse => warehouse.hq !== '1');


    useEffect(() => {
        document.title = "Add Brand";
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setBrand(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await createBrand(brand);
            showLoadingToast(`Creating ${brand.name} ...`, 100);
            setTimeout(() => {
                navigate('/gp/inventories/brands');
                showSuccessToast(`${brand.name} added successfully`);
            }, 1000);
        } catch (err) {
            showErrorToast(`Failed to add brand: ${err}`);
        }
    };

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Brand</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className='ml-3'>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Brand Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Differentiates a company’s products or services.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Brand Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Name"
                                        name="name"
                                        value={brand.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button type="submit">Save</Button>
            </div>
        </form>
    );
};

export default SetupAddBrandPage;
