// services/TrackingVerifyService.ts
import axios from 'axios';
import { TrackingVerify } from '../models/TrackingVerify'; // Define the TrackingVerify model

const API_URL = `${process.env.REACT_APP_SERVER_URL}/trackingverify`;

const TrackingVerifyService = {
    // Fetch all tracking verifications
    getAllTrackingVerifies: async (): Promise<TrackingVerify[]> => {
        const response = await axios.get(`${API_URL}/getAllTrackingVerifys`);
        return response.data;
    },

    // Fetch tracking verification by ID
    getTrackingVerifyById: async (id: number): Promise<TrackingVerify> => {
        const response = await axios.get(`${API_URL}/getTrackingVerifyById/${id}`);
        return response.data;
    },

    // Fetch tracking verification by stock out ID
    getTrackingVerifyByStockOutId: async (stockOutId: number): Promise<TrackingVerify[]> => {
        const response = await axios.get(`${API_URL}/getByStockOutId/${stockOutId}`);
        return response.data;
    },

    // Create a new tracking verification
    createTrackingVerify: async (
        trackingVerifyData: Omit<TrackingVerify, 'id' | 'created_time' | 'updated_time'>
    ): Promise<TrackingVerify> => {
        const response = await axios.post(`${API_URL}/add`, trackingVerifyData);
        return response.data;
    },

    // Update an existing tracking verification by ID
    updateTrackingVerify: async (
        id: number,
        trackingVerifyData: Omit<TrackingVerify, 'id' | 'created_time' | 'updated_time'>
    ): Promise<TrackingVerify> => {
        const response = await axios.put(`${API_URL}/update/${id}`, trackingVerifyData);
        return response.data;
    },

    // Delete a tracking verification by ID
    deleteTrackingVerify: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};

export default TrackingVerifyService;
