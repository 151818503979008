import React, { useContext, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon, MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/Dropdown';
import { Badge } from '../../../components/Badge';
import { Link } from '../../../components/Link';
import { Button } from '../../../components/Button';
import { Heading, Subheading } from '../../../components/Heading';
import { Input, InputGroup } from '../../../components/Input';
import { Divider } from '../../../components/Divider';
import { UserService } from '../../../services/userService';
import { Avatar } from '../../../components/Avatar';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../components/Dialog';
import { useStockOut } from '../../../hooks/useStockOut';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import dateFormat from 'dateformat';
import { useTrackingPickup } from '../../../hooks/useTrackingPickup';
import showLoadingToast from '../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../components/Toasts/ShowErrorToast'; // Import showErrorToast
import { useTrackingVerify } from '../../../hooks/useTrackingVerify';
import { StockOut } from '../../../models/StockOut';
import { UserContext } from '../../../context/userContext';
import { Text } from '../../../components/Text';
import { useWarehouses } from '../../../hooks/useWarehouse';

// src/models/TrackingPickup.ts
export interface AddTrackingPickup {
    driver_name: string | null;
    vehicle_no: string | null;
    carton: string | null;
    cold_item: string | null;
    stock_out_id: number | null;
}

// src/models/TrackingPickup.ts
export interface AddTrackingVerify {
    verify_name: string | null;
    stock_out_id: number | null;
}

const PickupStockOutPage: React.FC = () => {
    const { currentUser } = useContext(UserContext);
    const { pickupStockOuts, loading, error, fetchAllFilteredPickUpStockOuts } = useStockOut();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isPickupDialogOpen, setIsPickupDialogOpen] = useState(false);
    const [isVerifyDialogOpen, setIsVerifyDialogOpen] = useState(false);
    const [selectedStockOutRefNo, setSelectedStockOutRefNo] = useState<string | null>(null);
    const [selectedStockOutId, setSelectedStockOutId] = useState<string | null>(null);
    const [selectedCurrentStockOut, setSelectedCurrentStockOut] = useState<StockOut | null>(null);
    const navigate = useNavigate();
    const { createTrackingPickup, updateTrackingPickup, fetchTrackingPickupByStockOutId } = useTrackingPickup();
    const { createTrackingVerify } = useTrackingVerify();
    const { warehouses } = useWarehouses();
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [filterWarehouseId, setFilterWarehouseId] = useState('');

    const [trackingPickup, setTrackingPickup] = useState<AddTrackingPickup>({
        driver_name: '',
        vehicle_no: '',
        carton: '',
        cold_item: '',
        stock_out_id: Number(0),
    });

    const [trackingVerify, setTrackingVerify] = useState<AddTrackingVerify>({
        verify_name: '',
        stock_out_id: Number(0),
    });

    const [currentDate, setCurrentDate] = useState<string>('');
    const [currentTime, setCurrentTime] = useState<string>('');

    useEffect(() => {
        document.title = "Pickups Stock Out";
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const formattedDate = now.toISOString().split('T')[0]; // This gives 'YYYY-MM-DD'
            setCurrentDate(formattedDate);
            setCurrentTime(now.toTimeString().substring(0, 8));
        }, 1000);

        // Set initial date and time
        const now = new Date();
        const formattedDate = now.toISOString().split('T')[0]; // This gives 'YYYY-MM-DD'
        setCurrentDate(formattedDate);
        setCurrentTime(now.toTimeString().substring(0, 8));

        return () => clearInterval(interval);
    }, []);

    const filteredStockOut = Array.isArray(pickupStockOuts)
        ? pickupStockOuts.filter((stockOut) => {
            const matchesSearch = stockOut.invoice_no?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                stockOut.reference_no?.toLowerCase().includes(searchQuery.toLowerCase())
            const matchesStartDate = !filterStartDate || new Date(stockOut.created_time) >= new Date(filterStartDate);
            const matchesEndDate = !filterEndDate || new Date(stockOut.created_time) <= new Date(filterEndDate);
            const matchesYear = !filterYear || new Date(stockOut.created_time).getFullYear().toString() === filterYear;
            const matchesWarehouse = !filterWarehouseId || stockOut.warehouse_id === Number(filterWarehouseId);

            return matchesSearch && matchesStartDate && matchesEndDate && matchesYear && matchesWarehouse;
        }) : [];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setTrackingPickup(prev => ({ ...prev, [name]: value }));
        setTrackingVerify(prev => ({ ...prev, [name]: value }));
    };

    const handlePickUpDialog = async (currentStockOut: StockOut) => {
        setSelectedStockOutRefNo(currentStockOut.reference_no);
        setSelectedStockOutId(currentStockOut.id.toString());
        setSelectedCurrentStockOut(currentStockOut);
        setIsPickupDialogOpen(true);
        setTrackingPickup(prev => ({ ...prev, stock_out_id: Number(currentStockOut.id) }));

        if (currentStockOut.pickup_exist == '1') {
            // Fetch existing tracking pickup data by stock out ID
            const existingPickup = await fetchTrackingPickupDetailsByStockOutId(Number(currentStockOut.id));
            setTrackingPickup(existingPickup!);
        } else {
            setTrackingPickup({ driver_name: '', vehicle_no: '', carton: '', cold_item: '', stock_out_id: Number(currentStockOut.id) });
        }
    };

    const fetchTrackingPickupDetailsByStockOutId = async (id: number): Promise<AddTrackingPickup | null> => {
        try {
            const pickupData = await fetchTrackingPickupByStockOutId(id);
            return pickupData ? {
                driver_name: pickupData.driver_name,
                vehicle_no: pickupData.vehicle_no,
                carton: pickupData.carton,
                cold_item: pickupData.cold_item,
                stock_out_id: pickupData.stock_out_id
            } : null;
        } catch (error) {
            console.error('Error fetching tracking pickup:', error);
            showErrorToast(`Error fetching tracking pickup: ${error}`);
            return null;
        }
    };

    const handlePickupSubmit = async () => {
        if (!trackingPickup.carton || !trackingPickup.cold_item || !trackingPickup.driver_name || !trackingPickup.vehicle_no || !trackingPickup.stock_out_id) {
            showErrorToast("Please fill in all fields before submit.");
            return;
        }
        try {
            showLoadingToast(`Saving pickup data...`, 100);

            if (selectedCurrentStockOut?.pickup_exist == '1') {
                // If existingPickup is found, update the existing entry
                await updateTrackingPickup(Number(selectedCurrentStockOut.pickup_id), trackingPickup);
                showSuccessToast(`Pickup data updated successfully.`);
            } else {
                // If no existing pickup is found, create a new entry
                await createTrackingPickup(trackingPickup);
                showSuccessToast(`Pickup data saved successfully.`);
            }

            // Reset the dialog state
            setIsPickupDialogOpen(false);
            setTrackingPickup({ driver_name: '', vehicle_no: '', carton: '', cold_item: '', stock_out_id: Number(0) });
            fetchAllFilteredPickUpStockOuts();
        } catch (error) {
            console.error('Error handling submit:', error);
            showErrorToast(`Error saving pickup data: ${error}`);
        }
    };

    const hanldleVerifyDialog = async (StockOutRefNo: string, StockOutId: string) => {
        setSelectedStockOutRefNo(StockOutRefNo);
        setSelectedStockOutId(StockOutId);
        setIsVerifyDialogOpen(true);
        setTrackingVerify(prev => ({ ...prev, stock_out_id: Number(StockOutId) }));
    };

    const handleVerifySubmit = async () => {
        if (!trackingVerify.stock_out_id || !trackingVerify.verify_name) {
            showErrorToast("Please fill in all fields before submit.");
            return;
        }
        try {
            showLoadingToast(`Saving verification data...`, 100);
            await createTrackingVerify(trackingVerify);
            showSuccessToast(`Verification data saved successfully.`);
            setIsVerifyDialogOpen(false);
            setTrackingVerify({ verify_name: '', stock_out_id: Number(0) });
            fetchAllFilteredPickUpStockOuts();
        } catch (error) {
            console.error('Error handling verify submit:', error);
            showErrorToast(`Error saving verification data: ${error}`);
        }
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Pick Up</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder="Search pick up&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
            </div>

            {/* Date, Year, and Warehouse Filters */}
            <div className="mt-4 px-6 py-4 bg-zinc-50 ring-1 ring-zinc-200 rounded-md">
                <div className='flex flex-wrap items-end justify-between gap-4'>
                    <Subheading>Filters & Sorting</Subheading>
                    {(isFilterOpen) ? <ChevronUpIcon onClick={() => setIsFilterOpen(false)} className='w-5 h-5' /> : <ChevronDownIcon onClick={() => setIsFilterOpen(true)} className='w-5 h-5' />}
                </div>
                {(isFilterOpen) && <div className='mt-4 grid gap-4 sm:grid-cols-1'>
                    <div>
                        <Text>From Date</Text>
                        <Input
                            type="date"
                            value={filterStartDate}
                            onChange={(e) => setFilterStartDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>To Date</Text>
                        <Input
                            type="date"
                            value={filterEndDate}
                            onChange={(e) => setFilterEndDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>By Year</Text>
                        <Input
                            type="number"
                            placeholder="Year"
                            value={filterYear}
                            onChange={(e) => setFilterYear(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button onClick={() => {
                            setFilterStartDate('');
                            setFilterEndDate('');
                            setFilterYear('');
                            setFilterWarehouseId('');
                        }}>
                            Reset Filter
                        </Button>
                    </div>
                    {(currentUser?.role.toString() === "1") && (
                        <div className='max-w-full'>
                            <Text>By Warehouse</Text>
                            <Dropdown>
                                <DropdownButton outline>
                                    {filterWarehouseId ? warehouses?.find(w => w.id?.toString() === filterWarehouseId)?.warehouse_name : 'All Warehouses'}
                                    <ChevronDownIcon />
                                </DropdownButton>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setFilterWarehouseId('')}>All Warehouses</DropdownItem>
                                    {warehouses?.map((warehouse) => (
                                        <DropdownItem key={warehouse.id} onClick={() => setFilterWarehouseId(warehouse.id?.toString()!)}>
                                            {warehouse.warehouse_name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    )}
                </div>}
            </div>

            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Reference No</TableHeader>
                                    <TableHeader>Customer</TableHeader>
                                    <TableHeader>Invoice No</TableHeader>
                                    <TableHeader>Date</TableHeader>
                                    <TableHeader>Status</TableHeader>
                                    <TableHeader className="text-center">Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(filteredStockOut.length > 0) ? filteredStockOut.map((stockOut, index) => (
                                    <TableRow key={stockOut.id} title={`${stockOut.reference_no}`}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{stockOut.reference_no}</TableCell>
                                        <TableCell>{stockOut.customer_fullname}</TableCell>
                                        <TableCell className="text-zinc-500">{stockOut.invoice_no}</TableCell>
                                        <TableCell className="text-zinc-500">{dateFormat(stockOut.date?.toString(), "mmmm dd, yyyy")}</TableCell>
                                        <TableCell className="text-zinc-500">{stockOut.status}</TableCell>
                                        <TableCell className="text-center flex gap-4">
                                            <a onClick={() => handlePickUpDialog(stockOut)} className="text-zinc-700 hover:text-zinc-900">
                                                Pick Up<span className="sr-only">, {stockOut.reference_no}</span>
                                            </a>
                                            <a onClick={() => hanldleVerifyDialog(stockOut.reference_no!, stockOut.id.toString())} className="text-zinc-700 hover:text-zinc-900">
                                                Completed <span className="sr-only">, {stockOut.reference_no}</span>
                                            </a>
                                            {(stockOut.pickup_exist == '1') && <a onClick={() => navigate(`/gp/tracking/pickups/view/${stockOut.pickup_id}`)} className="text-zinc-700 hover:text-zinc-900">
                                                View <span className="sr-only">, {stockOut.reference_no}</span>
                                            </a>}
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={9} className="text-center py-8">No stock out found.</TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>

            {/* Pick Up Information Dialog */}
            <Dialog open={isPickupDialogOpen} onClose={() => setIsPickupDialogOpen(false)}>
                <DialogTitle>Pick up</DialogTitle>
                <DialogDescription>
                    You can fill the pick-up information.
                </DialogDescription>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Driver Name</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Driver Name"
                                    type="text"
                                    name="driver_name"
                                    value={trackingPickup.driver_name!}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Vehicle Number</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Vehicle Number"
                                    type="text"
                                    name="vehicle_no"
                                    value={trackingPickup.vehicle_no!}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Carton</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Carton"
                                    type="text"
                                    name="carton"
                                    value={trackingPickup.carton!}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Cold Item</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Cold Item"
                                    type="text"
                                    name="cold_item"
                                    value={trackingPickup.cold_item!}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Date</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Date"
                                    type="date"
                                    name="date"
                                    value={currentDate}
                                    readOnly // This is read-only; remove if you want to allow editing
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Time</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="time"
                                    type="time"
                                    name="time"
                                    value={currentTime}
                                    readOnly // This is read-only; remove if you want to allow editing
                                />
                            </div>
                        </section>
                    </div>
                </div>
                <DialogActions>
                    <Button plain onClick={() => setIsPickupDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handlePickupSubmit} disabled={(!trackingPickup.carton || !trackingPickup.cold_item || !trackingPickup.driver_name || !trackingPickup.vehicle_no || !trackingPickup.stock_out_id)}>Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isVerifyDialogOpen} onClose={() => setIsVerifyDialogOpen(false)}>
                <DialogTitle>Verify</DialogTitle>
                <DialogDescription>
                    You can fill the stock-out verification information.
                </DialogDescription>
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 mt-4">
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Verify By</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="verify_name"
                                    type="text"
                                    name="verify_name"
                                    value={trackingVerify.verify_name!}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Date</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="Date"
                                    type="date"
                                    name="date"
                                    value={currentDate}
                                    readOnly // This is read-only; remove if you want to allow editing
                                />
                            </div>
                        </section>
                    </div>
                    <div className="sm:col-span-full">
                        <section>
                            <div className="space-y-1">
                                <Subheading>Time</Subheading>
                            </div>
                            <div className="mt-2">
                                <Input
                                    aria-label="time"
                                    type="time"
                                    name="time"
                                    value={currentTime}
                                    readOnly // This is read-only; remove if you want to allow editing
                                />
                            </div>
                        </section>
                    </div>
                </div>
                <DialogActions>
                    <Button plain onClick={() => setIsVerifyDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleVerifySubmit} disabled={(!trackingVerify.stock_out_id || !trackingVerify.verify_name)}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PickupStockOutPage;
