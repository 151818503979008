// services/WarehouseProductItemBatchNoService.ts
import axios from 'axios';
import { WarehouseProductItemBatchNo } from '../models/WarehouseProductItemBatchNo';

const API_URL = process.env.REACT_APP_SERVER_URL + "/warehouseproductitembatchno";

const WarehouseProductItemBatchNoService = {
    getAllBatches: async (): Promise<WarehouseProductItemBatchNo[]> => {
        const response = await axios.get(`${API_URL}/getAllBatchNo`);
        return response.data;
    },

    getBatchesByProductItemId: async (productItemId: number) => {
        const response = await axios.get(`${API_URL}/getBatchNoByProductId/${productItemId}`);
        return response.data;
    },

    getBatchesByWarehouseId: async (warehouseId: number) => {
        const response = await axios.get(`${API_URL}/getBatchNoByWarehouseId/${warehouseId}`);
        return response.data;
    },

    getBatchesByProductIdAndWarehouseId: async (productItemId: number, warehouseId: number) => {
        const response = await axios.get(`${API_URL}/product/${productItemId}/warehouse/${warehouseId}`);
        return response.data;
    },

    getBatchById: async (id: number): Promise<WarehouseProductItemBatchNo> => {
        const response = await axios.get(`${API_URL}/getBatchNoById/${id}`);
        return response.data;
    },

    createBatch: async (batchData: Omit<WarehouseProductItemBatchNo, 'id' | 'created_time' | 'updated_time'>): Promise<WarehouseProductItemBatchNo> => {
        const response = await axios.post(`${API_URL}/add`, batchData);
        return response.data;
    },

    updateBatch: async (id: number, batchData: Omit<WarehouseProductItemBatchNo, 'id' | 'created_time' | 'updated_time'>): Promise<WarehouseProductItemBatchNo> => {
        const response = await axios.put(`${API_URL}/update/${id}`, batchData);
        return response.data;
    },

    deleteBatch: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};

export default WarehouseProductItemBatchNoService;
