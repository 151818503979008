// services/StockAdjustService.ts
import axios from 'axios';
import { StockAdjust } from '../models/StockAdjust';

const API_URL = process.env.REACT_APP_SERVER_URL + "/stockadjust";

const StockAdjustService = {
    getAllStockAdjusts: async () => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getAllStockAdjusts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    getAllStockAdjustsByWarehouseId: async (warehouseId: string) => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getAllStockAdjustsByWarehouseId/${warehouseId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    getStockAdjustById: async (id: number): Promise<StockAdjust> => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.get(`${API_URL}/getStockAdjustById/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    createStockAdjust: async (StockAdjustData: Omit<StockAdjust, 'id' | 'created_time' | 'updated_time'>) => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.post(`${API_URL}/add`, StockAdjustData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    updateStockAdjust: async (id: number, StockAdjustData: Omit<StockAdjust, 'id' | 'created_time' | 'updated_time'>) => {
        const token = localStorage.getItem('gpcwstoken_kw');
        const response = await axios.put(`${API_URL}/update/${id}`, StockAdjustData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    },

    deleteStockAdjust: async (id: number): Promise<void> => {
        const token = localStorage.getItem('gpcwstoken_kw');
        await axios.delete(`${API_URL}/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
};

export default StockAdjustService;
