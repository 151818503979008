import React, { useContext, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon, EyeIcon, MagnifyingGlassIcon, PencilIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/Dropdown';
import { Badge } from '../../../components/Badge';
import { Link } from '../../../components/Link';
import { Button } from '../../../components/Button';
import { Heading, Subheading } from '../../../components/Heading';
import { Input, InputGroup } from '../../../components/Input';
import { Divider } from '../../../components/Divider';
import { UserService } from '../../../services/userService'; // Import the UserService
import { Avatar } from '../../../components/Avatar';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination'; // Import the Pagination component
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../components/Dialog';
import { useStockAdjust } from '../../../hooks/useStockAdjust';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import dateFormat from 'dateformat';
import { StockAdjust } from '../../../models/StockAdjust';
import { UserContext } from '../../../context/userContext';
import { useWarehouses } from '../../../hooks/useWarehouse';
import { Text } from '../../../components/Text';

const StockAdjustPage: React.FC = () => {
    const { currentUser } = useContext(UserContext); // Get current user info
    const {
        loading,
        error,
        stockAdjusts,
        totalStockAdjusts,
        fetchStockAdjusts,
        deleteStockAdjust,
    } = useStockAdjust();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage] = useState<number>(10);
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedStockAdjustId, setSelectedStockAdjustId] = useState<string | null>(null);
    const navigate = useNavigate();
    const { warehouses } = useWarehouses();
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [filterWarehouseId, setFilterWarehouseId] = useState('');

    useEffect(() => {
        document.title = "Stock Adjusts";
    }, []);

    // Filter stock adjusts based on the search query
    const filteredStockAdjust = Array.isArray(stockAdjusts)
        ? stockAdjusts?.filter((stockadjust) => {
            const matchesSearch = stockadjust.manual_no?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                stockadjust.reference_no?.toLowerCase().includes(searchQuery.toLowerCase())
            const matchesStartDate = !filterStartDate || new Date(stockadjust.created_time) >= new Date(filterStartDate);
            const matchesEndDate = !filterEndDate || new Date(stockadjust.created_time) <= new Date(filterEndDate);
            const matchesYear = !filterYear || new Date(stockadjust.created_time).getFullYear().toString() === filterYear;
            const matchesWarehouse = !filterWarehouseId || stockadjust.warehouse_id === Number(filterWarehouseId);

            return matchesSearch && matchesStartDate && matchesEndDate && matchesYear && matchesWarehouse;
        }) : [];

    // Open the delete dialog with the selected user's ID
    const handleOpenDeleteDialog = (StockAdjustId: string) => {
        setSelectedStockAdjustId(StockAdjustId);
        setIsOpen(true);
    };

    // Handle delete user
    const handleDelete = async () => {
        if (!selectedStockAdjustId) return;

        setIsDeleting(true);
        try {
            await deleteStockAdjust(Number(selectedStockAdjustId)); // Call the delete API
            fetchStockAdjusts(); // Refresh the user list
            setIsOpen(false);
        } catch (error) {
            console.error('Failed to delete Stock Adjust data:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Stock Adjust</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder="Search stock adjust&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Button onClick={() => navigate('/gp/stocks/stockadjust/add')}>Create new Stock-adjust</Button>
            </div>

            {/* Date, Year, and Warehouse Filters */}
            <div className="mt-4 px-6 py-4 bg-zinc-50 ring-1 ring-zinc-200 rounded-md">
                <div className='flex flex-wrap items-end justify-between gap-4'>
                    <Subheading>Filters & Sorting</Subheading>
                    {(isFilterOpen) ? <ChevronUpIcon onClick={() => setIsFilterOpen(false)} className='w-5 h-5' /> : <ChevronDownIcon onClick={() => setIsFilterOpen(true)} className='w-5 h-5' />}
                </div>
                {(isFilterOpen) && <div className='mt-4 grid gap-4 sm:grid-cols-1'>
                    <div>
                        <Text>From Date</Text>
                        <Input
                            type="date"
                            value={filterStartDate}
                            onChange={(e) => setFilterStartDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>To Date</Text>
                        <Input
                            type="date"
                            value={filterEndDate}
                            onChange={(e) => setFilterEndDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>By Year</Text>
                        <Input
                            type="number"
                            placeholder="Year"
                            value={filterYear}
                            onChange={(e) => setFilterYear(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button onClick={() => {
                            setFilterStartDate('');
                            setFilterEndDate('');
                            setFilterYear('');
                            setFilterWarehouseId('');
                        }}>
                            Reset Filter
                        </Button>
                    </div>
                    {(currentUser?.role.toString() === "1") && (
                        <div className='max-w-full'>
                            <Text>By Warehouse</Text>
                            <Dropdown>
                                <DropdownButton outline>
                                    {filterWarehouseId ? warehouses?.find(w => w.id?.toString() === filterWarehouseId)?.warehouse_name : 'All Warehouses'}
                                    <ChevronDownIcon />
                                </DropdownButton>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setFilterWarehouseId('')}>All Warehouses</DropdownItem>
                                    {warehouses?.map((warehouse) => (
                                        <DropdownItem key={warehouse.id} onClick={() => setFilterWarehouseId(warehouse.id?.toString()!)}>
                                            {warehouse.warehouse_name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    )}
                </div>}
            </div>

            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Reference No</TableHeader>
                                    <TableHeader>Manual No</TableHeader>
                                    <TableHeader>Customer </TableHeader>
                                    <TableHeader>Issued by</TableHeader>
                                    <TableHeader>Created on</TableHeader>
                                    <TableHeader className="text-center">Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(filteredStockAdjust.length > 0) ? filteredStockAdjust.map((stockAdjust, index) => (
                                    <TableRow key={stockAdjust.id} title={`${stockAdjust.reference_no}`}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{stockAdjust.reference_no}</TableCell>
                                        <TableCell className="text-zinc-500">{stockAdjust.manual_no}</TableCell>
                                        <TableCell className="text-zinc-500">{stockAdjust.customer_fullname}</TableCell>
                                        <TableCell className="text-zinc-500">{stockAdjust.issuer_fullname}</TableCell>
                                        <TableCell className="text-zinc-500">{dateFormat(stockAdjust.created_time?.toString(), "mmm dd, yyyy")}</TableCell>
                                        <TableCell className="py-5 grid grid-cols-3 text-zinc-500">
                                            <a onClick={() => navigate(`/gp/stocks/stockadjust/edit/${stockAdjust.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <PencilIcon className='h-5 w-5' />
                                            </a>
                                            <a onClick={() => navigate(`/gp/stocks/stockadjust/view/${stockAdjust.id}`)} className="text-zinc-600 hover:text-zinc-900">
                                                <EyeIcon className='h-5 w-5' />
                                            </a>

                                            <Dropdown>
                                                <DropdownButton plain aria-label="More options" className='h-5 w-5' >
                                                    <EllipsisVerticalIcon />
                                                </DropdownButton>
                                                <DropdownMenu anchor="bottom end">
                                                    <DropdownItem onClick={() => handleOpenDeleteDialog(stockAdjust.id.toString())}>Delete</DropdownItem>
                                                    <DropdownItem onClick={() => navigate(`/gp/stocks/stockadjust/add/items/${stockAdjust.id}`)}>Add Stock-adjust Items</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={9} className="text-center py-8">No stock adjust found.</TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Delete Stock Adjust</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this Stock Adjust data? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StockAdjustPage;
