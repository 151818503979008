// frontend/src/services/UserService.ts
import axios from 'axios';
import showErrorToast from '../components/Toasts/ShowErrorToast';
import { User } from '../models/User';

const API_URL = process.env.REACT_APP_SERVER_URL + "/users";

export const UserService = {

    login: async (username: string, password: string) => {
        try {
            const response = await axios.post(`${API_URL}/login`, {
                username,
                password,
            });
            // Save token and role in localStorage
            if (response.data.gpcwstoken_kw) {
                localStorage.setItem('gpcwstoken_kw', response.data.gpcwstoken_kw);
                localStorage.setItem('gpcwstoken_kw_role', response.data.gpcwstoken_kw);
            }
            if (!response.data.success) {
                showErrorToast(response.data.message);
            }
            return response.data.success;
        } catch (error) {
            console.error('Login failed:', error);
            showErrorToast('Login Error: ' + error);
            throw error;
        }
    },

    getProfile: async () => {
        try {
            const token = localStorage.getItem('gpcwstoken_kw');
            const response = await axios.get(`${API_URL}/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Check if the response indicates an expired or invalid token
            if (!response.data.success) {
                showErrorToast('Token expired or invalid: ' + response.data.message);
                localStorage.removeItem('gpcwstoken_kw');
                localStorage.removeItem('gratefulmsrole');
                return null; // Explicitly return null if token is expired
            }

            return response.data.userProfile;
        } catch (error) {
            console.error('Failed to fetch profile:', error);
            showErrorToast('Error fetching user profile: ' + error);
            localStorage.removeItem('gpcwstoken_kw');
            localStorage.removeItem('gratefulmsrole');
            throw error; // Re-throw error to propagate it to the calling component
        }
    },

    logout: () => {
        localStorage.removeItem('gpcwstoken_kw');
        localStorage.removeItem('gratefulmsrole');

    },

    // Get all users method
    getAllUsers: async () => {
        try {
            const token = localStorage.getItem('gpcwstoken_kw');
            const response = await axios.get(`${API_URL}/getAllUsers`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.data.success) {
                showErrorToast(response.data.message);
            }
            return response.data.users;
        } catch (error) {
            console.error('Failed to fetch users:', error);
            showErrorToast('Fetch Users Error: ' + error);
            throw error;
        }
    },

    getAllUsersByWarehouseId: async (warehouseId: string) => {
        try {
            const token = localStorage.getItem('gpcwstoken_kw');
            const response = await axios.get(`${API_URL}/getAllUsersByWarehouseId/${warehouseId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.data.success) {
                showErrorToast(response.data.message);
            }
            return response.data.users;
        } catch (error) {
            console.error('Failed to fetch users:', error);
            showErrorToast('Fetch Users Error: ' + error);
            throw error;
        }
    },

    getUserById: async (id: string): Promise<User | null> => {
        try {
            const token = localStorage.getItem('gpcwstoken_kw');
            const response = await axios.get(`${API_URL}/getUserById/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.data.success) {
                showErrorToast(response.data.message);
            }
            return response.data.user;
        } catch (error) {
            console.error('Failed to fetch user:', error);
            showErrorToast('Fetch User Error: ' + error);
            throw error;
        }
    },

    addUser: async (userData: Record<string, any>) => {
        try {
            const token = localStorage.getItem('gpcwstoken_kw');
            const response = await axios.post(`${API_URL}/add`, userData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Failed to add user:', error);
            showErrorToast('Add User Error: ' + error);
            throw error;
        }
    },

    addUserDev: async (userData: Record<string, any>) => {
        try {
            const token = localStorage.getItem('gpcwstoken_kw');
            const response = await axios.post(`${API_URL}/dev/add`, userData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Failed to add user:', error);
            showErrorToast('Add User Error: ' + error);
            throw error;
        }
    },

    updateUser: async (id: string, userData: Partial<User>) => {
        try {
            const token = localStorage.getItem('gpcwstoken_kw');
            const response = await axios.put(`${API_URL}/update/${id}`, userData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            return response.data;
        } catch (error) {
            console.error('Failed to update user:', error);
            showErrorToast('Update User Error: ' + error);
            throw error;
        }
    },

    deleteUser: async (id: string) => {
        try {
            const token = localStorage.getItem('gpcwstoken_kw');
            const response = await axios.delete(`${API_URL}/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (error) {
            console.error('Failed to delete user:', error);
            showErrorToast('Delete User Error: ' + error);
            throw error;
        }
    },

    resetPassword: async (userId: number, currentPassword: string, newPassword: string) => {
        try {
            const token = localStorage.getItem('gpcwstoken_kw');
            const response = await axios.post(`${API_URL}/resetpassword`, { userId, currentPassword, newPassword }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error resetting password:', error);
            throw new Error('Failed to reset password');
        }
    },

    resetPasswordByRandom: async (username: string) => {
        try {
            const token = localStorage.getItem('gpcwstoken_kw');
            const response = await axios.post(`${API_URL}/resetPasswordByRandom`, { username }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error resetting password by random:', error);
            throw new Error('Failed to reset password by random');
        }
    }

};
