import React, { useContext, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon, MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/Dropdown';
import { Badge } from '../../../components/Badge';
import { Link } from '../../../components/Link';
import { Button } from '../../../components/Button';
import { Heading, Subheading } from '../../../components/Heading';
import { Input, InputGroup } from '../../../components/Input';
import { Divider } from '../../../components/Divider';
import { Avatar } from '../../../components/Avatar';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination'; // Import the Pagination component
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../components/Dialog';
import { SetupUsergroup } from '../../../models/SetupUsergroup';
import { useSetupUsergroup } from '../../../hooks/useSetupUsergroup';
import { UserContext } from '../../../context/userContext';
import { Text } from '../../../components/Text';
import { useWarehouses } from '../../../hooks/useWarehouse';

const SetupLogisticsPage: React.FC = () => {
    const { getLogistics, getLogisticsByWarehouseId, loading, error, deleteUserGroup, fetchLogistic, totalLogistics, } = useSetupUsergroup();
    const [logistics, setLogistics] = useState<SetupUsergroup[]>([]);
    const { currentUser } = useContext(UserContext);
    const [searchQuery, setSearchQuery] = useState<string>(''); // State to store the search query
    const [currentPage, setCurrentPage] = useState<number>(1); // State to track the current page
    const [usersPerPage] = useState<number>(10); // Number of users per page
    const [isOpen, setIsOpen] = useState(false); // Dialog open state
    const [isDeleting, setIsDeleting] = useState(false); // Loading state for deletion
    const [selectedUsergroupId, setSelectedUsergroupId] = useState<string | null>(null); // ID of the user to be deleted
    const navigate = useNavigate();

    const { warehouses } = useWarehouses();
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [filterWarehouseId, setFilterWarehouseId] = useState('');

    // Filter users based on the search query
    const filteredUsergroups = logistics.filter((usergroups) => {
        const matchesSearch = usergroups.fullname.toLowerCase().includes(searchQuery.toLowerCase()) ||
            usergroups.email?.toLowerCase().includes(searchQuery.toLowerCase())
        const matchesStartDate = !filterStartDate || new Date(usergroups.created_time) >= new Date(filterStartDate);
        const matchesEndDate = !filterEndDate || new Date(usergroups.created_time) <= new Date(filterEndDate);
        const matchesYear = !filterYear || new Date(usergroups.created_time).getFullYear().toString() === filterYear;
        const matchesWarehouse = !filterWarehouseId || usergroups.warehouse_id === Number(filterWarehouseId);

        return matchesSearch && matchesStartDate && matchesEndDate && matchesYear && matchesWarehouse;

    }, []);

    // Pagination logic
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsergroups = filteredUsergroups.slice(indexOfFirstUser, indexOfLastUser);
    const totalPages = Math.ceil(filteredUsergroups.length / usersPerPage);

    const fetchLogisticList = async () => {
        try {
            // Check if user has HQ access
            if (currentUser?.warehouse_hq === "1") {
                const fetchedLogistic = await getLogistics();
                setLogistics(fetchedLogistic || []);
            } else if (currentUser?.warehouse_id) {
                const fetchedLogistic = await getLogisticsByWarehouseId(currentUser.warehouse_id.toString());
                setLogistics(fetchedLogistic || []);
            }
        } catch (error) {
            console.error('Failed to fetch customers:', error);
        }
    };

    useEffect(() => {
        fetchLogisticList();
    }, [currentUser]); // Dependencies to re-run on change

    // Open the delete dialog with the selected user's ID
    const handleOpenDeleteDialog = (usergroupId: string) => {
        setSelectedUsergroupId(usergroupId);
        setIsOpen(true);
    };

    // Handle delete logistic
    const handleDelete = async () => {
        if (!selectedUsergroupId) return;

        setIsDeleting(true);
        try {
            await deleteUserGroup(Number(selectedUsergroupId));
            fetchLogisticList(); // Refresh the usergroup list
            setIsOpen(false);
        } catch (error) {
            console.error('Failed to delete logistic:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    useEffect(() => {
        document.title = "Logistics";
    }, []);

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Logistics</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder="Search logistic&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Button onClick={() => navigate('/gp/setup/logistics/add')}>Create new logistic</Button>
            </div>

            {/* Date, Year, and Warehouse Filters */}
            <div className="mt-4 px-6 py-4 bg-zinc-50 ring-1 ring-zinc-200 rounded-md">
                <div className='flex flex-wrap items-end justify-between gap-4'>
                    <Subheading>Filters & Sorting</Subheading>
                    {(isFilterOpen) ? <ChevronUpIcon onClick={() => setIsFilterOpen(false)} className='w-5 h-5' /> : <ChevronDownIcon onClick={() => setIsFilterOpen(true)} className='w-5 h-5' />}
                </div>
                {(isFilterOpen) && <div className='mt-4 grid gap-4 sm:grid-cols-1'>
                    <div>
                        <Text>From Date</Text>
                        <Input
                            type="date"
                            value={filterStartDate}
                            onChange={(e) => setFilterStartDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>To Date</Text>
                        <Input
                            type="date"
                            value={filterEndDate}
                            onChange={(e) => setFilterEndDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>By Year</Text>
                        <Input
                            type="number"
                            placeholder="Year"
                            value={filterYear}
                            onChange={(e) => setFilterYear(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button onClick={() => {
                            setFilterStartDate('');
                            setFilterEndDate('');
                            setFilterYear('');
                            setFilterWarehouseId('');
                        }}>
                            Reset Filter
                        </Button>
                    </div>
                    {(currentUser?.role.toString() === "1") && (
                        <div className='max-w-full'>
                            <Text>By Warehouse</Text>
                            <Dropdown>
                                <DropdownButton outline>
                                    {filterWarehouseId ? warehouses?.find(w => w.id?.toString() === filterWarehouseId)?.warehouse_name : 'All Warehouses'}
                                    <ChevronDownIcon />
                                </DropdownButton>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setFilterWarehouseId('')}>All Warehouses</DropdownItem>
                                    {warehouses?.map((warehouse) => (
                                        <DropdownItem key={warehouse.id} onClick={() => setFilterWarehouseId(warehouse.id?.toString()!)}>
                                            {warehouse.warehouse_name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    )}
                </div>}
            </div>

            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {!loading && !error && currentUsergroups.length === 0 && <p className='py-16 text-center'>No logistics found.</p>}
                {!loading && !error && currentUsergroups.map((usergroup, index) => (
                    <li key={usergroup.id}>
                        <Divider soft={index > 0} />
                        <div className="flex items-center justify-between">
                            <div className="flex gap-6 py-5">
                                <Avatar square initials={usergroup.fullname[0]} className="bg-zinc-300 text-gray-700 size-12" />
                                <div className="">
                                    <div className="text-base/6 font-semibold">
                                        <Link href={`/gp/setup/logistics/view/${usergroup.id}`}>{usergroup.fullname}</Link>
                                    </div>
                                    <div className="text-xs/6 text-zinc-600">
                                        {usergroup.email ? usergroup.email : 'Undefined email'}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <Badge className="max-sm:hidden" color='lime'>
                                    {usergroup.warehouse_name! || '-'}
                                </Badge>
                                <Dropdown>
                                    <DropdownButton plain aria-label="More options">
                                        <EllipsisVerticalIcon />
                                    </DropdownButton>
                                    <DropdownMenu anchor="bottom end">
                                        <DropdownItem onClick={() => navigate(`/gp/setup/logistics/view/${usergroup.id}`)}>View</DropdownItem>
                                        <DropdownItem onClick={() => navigate(`/gp/setup/logistics/edit/${usergroup.id}`)}>Edit</DropdownItem>
                                        <DropdownItem onClick={() => handleOpenDeleteDialog(usergroup.id!.toString())}>Delete</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
                totalResult={logistics.length}
                dataName='Logistics'
            />

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Delete logistic</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this logistic? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SetupLogisticsPage;
