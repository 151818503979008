import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import AccessDenied from "./404/AccessDenied/Page";

const PrivateRoute = () => {
    const { hasAccess } = useAuth();
    return (
        <Outlet />
    );
};

export default PrivateRoute;