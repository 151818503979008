import React, { useEffect, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon, CreditCardIcon } from '@heroicons/react/16/solid';
import { Link, useNavigate, useParams } from 'react-router-dom'; // useParams to get route params
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Avatar } from '../../../../components/Avatar';
import { UserService } from '../../../../services/userService'; // Import the UserService
import Loading from '../../../../components/Loading';
import dateFormat from 'dateformat';
import { useSetupUsergroup } from '../../../../hooks/useSetupUsergroup';
import { SetupUsergroup } from '../../../../models/SetupUsergroup';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';

const SetupViewLogisticIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Use useParams to get the id from the URL
    const navigate = useNavigate();
    const [logistic, setLogistic] = useState<SetupUsergroup | null>(null);
    const { fetchUserGroupById, loading, error } = useSetupUsergroup();

    useEffect(() => {
        document.title = "View Logistic";
    }, []);

    useEffect(() => {
        const fetchLogisticDetails = async () => {
            if (!id) return; // Ensure ID is valid before making the request

            try {
                const fetchedlogistic = await fetchUserGroupById(Number(id));
                setLogistic(fetchedlogistic);
            } catch (err) {
                showErrorToast('Failed to fetch logistic details.');
            }
        };
        fetchLogisticDetails();
    }, [id]);

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!logistic) return <p className='py-16 text-center'>logistic not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/setup/logistics" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Logistics
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={logistic.fullname[0]} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{logistic.fullname}</Heading>
                    <Badge color="lime">{logistic.usergroup_name!}</Badge>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span>{dateFormat(logistic.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">
                        <Button onClick={() => navigate(`/gp/setup/logistics/edit/${logistic.id}`)}>Edit</Button>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>logistic Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Fullname</DescriptionTerm>
                    <DescriptionDetails>{logistic.fullname}</DescriptionDetails>
                    <DescriptionTerm>Second Name</DescriptionTerm>
                    <DescriptionDetails>{logistic.second_name}</DescriptionDetails>
                    <DescriptionTerm>Area</DescriptionTerm>
                    <DescriptionDetails>{logistic.area}</DescriptionDetails>
                    <DescriptionTerm>Group</DescriptionTerm>
                    <DescriptionDetails>{logistic.group}</DescriptionDetails>
                    <DescriptionTerm>Office No</DescriptionTerm>
                    <DescriptionDetails>{logistic.office_no}</DescriptionDetails>
                    <DescriptionTerm>Fax No</DescriptionTerm>
                    <DescriptionDetails>{logistic.fax_no}</DescriptionDetails>
                    <DescriptionTerm>Mobile No</DescriptionTerm>
                    <DescriptionDetails>{logistic.mobile_no}</DescriptionDetails>
                    <DescriptionTerm>Account Code</DescriptionTerm>
                    <DescriptionDetails>{logistic.account_code}</DescriptionDetails>
                    <DescriptionTerm>Address</DescriptionTerm>
                    <DescriptionDetails>{logistic.address}</DescriptionDetails>
                    <DescriptionTerm>Address 2</DescriptionTerm>
                    <DescriptionDetails>{logistic.address_2}</DescriptionDetails>
                    <DescriptionTerm>Address 3</DescriptionTerm>
                    <DescriptionDetails>{logistic.address_3}</DescriptionDetails>
                    <DescriptionTerm>Address 4</DescriptionTerm>
                    <DescriptionDetails>{logistic.address_4}</DescriptionDetails>
                    <DescriptionTerm>Email</DescriptionTerm>
                    <DescriptionDetails>{logistic.email || '-'}</DescriptionDetails>
                    <DescriptionTerm>Warehouse</DescriptionTerm>
                    <DescriptionDetails>{logistic.warehouse_name || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>
        </>
    );
};

export default SetupViewLogisticIdPage;
