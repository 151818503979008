import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Select } from '../../../../components/Select';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import Loading from '../../../../components/Loading';
import { useWarehouses } from '../../../../hooks/useWarehouse';
import { useWarehouseProductCategory } from '../../../../hooks/useWarehouseProductCategory';
import { useWarehouseProductDepartment } from '../../../../hooks/useWarehouseProductDepartment';
import ReactSelect, { SingleValue } from 'react-select';
import { WarehouseProductDepartment } from '../../../../models/WarehouseProductDepartment';

interface AddWarehouseProductCategory {
    category_code: string;
    category_name: string;
    department_id: number;
    remarks: string;
}

const AddWarehouseProductCategoryPage = () => {
    const navigate = useNavigate();
    const { departments } = useWarehouseProductDepartment();

    const [category, setCategory] = useState<AddWarehouseProductCategory>({
        category_code: '',
        category_name: '',
        department_id: Number(0),
        remarks: '',
    });
    const { addCategory } = useWarehouseProductCategory();

    useEffect(() => {
        document.title = "Add Product Category";
    }, []);

    useEffect(() => {
        if (departments.length > 0) {
            setCategory(prev => ({ ...prev, department_id: Number(departments[0].id) }));
        }
    }, [departments])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setCategory(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!category.category_name || !category.department_id) {
            showErrorToast("Please fill in all fields before submit.");
            return;
        }

        try {
            const res = await addCategory(category);
            if (res.dbsuccess) {
                showLoadingToast(`Creating ${category.category_name} ...`, 100);
                setTimeout(() => {
                    navigate('/gp/inventories/categories');
                    showSuccessToast(`${category.category_name} added successfully`);
                }, 1000);

            } else {
                showErrorToast(`Failed creating category data`);
            }
        } catch (err) {
            showErrorToast(`Failed to add category: ${err}`);
        }
    };

    const selectDepartmentOptions = departments.map((department: WarehouseProductDepartment) => ({
        value: department.id,
        label: `${department.department_name || "-"}`,
    }));

    // Handle ReactSelect change
    const handleDepartmentChange = (newValue: SingleValue<{ value: number; label: string }>) => {
        if (newValue) {
            setCategory((prev) => ({ ...prev, department_id: newValue.value }));
        }
    };


    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Product Category</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className='ml-3' disabled={!category.category_name || !category.department_id}>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Product Category Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Grouping of products that share similar characteristics.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Category Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Category Name"
                                        name="category_name"
                                        value={category.category_name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Category Code</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Category Code"
                                        name="category_code"
                                        value={category.category_code}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Department</Subheading>
                                </div>
                                <div className="mt-2">
                                    <ReactSelect
                                        id="department_id"
                                        name="department_id"
                                        options={selectDepartmentOptions}
                                        className="text-sm"
                                        placeholder="Select Department"
                                        onChange={handleDepartmentChange}
                                        value={selectDepartmentOptions.find(option => option.value === category.department_id)}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Remarks</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Remarks"
                                        name="remarks"
                                        value={category.remarks}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button type="submit" disabled={!category.category_name || !category.department_id}>Save</Button>
            </div>
        </form>
    );
};

export default AddWarehouseProductCategoryPage;
