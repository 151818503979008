import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../../models/User';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Select } from '../../../../components/Select';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { useRoles } from '../../../../hooks/useRoles';
import Loading from '../../../../components/Loading';
import { useStockIn } from '../../../../hooks/useStockIn';
import { useSetupUsergroup } from '../../../../hooks/useSetupUsergroup';
import useUserService from '../../../../hooks/useUserService';
import { UserContext } from '../../../../context/userContext';
import { useWarehouses } from '../../../../hooks/useWarehouse';
import { SetupUsergroup } from '../../../../models/SetupUsergroup';

interface AddStockIn {
    date: string | null; // ISO date string or null
    reference_no: string | null;
    supplier_id: number | null;
    supplier_invoice: string | null;
    logistic_id: number | null;
    receiver_id: number | null;
    created_by: number;
    warehouse_id: number;
}

const AddStockInPage = () => {
    const navigate = useNavigate();

    const [stockIn, setStockIn] = useState<AddStockIn>({
        date: '',
        reference_no: '',
        supplier_id: Number(0),
        supplier_invoice: '',
        logistic_id: Number(0),
        receiver_id: Number(0),
        created_by: Number(0),
        warehouse_id: Number(0),
    });
    const { createStockIn } = useStockIn();
    const { suppliers, logistics, getSuppliers, getSuppliersByWarehouseId, getLogistics, getLogisticsByWarehouseId } = useSetupUsergroup();
    // const [suppliers, setSuppliers] = useState<SetupUsergroup[]>([]);
    // const [logistics, setLogistics] = useState<SetupUsergroup[]>([]);
    const { users } = useUserService();
    const { currentUser } = useContext(UserContext);
    const { warehouses } = useWarehouses();
    const filteredWarehouses = warehouses.filter(warehouse => warehouse.hq !== '1');

    useEffect(() => {
        document.title = "Add Stock in";
    }, []);

    // const getUserGroupsDetails = async () => {
    //     try {
    //         if (currentUser?.warehouse_hq === "1") {
    //             const fetchedSupplier = await getSuppliers();
    //             setSuppliers(fetchedSupplier || []);
    //             const fetchedLogistic = await getLogistics();
    //             setLogistics(fetchedLogistic || [])
    //         } else if (currentUser?.warehouse_id) {
    //             const fetchedSupplier = await getSuppliersByWarehouseId(currentUser.warehouse_id.toString());
    //             setSuppliers(fetchedSupplier || []);
    //             const fetchedLogistic = await getLogisticsByWarehouseId(currentUser.warehouse_id.toString());
    //             setLogistics(fetchedLogistic || [])
    //         }
    //     } catch (error) {
    //         console.error('Error fetching products:', error);
    //     }
    // }

    useEffect(() => {
        // getUserGroupsDetails();
        if (suppliers.length > 0) {
            setStockIn(prev => ({ ...prev, supplier_id: suppliers[0].id! }));
        }
        if (logistics.length > 0) {
            setStockIn(prev => ({ ...prev, logistic_id: logistics[0].id! }));
        }
        if (users.length > 0) {
            setStockIn(prev => ({ ...prev, receiver_id: Number(users[0].id!) }));
        }
        if (filteredWarehouses.length > 0) {
            setStockIn(prev => ({ ...prev, warehouse_id: Number(filteredWarehouses[0].id) }));
        }
        if (currentUser?.warehouse_hq !== "1") {
            setStockIn(prev => ({ ...prev, warehouse_id: Number(currentUser?.warehouse_id) }));
        }
        setStockIn(prev => ({ ...prev, created_by: Number(currentUser?.id) }));
    }, [suppliers, logistics, users, currentUser]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setStockIn(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!stockIn.date || !stockIn.logistic_id || !stockIn.receiver_id || !stockIn.reference_no || !stockIn.supplier_id || !stockIn.supplier_invoice || !stockIn.warehouse_id || !stockIn.created_by) {
            showErrorToast("Please fill in all field before submit");
            return;
        }

        try {
            const res = await createStockIn(stockIn);
            showLoadingToast(`Creating ${stockIn.reference_no} ...`, 100);
            if (res.dbsuccess) {
                setTimeout(() => {
                    navigate('/gp/stocks/stockin');
                    showSuccessToast(`${stockIn.reference_no} added successfully`);
                }, 1000);
            } else {
                showErrorToast(`Failed adding stock-in data`);
            }
        } catch (err) {
            showErrorToast(`Failed adding stock-in data: ${err}`);
        }
    };

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Stock In</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className='ml-3' disabled={!stockIn.date || !stockIn.logistic_id || !stockIn.receiver_id || !stockIn.reference_no || !stockIn.supplier_id || !stockIn.supplier_invoice || !stockIn.warehouse_id}>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Stock In Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Information on incoming inventory to the warehouse.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Stock-in Date</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type='date'
                                        aria-label="Date"
                                        name="date"
                                        value={stockIn.date!}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Warehouse</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Warehouse"
                                        name="warehouse_id"
                                        value={stockIn.warehouse_id.toString()}
                                        onChange={handleChange}
                                        required
                                        disabled={currentUser?.warehouse_hq !== "1"}
                                    >
                                        {filteredWarehouses.map(warehouse => (
                                            <option key={warehouse.id.toString()} value={warehouse.id.toString()}>
                                                {warehouse.warehouse_name}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Reference No</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Reference No"
                                        name="reference_no"
                                        value={stockIn.reference_no!}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Supplier</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Supplier"
                                        name="supplier_id"
                                        value={stockIn.supplier_id!.toString()}
                                        onChange={handleChange}
                                        required
                                    >
                                        {suppliers.map(supplier => (
                                            <option key={supplier.id} value={supplier.id!.toString()}>
                                                {supplier.fullname}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Supplier Invoice</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Supplier Invoice"
                                        name="supplier_invoice"
                                        value={stockIn.supplier_invoice!}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Logistic</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Logistic"
                                        name="logistic_id"
                                        value={stockIn.logistic_id!.toString()}
                                        onChange={handleChange}
                                        required
                                    >
                                        {logistics.map(logistic => (
                                            <option key={logistic.id} value={logistic.id!.toString()}>
                                                {logistic.fullname}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Received by</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Receiver Id"
                                        name="receiver_id"
                                        value={stockIn.receiver_id!.toString()}
                                        onChange={handleChange}
                                        required
                                    >
                                        {users.map(user => (
                                            <option key={user.id} value={user.id!.toString()}>
                                                {user.fullname}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-4 my-6">
                    <Button type="reset" plain onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" disabled={!stockIn.date || !stockIn.logistic_id || !stockIn.receiver_id || !stockIn.reference_no || !stockIn.supplier_id || !stockIn.supplier_invoice || !stockIn.warehouse_id}>Save</Button>
                </div>
            </div>
        </form>
    );
};

export default AddStockInPage;
