import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../../models/User';
import { Heading, Subheading } from '../../../../components/Heading';
import { Divider } from '../../../../components/Divider';
import { Input } from '../../../../components/Input';
import { Textarea } from '../../../../components/Textarea';
import { Select } from '../../../../components/Select';
import { Button } from '../../../../components/Button';
import showLoadingToast from '../../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../../components/Toasts/ShowSuccessToast';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { useRoles } from '../../../../hooks/useRoles';
import Loading from '../../../../components/Loading';
import { useStockOut } from '../../../../hooks/useStockOut';
import { useSetupUsergroup } from '../../../../hooks/useSetupUsergroup';
import useUserService from '../../../../hooks/useUserService';
import { UserContext } from '../../../../context/userContext';
import { useWarehouses } from '../../../../hooks/useWarehouse';

interface AddStockOut {
    date: string | null; // ISO date string or null
    reference_no: string | null;
    customer_id: number | null;
    invoice_no: string | null;
    logistic_id: number | null;
    issue_date: string | null;
    issue_time: string | null;
    issuer_id: number | null;
    created_by: number;
    warehouse_id: number;
}

const AddStockOutPage = () => {
    const navigate = useNavigate();

    const [stockOut, setStockOut] = useState<AddStockOut>({
        date: '',
        reference_no: '',
        customer_id: Number(0),
        invoice_no: '',
        logistic_id: Number(0),
        issue_date: '',
        issue_time: '',
        issuer_id: Number(0),
        created_by: Number(0),
        warehouse_id: Number(0),
    });
    const { createStockOut } = useStockOut();
    const { customers, logistics } = useSetupUsergroup();
    const { users } = useUserService();
    const { currentUser } = useContext(UserContext);
    const { warehouses } = useWarehouses();
    const filteredWarehouses = warehouses.filter(warehouse => warehouse.hq !== '1');

    useEffect(() => {
        document.title = "Add Stock Out";
    }, []);

    useEffect(() => {
        if (customers.length > 0) {
            setStockOut(prev => ({ ...prev, customer_id: customers[0].id! }));
        }
        if (logistics.length > 0) {
            setStockOut(prev => ({ ...prev, logistic_id: logistics[0].id! }));
        }
        if (users.length > 0) {
            setStockOut(prev => ({ ...prev, issuer_id: Number(users[0].id!) }));
        }
        if (filteredWarehouses.length > 0) {
            setStockOut(prev => ({ ...prev, warehouse_id: Number(filteredWarehouses[0].id) }));
        }
        if (currentUser?.warehouse_hq !== "1") {
            setStockOut(prev => ({ ...prev, warehouse_id: Number(currentUser?.warehouse_id) }));
        }
        setStockOut(prev => ({ ...prev, created_by: Number(currentUser?.id) }));
    }, [customers, logistics, users, currentUser]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setStockOut(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!stockOut.created_by || !stockOut.customer_id || !stockOut.date || !stockOut.invoice_no || !stockOut.issue_date || !stockOut.issue_time || !stockOut.issuer_id || !stockOut.logistic_id || !stockOut.reference_no || !stockOut.warehouse_id) {
            showErrorToast("Please fill in all field before submit");
            return;
        }

        try {
            const res = await createStockOut(stockOut);
            showLoadingToast(`Creating ${stockOut.reference_no} ...`, 100);
            if (res.dbsuccess) {
                setTimeout(() => {
                    navigate('/gp/stocks/stockout');
                    showSuccessToast(`${stockOut.reference_no} added successfully`);
                }, 1000);
            } else {
                showErrorToast(`Failed adding stock-out data`);
            }
        } catch (err) {
            showErrorToast(`Failed adding stock-out data: ${err}`);
        }
    };

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Add Stock Out Data</Heading>
                <div className='mt-5 flex lg:ml-4 lg:mt-0'>
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className='ml-3' disabled={(!stockOut.created_by || !stockOut.customer_id || !stockOut.date || !stockOut.invoice_no || !stockOut.issue_date || !stockOut.issue_time || !stockOut.issuer_id || !stockOut.logistic_id || !stockOut.reference_no || !stockOut.warehouse_id)}>Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Stock Out Information</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Information on outgoing inventory from the warehouse.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Stock-out Date</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type='date'
                                        aria-label="Date"
                                        name="date"
                                        value={stockOut.date!}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Warehouse</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Warehouse"
                                        name="warehouse_id"
                                        value={stockOut.warehouse_id.toString()}
                                        onChange={handleChange}
                                        required
                                        disabled={currentUser?.warehouse_hq !== "1"}
                                    >
                                        {filteredWarehouses.map(warehouse => (
                                            <option key={warehouse.id.toString()} value={warehouse.id.toString()}>
                                                {warehouse.warehouse_name}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Reference No</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Reference No"
                                        name="reference_no"
                                        value={stockOut.reference_no!}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Customer</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Customer"
                                        name="customer_id"
                                        value={stockOut.customer_id!.toString()}
                                        onChange={handleChange}
                                        required
                                    >
                                        {customers.map(customer => (
                                            <option key={customer.id} value={customer.id!.toString()}>
                                                {customer.fullname}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Invoice Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Invoice Number"
                                        name="invoice_no"
                                        value={stockOut.invoice_no!}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Logistic</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Logistic"
                                        name="logistic_id"
                                        value={stockOut.logistic_id!.toString()}
                                        onChange={handleChange}
                                        required
                                    >
                                        {logistics.map(logistic => (
                                            <option key={logistic.id} value={logistic.id!.toString()}>
                                                {logistic.fullname}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Issue Date</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type='date'
                                        aria-label="Issue Date"
                                        name="issue_date"
                                        value={stockOut.issue_date!}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Issue Time</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type='time'
                                        aria-label="Issue Time"
                                        name="issue_time"
                                        value={stockOut.issue_time!}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Issued by</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Select
                                        aria-label="Issuer Id"
                                        name="issuer_id"
                                        value={stockOut.issuer_id!.toString()}
                                        onChange={handleChange}
                                        required
                                    >
                                        {users.map(user => (
                                            <option key={user.id} value={user.id!.toString()}>
                                                {user.fullname}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-4 my-6">
                    <Button type="reset" plain onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" disabled={(!stockOut.created_by || !stockOut.customer_id || !stockOut.date || !stockOut.invoice_no || !stockOut.issue_date || !stockOut.issue_time || !stockOut.issuer_id || !stockOut.logistic_id || !stockOut.reference_no || !stockOut.warehouse_id)}>Save</Button>
                </div>
            </div>
        </form>
    );
};

export default AddStockOutPage;
