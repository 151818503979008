// services/TrackingPickupService.ts
import axios from 'axios';
import { TrackingPickup } from '../models/TrackingPickup'; // Make sure to define the TrackingPickup model

const API_URL = process.env.REACT_APP_SERVER_URL + "/trackingpickup";

const TrackingPickupService = {
    // Fetch all tracking pickups
    getAllTrackingPickups: async (): Promise<TrackingPickup[]> => {
        const response = await axios.get(`${API_URL}/getAllTrackingPickups`);
        return response.data;
    },

    // Fetch tracking pickup by ID
    getTrackingPickupById: async (id: number): Promise<TrackingPickup> => {
        const response = await axios.get(`${API_URL}/getTrackingPickUpById/${id}`);
        return response.data;
    },

    // Fetch tracking pickup by stock out ID
    getTrackingPickupByStockOutId: async (id: number): Promise<TrackingPickup> => {
        const response = await axios.get(`${API_URL}/getTrackingPickupByStockOutId/${id}`);
        return response.data;
    },

    // Create a new tracking pickup
    createTrackingPickup: async (trackingPickupData: Omit<TrackingPickup, 'id' | 'created_time' | 'updated_time'>): Promise<TrackingPickup> => {
        const response = await axios.post(`${API_URL}/add`, trackingPickupData);
        return response.data;
    },

    // Update an existing tracking pickup by ID
    updateTrackingPickup: async (id: number, trackingPickupData: Omit<TrackingPickup, 'id' | 'created_time' | 'updated_time'>): Promise<TrackingPickup> => {
        const response = await axios.put(`${API_URL}/update/${id}`, trackingPickupData);
        return response.data;
    },

    // Delete a tracking pickup by ID
    deleteTrackingPickup: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    }
};

export default TrackingPickupService;
