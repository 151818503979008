import React, { useEffect, useState } from 'react';
import { EllipsisVerticalIcon, MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/Dropdown';
import { Badge } from '../../../components/Badge';
import { Link } from '../../../components/Link';
import { Button } from '../../../components/Button';
import { Heading } from '../../../components/Heading';
import { Input, InputGroup } from '../../../components/Input';
import { Divider } from '../../../components/Divider';
import { Avatar } from '../../../components/Avatar';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination'; // Import the Pagination component
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../components/Dialog';
import { useWarehouses } from '../../../hooks/useWarehouse';

const SetupWarehousesPage: React.FC = () => {
    const { warehouses, loading, error, addWarehouse, deleteWarehouse, fetchWarehouses, totalWarehouse, } = useWarehouses();
    const [searchQuery, setSearchQuery] = useState<string>(''); // State to store the search query
    const [currentPage, setCurrentPage] = useState<number>(1); // State to track the current page
    const [warehousePerPage] = useState<number>(10); // Number of Warehouses per page
    const [isOpen, setIsOpen] = useState(false); // Dialog open state
    const [isDeleting, setIsDeleting] = useState(false); // Loading state for deletion
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<string | null>(null); // ID of the Warehouse to be deleted
    const navigate = useNavigate();

    // Ensure warehouses is always an array before filtering
    const filteredWarehouses = Array.isArray(warehouses)
        ? warehouses.filter((warehouse) =>
            warehouse.warehouse_name!.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : [];

    // Pagination logic
    const indexOfLastWarehouse = currentPage * warehousePerPage;
    const indexOfFirstWarehouse = indexOfLastWarehouse - warehousePerPage;
    const currentWarehouses = filteredWarehouses.slice(indexOfFirstWarehouse, indexOfLastWarehouse);
    const totalPages = Math.ceil(filteredWarehouses.length / warehousePerPage);

    // Open the delete dialog with the selected Warehouse's ID
    const handleOpenDeleteDialog = (WarehouseId: string) => {
        setSelectedWarehouseId(WarehouseId);
        setIsOpen(true);
    };

    // Handle delete Warehouse
    const handleDelete = async () => {
        if (!selectedWarehouseId) return;

        setIsDeleting(true);
        try {
            await deleteWarehouse(Number(selectedWarehouseId));
            fetchWarehouses(); // Refresh the Warehouse list
            setIsOpen(false);
        } catch (error) {
            console.error('Failed to delete warehouse:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Warehouses</Heading>
                    <div className="mt-4 flex max-w-xl gap-4">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder="Search warehouse&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <Button onClick={() => navigate('/gp/Warehouses/add')}>Create new warehouse</Button>
            </div>

            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {!loading && !error && currentWarehouses.length === 0 && <p className='py-16 text-center'>No Warehouses found.</p>}
                {!loading && !error && currentWarehouses.map((warehouse, index) => (
                    <li key={warehouse.id}>
                        <Divider soft={index > 0} />
                        <div className="flex items-center justify-between">
                            <div className="flex gap-6 py-5">
                                <Avatar square initials={warehouse.warehouse_name![0]} className="bg-zinc-300 text-gray-700 size-12" />
                                <div className="">
                                    <div className="text-base/6 font-semibold">
                                        <Link href={`/gp/warehouses/view/${warehouse.id}`}>{warehouse.warehouse_name}</Link>
                                    </div>
                                    <div className="text-xs/6 text-zinc-600">
                                        {warehouse.location ? warehouse.location : 'Undefined location'}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <Dropdown>
                                    <DropdownButton plain aria-label="More options">
                                        <EllipsisVerticalIcon />
                                    </DropdownButton>
                                    <DropdownMenu anchor="bottom end">
                                        <DropdownItem onClick={() => navigate(`/gp/warehouses/view/${warehouse.id}`)}>View</DropdownItem>
                                        <DropdownItem onClick={() => navigate(`/gp/warehouses/edit/${warehouse.id}`)}>Edit</DropdownItem>
                                        <DropdownItem onClick={() => handleOpenDeleteDialog(warehouse.id!.toString())}>Delete</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
                totalResult={totalWarehouse}
                dataName='Warehouses'
            />

            {/* Delete Confirmation Dialog */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Delete Warehouse</DialogTitle>
                <DialogDescription>
                    Are you sure you want to delete this warehouse? This action cannot be undone.
                </DialogDescription>
                <DialogActions>
                    <Button plain onClick={() => setIsOpen(false)} disabled={isDeleting}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} disabled={isDeleting}>
                        {isDeleting ? <Loading /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SetupWarehousesPage;
