// services/StockAdjustItemService.ts
import axios from 'axios';
import { StockAdjustItem } from '../models/StockAdjustItem';

const API_URL = process.env.REACT_APP_SERVER_URL + "/stockadjustitem";

const StockAdjustItemService = {
    // Fetch all stock_adjust_items
    getAllStockAdjustItems: async (): Promise<StockAdjustItem[]> => {
        const response = await axios.get(`${API_URL}/getAllStockAdjustItems`);
        return response.data;
    },

    // Fetch stock_adjust_item by ID
    getStockAdjustItemById: async (id: number): Promise<StockAdjustItem> => {
        const response = await axios.get(`${API_URL}/getStockAdjustItemById/${id}`);
        return response.data;
    },

    // Fetch stock_adjust_item by ID
    getStockAdjustItemByStockAdjustId: async (id: number): Promise<StockAdjustItem[]> => {
        const response = await axios.get(`${API_URL}/getStockAdjustItemByStockAdjustId/${id}`);
        return response.data;
    },

    // Create new stock_adjust_item
    createStockAdjustItem: async (data: Omit<StockAdjustItem, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.post(`${API_URL}/add`, data);
        return response.data;
    },

    // Update stock_adjust_item by ID
    updateStockAdjustItem: async (id: number, data: Omit<StockAdjustItem, 'id' | 'created_time' | 'updated_time'>) => {
        const response = await axios.put(`${API_URL}/update/${id}`, data);
        return response.data;
    },

    // Delete stock_adjust_item by ID
    deleteStockAdjustItem: async (id: number): Promise<void> => {
        await axios.delete(`${API_URL}/delete/${id}`);
    },
};

export default StockAdjustItemService;
