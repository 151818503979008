import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserService } from '../../../services/userService';
import { User } from '../../../models/User';
import { Heading, Subheading } from '../../../components/Heading';
import { Divider } from '../../../components/Divider';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Select } from '../../../components/Select';
import { Textarea } from '../../../components/Textarea';
import { Button } from '../../../components/Button';
import showLoadingToast from '../../../components/Toasts/ShowLoadingToast';
import showSuccessToast from '../../../components/Toasts/ShowSuccessToast';
import useUserService from '../../../hooks/useUserService';
import showErrorToast from '../../../components/Toasts/ShowErrorToast';
import { useWarehouses } from '../../../hooks/useWarehouse';
import { useRoles } from '../../../hooks/useRoles';
import { UserContext } from '../../../context/userContext';

const EditMyAccountPage = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const [user, setUser] = useState<User | null>(null);
    const { getUserById, loading, error } = useUserService();
    const { warehouses } = useWarehouses();
    const { roles } = useRoles();

    useEffect(() => {
        document.title = "Edit My Account";
    }, []);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const fetchedUser = await getUserById(currentUser?.id!.toString()!);
                setUser(fetchedUser);
            } catch (err) {
                showErrorToast('Failed to fetch my account details.');
            }
        };

        fetchUserDetails();
    }, [currentUser]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setUser(prev => prev ? { ...prev, [name]: name === 'role' ? BigInt(value) : value } : null);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!user) return;

        try {
            const res = await UserService.updateUser(user.id.toString(), user);
            if (res.dbsuccess) {
                showLoadingToast(`Updating ${user.fullname} ...`, 100);
                setTimeout(() => {
                    navigate('/gp/users');
                    showSuccessToast(`${user.fullname} updated successfully`);
                }, 1000);
            } else {
                showErrorToast(`Error updating user`);
            }
        } catch (err) {
            showErrorToast('Failed to update user.');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (!user) return <p>User not found.</p>;
    if (!warehouses) return <p>Warehouses not found.</p>;

    return (
        <form method="post" onSubmit={handleSubmit} className="mx-auto max-w-6xl">
            <div className="lg:flex lg:items-center lg:justify-between mb-5">
                <Heading>Edit User</Heading>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <Button type="reset" outline onClick={() => navigate(-1)}>
                        Cancel
                    </Button>
                    <Button type="submit" className="ml-3">Save</Button>
                </div>
            </div>
            <Divider className="mt-5 mb-8" />
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            This information will be displayed publicly so be careful what you share.
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Full Name</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Full Name"
                                        name="fullname"
                                        value={user.fullname}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Email</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        type="email"
                                        aria-label="Email"
                                        name="email"
                                        value={user.email?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Mobile Number</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Mobile Number"
                                        name="mobile_no"
                                        value={user.mobile_no?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Street Address</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Input
                                        aria-label="Street Address"
                                        name="street_address"
                                        value={user.street_address?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>

                        <div className="sm:col-span-full">
                            <section className="">
                                <div className="space-y-1">
                                    <Subheading>Remarks</Subheading>
                                </div>
                                <div className="mt-2">
                                    <Textarea
                                        aria-label="Remarks"
                                        name="remarks"
                                        value={user.remarks?.toString()}
                                        onChange={handleChange}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <div className='space-y-12'>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Account Information</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                Provide account details for the user.
                            </p>
                        </div>

                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>Username</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Input
                                            aria-label="Username"
                                            name="username"
                                            value={user.username}
                                            onChange={handleChange}
                                            disabled
                                        />
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>Role</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Select
                                            aria-label="Role"
                                            name="role"
                                            value={user.role.toString()}
                                            onChange={handleChange}
                                            disabled
                                        >
                                            {roles.map(role => (
                                                <option key={role.id.toString()} value={role.id.toString()}>
                                                    {role.rolename}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                </section>
                            </div>

                            <div className="sm:col-span-full">
                                <section className="">
                                    <div className="space-y-1">
                                        <Subheading>Warehouse</Subheading>
                                    </div>
                                    <div className="mt-2">
                                        <Select
                                            aria-label="Warehouse"
                                            name="warehouse_id"
                                            value={user.warehouse_id?.toString()}
                                            onChange={handleChange}
                                            disabled
                                        >
                                            {warehouses.map(warehouse => (
                                                <option key={warehouse.id.toString()} value={warehouse.id.toString()}>
                                                    {warehouse.warehouse_name}
                                                </option>
                                            ))}
                                        </Select>
                                    </div>
                                </section>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-4 my-6">
                <Button type="reset" plain onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button type="submit">Save</Button>
            </div>
        </form>
    );
};

export default EditMyAccountPage;
