import React, { Fragment } from 'react';
import { Image, Text, View, Page, Document, StyleSheet } from '@react-pdf/renderer';
import { StockOut } from '../../../../models/StockOut';
import { StockOutItem } from '../../../../models/StockOutItem';
import dateFormat from 'dateformat';

interface DOProps {
    stockOut: StockOut;
    stockOutItems: StockOutItem[];
    qrCodeImage: string
}

const ViewTrackingPickupDeliveryOrderPage: React.FC<DOProps> = ({ stockOut, stockOutItems, qrCodeImage }) => {
    const styles = StyleSheet.create({
        page: { fontSize: 11, paddingTop: 20, paddingLeft: 40, paddingRight: 40, lineHeight: 1.5, flexDirection: 'column' },
        spaceBetween: { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', color: "#3E3E3E" },
        titleContainer: { flexDirection: 'row', marginTop: 24 },
        logo: { width: 90 },
        reportTitle: { fontSize: 16, textAlign: 'center' },
        addressTitle: { fontSize: 11, fontStyle: 'bold' },
        invoice: { fontWeight: 'bold', fontSize: 20 },
        invoiceNumber: { fontSize: 11, fontWeight: 'bold' },
        address: { fontWeight: 400, fontSize: 10 },
        theader: { marginTop: 20, fontSize: 10, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 20, backgroundColor: '#DEDEDE', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },
        theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },
        tbody: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },
        total: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1.5, borderColor: 'whitesmoke', borderBottomWidth: 1 },
        tbody2: { flex: 2, borderRightWidth: 1 },
        qrCode: { marginTop: 10, width: 80, height: 80 },
    });

    const reciept_data = {
        "id": "642be0b4bbe5d71a5341dfb1",
        "invoice_no": "20200669",
        "address": "739 Porter Avenue, Cade, Missouri, 1134",
        "date": "24-09-2019",
        "items": [
            { "id": 1, "desc": "do ex anim quis velit excepteur non", "qty": 8, "price": 179.25 },
            { "id": 2, "desc": "incididunt cillum fugiat aliqua Lorem sit Lorem", "qty": 9, "price": 107.78 },
            { "id": 3, "desc": "quis Lorem ad laboris proident aliqua laborum", "qty": 4, "price": 181.62 },
            { "id": 4, "desc": "exercitation non do eu ea ullamco cillum", "qty": 4, "price": 604.55 },
            { "id": 5, "desc": "ea nisi non excepteur irure Lorem voluptate", "qty": 6, "price": 687.08 }
        ]
    }

    const InvoiceTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={{ fontSize: 10, marginBottom: 5 }}>Scan for Delivery Confirmation</Text>
                    <Image style={styles.qrCode} src={qrCodeImage} />
                </View>
                <Text style={styles.reportTitle}>Global Pharma Sdn. Bhd.</Text>
            </View>
        </View>
    );

    const QRCodeSection = () => (
        qrCodeImage ? (
            <View style={{ alignItems: 'center', marginTop: 20 }}>
                <Text style={{ fontSize: 10, marginBottom: 5 }}>Scan for Delivery Confirmation</Text>
                <Image style={styles.qrCode} src={qrCodeImage} />
            </View>
        ) : null
    );

    const Address = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={styles.invoice}>Delivery Order </Text>
                    <Text style={styles.invoiceNumber}>Customer: {stockOut.customer_fullname} </Text>
                    <Text style={styles.invoiceNumber}>Customer Invoice number: {stockOut.invoice_no} </Text>
                </View>
                <View>
                    <Text style={styles.addressTitle}>{stockOut.customer_address1} / {stockOut.customer_address2}</Text>
                    <Text style={styles.addressTitle}>Date: {dateFormat(stockOut.date?.toString(), "mmmm dd, yyyy")}</Text>
                    <Text style={styles.addressTitle}>Received by: </Text>
                </View>
            </View>
        </View>
    );

    const UserAddress = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{ maxWidth: 200 }}>
                    <Text style={styles.addressTitle}>Bill to </Text>
                    <Text style={styles.address}>
                        {reciept_data.address}
                    </Text>
                </View>
                <Text style={styles.addressTitle}>{reciept_data.date}</Text>
            </View>
        </View>
    );

    const TableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={styles.theader}>
                <Text>S/N</Text>
            </View>
            <View style={styles.theader}>
                <Text>Item No</Text>
            </View>
            <View style={styles.theader}>
                <Text>Description</Text>
            </View>
            <View style={styles.theader}>
                <Text>QTY</Text>
            </View>
            <View style={styles.theader}>
                <Text>UOM</Text>
            </View>
            <View style={styles.theader}>
                <Text>Packing</Text>
            </View>
            <View style={styles.theader}>
                <Text>Batch No</Text>
            </View>
            <View style={styles.theader}>
                <Text>Expiry Date</Text>
            </View>
            <View style={styles.theader}>
                <Text>Location</Text>
            </View>
        </View>
    );

    const TableBody = () => (
        <View>
            {stockOutItems.map((stockOutItem, index) => (
                <Fragment key={stockOutItem.id}>
                    <View style={{ width: '100%', flexDirection: 'row' }}>
                        <View style={styles.tbody}>
                            <Text>{index + 1}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{stockOutItem.item_no}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{stockOutItem.description}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{stockOutItem.quantity}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{stockOutItem.uom}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{stockOutItem.packing}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{stockOutItem.batch_no}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{dateFormat(stockOutItem.expiry_date?.toString(), "dd/mm/yyyy")}</Text>
                        </View>
                        <View style={styles.tbody}>
                            <Text>{stockOutItem.location}</Text>
                        </View>
                    </View>
                </Fragment>
            ))}
        </View>
    );

    const TableTotal = () => (
        <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={styles.total}>
                <Text></Text>
            </View>
            <View style={styles.total}>
                <Text> </Text>
            </View>
            <View style={styles.tbody}>
                <Text>Total</Text>
            </View>
            <View style={styles.tbody}>
                <Text>
                    {reciept_data.items.reduce((sum, item) => sum + (item.price * item.qty), 0).toFixed(2)}
                </Text>
            </View>
        </View>
    );

    return (
        <Document>
            <Page size="A4" orientation='landscape' style={styles.page}>
                <InvoiceTitle />
                <Address />
                <TableHead />
                <TableBody />
            </Page>
        </Document>
    );
}

export default ViewTrackingPickupDeliveryOrderPage;
