import React, { useEffect, useRef, useState } from 'react';
import { BanknotesIcon, CalendarIcon, ChevronLeftIcon } from '@heroicons/react/16/solid';
import { Link, useParams } from 'react-router-dom';
import { Heading, Subheading } from '../../../../components/Heading';
import { Badge } from '../../../../components/Badge';
import { Button } from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../../../../components/Description-List';
import { Avatar } from '../../../../components/Avatar';
import Loading from '../../../../components/Loading';
import dateFormat from 'dateformat';
import showErrorToast from '../../../../components/Toasts/ShowErrorToast';
import { TrackingPickup } from '../../../../models/TrackingPickup';
import { useTrackingPickup } from '../../../../hooks/useTrackingPickup';
import { useStockOut } from '../../../../hooks/useStockOut';
import { StockOutItem } from '../../../../models/StockOutItem';
import { useStockOutItems } from '../../../../hooks/useStockOutItem';
import { StockOut } from '../../../../models/StockOut';
import QRCode from 'qrcode';
import DeliveryOrder from "../DeliveryOrder/Page";
import { Image, Text, View, Page, Document, StyleSheet, BlobProvider } from '@react-pdf/renderer';
import { useTrackingDelivery } from '../../../../hooks/useTrackingDelivery';
import { TrackingDelivery } from '../../../../models/TrackingDelivery';

const generateQrCodeBase64 = async (stockOutId: string) => {
    const qrCodeData = `${window.location.origin}/gp/tracking/delivery/confirmation/${stockOutId}`;
    try {
        const qrCodeImage = await QRCode.toDataURL(qrCodeData, { width: 100 });
        return qrCodeImage; // Return the Base64 image string
    } catch (error) {
        console.error('Error generating QR code:', error);
        return null;
    }
};

interface TrackingDeliveryImages {
    proven_image: string | null;
}

const ViewTrackingPickupIdPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [trackingPickup, setTrackingPickup] = useState<TrackingPickup | null>(null);
    const { fetchTrackingPickupById, loading, error } = useTrackingPickup();
    const { fetchStockOutById } = useStockOut();
    const [stockOut, setStockOut] = useState<StockOut | null>(null);
    const [stockOutItems, setStockOutItems] = useState<StockOutItem[]>([]);
    const { fetchStockOutItemByStockOutId } = useStockOutItems();
    const [qrCodeImage, setQrCodeImage] = useState<string | null>(null);
    const { fetchTrackingDeliveryByStockOutId, fetchTrackingDeliveryImagesByStockOutId } = useTrackingDelivery();
    const [trackingDelivery, setTrackingDelivery] = useState<TrackingDelivery | null>(null);
    const [images, setImages] = useState<TrackingDeliveryImages | null>(null);

    useEffect(() => {
        document.title = "View Pickup Stock Out";
    }, []);

    useEffect(() => {
        const fetchPickupDetails = async () => {
            if (!id) return;

            try {
                const fetchedPickup = await fetchTrackingPickupById(Number(id));
                setTrackingPickup(fetchedPickup);
                const fetchedStockOut = await fetchStockOutById(Number(fetchedPickup?.stock_out_id!));
                setStockOut(fetchedStockOut);
                const fetchedStockOutItems = await fetchStockOutItemByStockOutId(Number(fetchedPickup?.stock_out_id!));
                setStockOutItems(fetchedStockOutItems);
                const image = await generateQrCodeBase64(fetchedPickup?.stock_out_id!.toString()!);
                setQrCodeImage(image!);
                const fetchedDelivery = await fetchTrackingDeliveryByStockOutId(Number(fetchedPickup?.stock_out_id!));
                setTrackingDelivery(fetchedDelivery);
                const fetchedImages = await fetchTrackingDeliveryImagesByStockOutId(Number(fetchedPickup?.stock_out_id!));
                setImages({ proven_image: fetchedImages?.proven_image, });
            } catch (err) {
                showErrorToast('Failed to fetch tracking pickup details.');
            }
        };

        fetchPickupDetails();
    }, [id]);

    if (loading) return <Loading />;
    if (error) return <p className='py-16 text-center text-red-700'>{error}</p>;
    if (!trackingPickup) return <p className='py-16 text-center'>Tracking pickup not found.</p>;
    if (!stockOut) return <p className='py-16 text-center'>Stock out not found.</p>;

    return (
        <>
            <div className="max-lg:hidden">
                <Link to="/gp/tracking/pickups" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
                    <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
                    Back to Pickups
                </Link>
            </div>
            <div className="mt-4 lg:mt-8">
                <div className="flex items-center gap-4">
                    <Avatar square initials={trackingPickup.driver_name ? trackingPickup.driver_name[0] : 'D'} className="bg-zinc-300 text-gray-700 size-8" />
                    <Heading>{trackingPickup.driver_name}</Heading>
                </div>
                <div className="isolate mt-2.5 flex flex-wrap justify-between gap-x-6 gap-y-4">
                    <div className="flex flex-wrap gap-x-10 gap-y-4 py-1.5">
                        <span className="flex items-center gap-2 text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white">
                            <CalendarIcon className="size-4 shrink-0 fill-zinc-400 dark:fill-zinc-500" />
                            <span className='pr-1 text-zinc-400'>Created on</span><span>{dateFormat(trackingPickup.created_time, "mmmm dd, yyyy")}</span>
                        </span>
                    </div>
                    <div className="flex gap-4">

                        <BlobProvider document={<DeliveryOrder stockOut={stockOut!} stockOutItems={stockOutItems} qrCodeImage={qrCodeImage!} />}>
                            {({ url, blob }) => (
                                <a href={url!} target="_blank">
                                    <Button>Print Delivery Order</Button>
                                </a>
                            )}
                        </BlobProvider>
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <Subheading>Pickup Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Driver Name</DescriptionTerm>
                    <DescriptionDetails>{trackingPickup.driver_name || '-'}</DescriptionDetails>
                    <DescriptionTerm>Vehicle Number</DescriptionTerm>
                    <DescriptionDetails>{trackingPickup.vehicle_no || '-'}</DescriptionDetails>
                    <DescriptionTerm>Carton</DescriptionTerm>
                    <DescriptionDetails>{trackingPickup.carton || '-'}</DescriptionDetails>
                    <DescriptionTerm>Cold Item</DescriptionTerm>
                    <DescriptionDetails>{trackingPickup.cold_item || '-'}</DescriptionDetails>
                    <DescriptionTerm>Stock Out ID</DescriptionTerm>
                    <DescriptionDetails>{trackingPickup.stock_out_id || '-'}</DescriptionDetails>
                </DescriptionList>
            </div>

            <div className="mt-12">
                <Subheading>Stock Out Information</Subheading>
                <Divider className="mt-4" />
                <DescriptionList>
                    <DescriptionTerm>Reference</DescriptionTerm>
                    <DescriptionDetails>{stockOut.reference_no!}</DescriptionDetails>
                    <DescriptionTerm>Customer</DescriptionTerm>
                    <DescriptionDetails>{stockOut.customer_fullname || '-'}</DescriptionDetails>
                    <DescriptionTerm>Invoice Number</DescriptionTerm>
                    <DescriptionDetails>{stockOut.invoice_no || '-'}</DescriptionDetails>
                    <DescriptionTerm>Logistic</DescriptionTerm>
                    <DescriptionDetails>{stockOut.logistic_fullname || '-'}</DescriptionDetails>
                    <DescriptionTerm>Issue Date</DescriptionTerm>
                    <DescriptionDetails>{dateFormat(stockOut.issue_date!, "mmmm dd, yyyy")}</DescriptionDetails>
                    <DescriptionTerm>Issue Time</DescriptionTerm>
                    <DescriptionDetails>{stockOut.issue_time!.toString().substring(0, 8) || '-'}</DescriptionDetails>
                    <DescriptionTerm>Issuer</DescriptionTerm>
                    <DescriptionDetails>{stockOut.issuer_fullname || '-'}</DescriptionDetails>

                </DescriptionList>
            </div>

            <div className="mt-12">
                <Subheading>Delivery Information</Subheading>
                <Divider className="mt-4" />
                {(trackingDelivery) ? <DescriptionList>
                    <DescriptionTerm>Driver / Rider Name</DescriptionTerm>
                    <DescriptionDetails>{trackingDelivery?.driver_name! || '-'}</DescriptionDetails>
                    <DescriptionTerm>Recipient Name</DescriptionTerm>
                    <DescriptionDetails>{trackingDelivery?.recipient_name || '-'}</DescriptionDetails>
                    <DescriptionTerm>Confirmed Date</DescriptionTerm>
                    <DescriptionDetails>{dateFormat(trackingDelivery?.created_time!, "mmmm dd, yyyy") || '-'}</DescriptionDetails>
                    <DescriptionTerm>Confirmed Time</DescriptionTerm>
                    <DescriptionDetails>{dateFormat(trackingDelivery?.created_time!, "hh:MM:ss") || '-'}</DescriptionDetails>
                    <DescriptionTerm>Proven Image</DescriptionTerm>
                    <DescriptionDetails>{images?.proven_image ? (
                        <div>
                            {
                                images.proven_image && (
                                    <div>
                                        <img
                                            className="w-1/2 max-w-none rounded-xl shadow-md ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 mt-4"
                                            src={images.proven_image}
                                            alt="Patient Image X-ray"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : (<p>No images available</p>)}</DescriptionDetails>
                </DescriptionList> : <p className='py-8 text-zinc-700 text-sm'>Stock-out haven't delivered yet.</p>}
            </div>
            {/* QR Code rendering placeholder
            <PDFViewer width="1000" height="650" className="app" >
                <Invoice stockOut={stockOut} stockOutItems={stockOutItems} qrCodeImage={qrCodeImage!} />
            </PDFViewer> */}
        </>
    );
};

export default ViewTrackingPickupIdPage;
