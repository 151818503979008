import React, { useContext, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, EyeIcon, MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/Dropdown';
import { Button } from '../../../components/Button';
import { Heading, Subheading } from '../../../components/Heading';
import { Input, InputGroup } from '../../../components/Input';
import Loading from '../../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/Table';
import dateFormat from 'dateformat';
import { UserContext } from '../../../context/userContext';
import { useSetupUsergroup } from '../../../hooks/useSetupUsergroup';
import showErrorToast from '../../../components/Toasts/ShowErrorToast';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import useWarehouseProductItemMovement from '../../../hooks/useWarehouseProductItemMovement';
import { Text } from '../../../components/Text';

const ReportStockMovementPage: React.FC = () => {
    const { jsPDF } = require('jspdf');
    require('jspdf-autotable');
    const { currentUser } = useContext(UserContext);
    const { loading, error, movements } = useWarehouseProductItemMovement();
    const { fetchSupplierByUserId, suppliers } = useSetupUsergroup();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [filterStartDate, setFilterStartDate] = useState<string>('');
    const [filterEndDate, setFilterEndDate] = useState<string>('');
    const [filterSupplierId, setFilterSupplierId] = useState<string>('');
    const [filterYear, setFilterYear] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const navigate = useNavigate();
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    useEffect(() => {
        document.title = "Stock Movement Report";
    }, []);

    // Filter logic
    const filteredProductItemMovement = Array.isArray(movements)
        ? movements?.filter((productItemMovement) => {
            const itemDate = new Date(productItemMovement.created_time!);

            return (
                (productItemMovement.item_no?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    productItemMovement.second_item_no?.toLowerCase().includes(searchQuery.toLowerCase())) &&
                (!filterStartDate || itemDate >= new Date(filterStartDate)) &&
                (!filterEndDate || itemDate <= new Date(filterEndDate)) &&
                (!filterSupplierId || productItemMovement.supplier_id === Number(filterSupplierId)) &&
                (!filterYear || itemDate.getFullYear().toString() === filterYear)
            );
        }) : [];

    // Date formatting helper
    const formatDate = (date: Date): string => `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

    // PDF Generation
    const generatePDF = () => {
        const today = formatDate(new Date());
        if (!filteredProductItemMovement) {
            showErrorToast("PDF generation failed. Product item is not available.");
            return;
        }
        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.text(`Stock Movement Report | ${today}`, 14, 10);
        doc.autoTable({
            head: [['S/N', 'Item No', 'Second Item No', 'Description', 'Quantity In', 'Quantity Out', 'Quantity Adjust']],
            body: filteredProductItemMovement.map((item, index) => [
                index + 1,
                item.item_no,
                item.second_item_no,
                item.description,
                item.quantity_in || '0',
                item.quantity_out || '0',
                item.quantity_adjust || '0'
            ])
        });

        doc.save(`stock_movement_report_${today}.pdf`);
    };

    // CSV Generation
    const generateCSV = () => {
        if (!filteredProductItemMovement) {
            showErrorToast("CSV generation failed. Product item is not available.");
            return;
        }
        const csvData = [
            ['S/N', 'Item No', 'Second Item No', 'Description', 'Quantity In', 'Quantity Out', 'Quantity Adjust'],
            ...filteredProductItemMovement.map((item, index) => [
                index + 1,
                item.item_no,
                item.second_item_no,
                item.description,
                item.quantity_in || '0',
                item.quantity_out || '0',
                item.quantity_adjust || '0'
            ])
        ];

        const csvContent = 'data:text/csv;charset=utf-8,' + csvData.map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        const today = formatDate(new Date());
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `stock_movement_report_${today}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Excel Generation
    const generateExcel = () => {
        if (!filteredProductItemMovement) {
            showErrorToast("Excel generation failed. Product item is not available.");
            return;
        }

        const worksheet = XLSX.utils.json_to_sheet(filteredProductItemMovement.map((item, index) => ({
            SNo: index + 1,
            ItemNo: item.item_no,
            SecondItemNo: item.second_item_no,
            Description: item.description,
            QuantityIn: item.quantity_in || '0',
            QuantityOut: item.quantity_out || '0',
            QuantityAdjust: item.quantity_adjust || '0',
        })));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Movement");

        const today = formatDate(new Date());
        XLSX.writeFile(workbook, `stock_movement_report_${today}.xlsx`);
    };

    return (
        <>
            <div className="flex flex-wrap items-end justify-between gap-4">
                <div className="max-sm:w-full sm:flex-1">
                    <Heading>Stock Movement</Heading>
                    <div className="mt-4 flex gap-4 justify-between">
                        <div className="flex-1">
                            <InputGroup>
                                <MagnifyingGlassIcon />
                                <Input
                                    name="search"
                                    placeholder="Search stock movement&hellip;"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                        <Dropdown>
                            <DropdownButton aria-label="More options">
                                Print Report
                            </DropdownButton>
                            <DropdownMenu anchor="bottom end">
                                <DropdownItem onClick={generatePDF}>PDF</DropdownItem>
                                <DropdownItem onClick={generateCSV}>CSV</DropdownItem>
                                <DropdownItem onClick={generateExcel}>Excel</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            {/* Date, Year, and Warehouse Filters */}
            <div className="mt-4 px-6 py-4 bg-zinc-50 ring-1 ring-zinc-200 rounded-md">
                <div className='flex flex-wrap items-end justify-between gap-4'>
                    <Subheading>Filters & Sorting</Subheading>
                    {(isFilterOpen) ? <ChevronUpIcon onClick={() => setIsFilterOpen(false)} className='w-5 h-5' /> : <ChevronDownIcon onClick={() => setIsFilterOpen(true)} className='w-5 h-5' />}
                </div>
                {(isFilterOpen) && <div className='mt-4 grid gap-4 sm:grid-cols-1'>
                    <div>
                        <Text>From Date</Text>
                        <Input
                            type="date"
                            value={filterStartDate}
                            onChange={(e) => setFilterStartDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>To Date</Text>
                        <Input
                            type="date"
                            value={filterEndDate}
                            onChange={(e) => setFilterEndDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <Text>By Year</Text>
                        <Input
                            type="number"
                            placeholder="Year"
                            value={filterYear}
                            onChange={(e) => setFilterYear(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button onClick={() => {
                            setFilterStartDate('');
                            setFilterEndDate('');
                            setFilterYear('');
                            setFilterSupplierId('');
                        }}>
                            Reset Filter
                        </Button>
                    </div>
                    {(currentUser?.role.toString() !== "5") && (
                        <div className='max-w-full'>
                            <Text>By Supplier</Text>
                            <Dropdown>
                                <DropdownButton outline>
                                    {filterSupplierId ? suppliers?.find(w => w.id?.toString() === filterSupplierId)?.fullname : 'All Suppliers'}
                                    <ChevronDownIcon />
                                </DropdownButton>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setFilterSupplierId('')}>All Suppliers</DropdownItem>
                                    {suppliers?.map((supplier) => (
                                        <DropdownItem key={supplier.id} onClick={() => setFilterSupplierId(supplier.id?.toString()!)}>
                                            {supplier.fullname}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    )}
                </div>}
            </div>


            <ul className="mt-10">
                {loading && <div className='w-full text-center h-full py-36 justify-center'><Loading /></div>}
                {error && <p className='py-16 text-center text-red-700'>{error}</p>}
                {(!loading && !error) && (
                    <div>
                        <Table className="[--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
                            <TableHead>
                                <TableRow>
                                    <TableHeader>S/N</TableHeader>
                                    <TableHeader>Item No</TableHeader>
                                    <TableHeader>Second Item No</TableHeader>
                                    <TableHeader>Description</TableHeader>
                                    <TableHeader>Quantity In</TableHeader>
                                    <TableHeader>Quantity Out</TableHeader>
                                    <TableHeader>Quantity Adjust</TableHeader>
                                    <TableHeader>Movement Date</TableHeader>
                                    <TableHeader>Actions</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(filteredProductItemMovement.length > 0) ? filteredProductItemMovement.map((item, index) => (
                                    <TableRow key={index} title={`${index}`}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell className="text-zinc-500">{item.item_no}</TableCell>
                                        <TableCell className="text-zinc-500">{item.second_item_no}</TableCell>
                                        <TableCell className="text-zinc-500">{item.description}</TableCell>
                                        <TableCell className="text-zinc-400">{item.quantity_in || '0'}</TableCell>
                                        <TableCell className="text-zinc-400">{item.quantity_out || '0'}</TableCell>
                                        <TableCell className="text-zinc-400">{item.quantity_adjust || '0'}</TableCell>
                                        <TableCell className="text-zinc-400">{dateFormat(item.created_time?.toString(), "mmm dd, yyyy")}</TableCell>
                                        <TableCell className="py-5 flex gap-4">
                                            <a className="text-zinc-600 hover:text-zinc-900" onClick={() => navigate(`/gp/reports/stockmovement/view/${item.id}`)}>
                                                <EyeIcon className='h-5 w-5' />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={9} className="text-center py-8">No stock movement found.</TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </ul>
        </>
    );
};

export default ReportStockMovementPage;
